import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Snackbar, Alert } from '@mui/material';
import { hideSnackbar } from '../Redux/Reducer';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const SnackbarComponent = () => {
    const dispatch = useDispatch();
    const { snackbarOpen, snackbarMessage } = useSelector((state) => state.ProjamAppSlice);

    const handleClose = () => {
        dispatch(hideSnackbar());
    };

    return (
        <Snackbar
            open={snackbarOpen}
            onClose={handleClose}
            message={snackbarMessage}
            anchorOrigin={{vertical: 'top', horizontal: 'center', width: '100px' }}
            autoHideDuration={6000}
        >
            <Alert onClose={handleClose}
                severity="error"
                iconMapping={{
                    error: <ErrorOutlineIcon style={{ fontSize: 25 }} />,
                }}
                sx={{
                    width: '300px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyItems:'center'
                }}
            >
                {snackbarMessage}
            </Alert>
        </Snackbar>

    );
};

export default SnackbarComponent;
