import React from 'react'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const ActiveSprintsLayout = ({ groupBy, setGroupBy }) => {

    const handleChange = (event) => {
        setGroupBy(event.target.value);
    };

    return (
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small" color='success'>
            <InputLabel id="demo-select-small-label">Group By</InputLabel>
            <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={groupBy}
                label="Group By"
                onChange={handleChange}
                color='success'
            >
                <MenuItem value="none">
                    <em>None</em>
                </MenuItem>
                <MenuItem value={'assignee'}>Assignee</MenuItem>
                <MenuItem value={'subtask'}>SubTask</MenuItem>
            </Select>
        </FormControl>
    );
}
export default ActiveSprintsLayout
