import React, { useState } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Microsofticon from "../../../assets/images/microsoft_732221.png";
import Googleicon from "../../../assets/images/google_300221.png";
import Slack from "../../../assets/images/Slack.png";
import SelectMenuTextField from "../../../components/SelectMenuTextField";
import { colors } from "../../../utilities/colors";
import SearchAutoComplete from "../../../components/SearchAutoComplete";
import MoreHoriz from "@mui/icons-material/MoreHoriz";
import "../../../App.css";
import { AddPeople } from "../../../utilities/Json";
import { useEffect } from "react";
import API_BASE_URL from "../../../components/config/appConfig";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { useDispatch, useSelector } from 'react-redux';
import { getActiveProjectData } from "../../../common/Redux/Reducer";
import { useParams } from "react-router-dom";

const AddPeopleToProject = ({
  open,
  ProjectName = "project",
  setOpenAddPeopleModel = () => {},
}) => {
  const [selectedRole, setSelectedRole] = useState("");
  const [SelectedEmp, setSelectedEmp] = useState();
  const [AllUsers, setAllUsers] = useState([]);
  const { Projects, Skeleton } = useSelector((state) => state.ProjamAppSlice);
  const dispatch = useDispatch();
  const {projectSlug} = useParams();

  useEffect(() => {
    getAllUsers();
  }, []);
  let projectId = Projects?.selectedProject?.selectedProjectData?._id
  const getAllUsers = async () => {
    try {
      let response = await axios(`${API_BASE_URL}/users`);
      setAllUsers(response.data.users);
    } catch (error) {
      console.log(error);
    }
  };

  const handelAddPeopleToProject = async () => {

    try {
      let decoded={}
      let token = localStorage.getItem('accessToken');
      if (token) {
        decoded = jwtDecode(token)
      }
      let response = await axios.post(
        `${API_BASE_URL}/projects/addmember?projectid=${projectId}`,
        {
          invitedBy:decoded?.id,
          employeeId: SelectedEmp?._id,
        }
      );
     
      setOpenAddPeopleModel(false);
      dispatch(getActiveProjectData(projectSlug));
    } catch (error) {
      console.log(error);
    }
  };







  return (
    <Dialog
      open={open}
      aria-labelledby="sprint-start-dialog-title"
      maxWidth="sm"
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginRight: 2,
        }}
      >
        <DialogTitle sx={{ fontSize: "14px" }} id="sprint-start-dialog-title">
          Add People to <b>{ProjectName}</b>
        </DialogTitle>
        <IconButton aria-label="close" size="small">
          <CloseIcon
            fontSize="small"
            onClick={() => setOpenAddPeopleModel(false)}
          />
        </IconButton>
      </Box>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            "& > div": {
              marginBottom: "12px",
            },
          }}
        >
          <Box>
            <SearchAutoComplete
              labelName="Search by Email"
              data={AllUsers}
              setSelectedEmp={setSelectedEmp}
            />
          </Box>
          <Box>
            <Typography sx={{ fontWeight: "bold", fontSize: "12px" }}>
              or add from
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "8px",
                marginTop: "8px",
              }}
            >
              <Button className="text-center social-login-btns">
                <img src={Googleicon} alt="Google icon" className="me-2" />{" "}
                Google
              </Button>
              <Button className="text-center social-login-btns">
                <img src={Slack} alt="Slack icon" className="me-2" /> Slack
              </Button>
              <Button className="text-center social-login-btns">
                <img
                  src={Microsofticon}
                  alt="Microsoft icon"
                  className="me-2"
                />{" "}
                Microsoft
              </Button>
            </Box>
          </Box>
          <Box>
            <Typography sx={{ fontWeight: "bold", fontSize: "12px" }}>
              Role
            </Typography>
            <SelectMenuTextField
              labelText="Role"
              options={AddPeople}
              onChange={(event) => setSelectedRole(event.target.value)}
              name="role"
            />
          </Box>
          <Box>
            <Typography
              sx={{
                fontSize: "10px",
                wordWrap: "break-word",
                maxWidth: "320px",
              }}
            >
              This site is protected by reCAPTCHA and the Google{" "}
              <a
                href="https://policies.google.com/privacy"
                className="anchor-tag"
                sx={{ color: colors.text_green }}
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy{" "}
              </a>
              and{" "}
              <a
                href="https://policies.google.com/terms"
                className="anchor-tag"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of Service
              </a>{" "}
              apply
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <ButtonGroup
              variant="contained"
              aria-label="outlined button group"
              className="shadow-none d-flex justify-content-between align-items-center gap-3"
            >
              <Button
                sx={{
                  backgroundColor: `transparent !important`,
                  border: `1.5px solid ${colors.primary} !important`,
                  color: `${colors.primary} !important`,
                  borderRadius: "5px  !important",
                  fontSize: "10px !important",
                  textTransform: "capitalize",
                }}
                className="px-3"
                type="button"
                onClick={() => setOpenAddPeopleModel(false)}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  backgroundColor: `${colors.primary} !important`,
                  border: "0px !important",
                  color: "#FFFFFF !important",
                  borderRadius: "5px  !important",
                  fontSize: "10px !important",
                  textTransform: "capitalize",
                }}
                className="px-3"
                type="submit"
                onClick={() => handelAddPeopleToProject()}
              >
                Add
              </Button>
            </ButtonGroup>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AddPeopleToProject;
