import React, { useEffect ,useState  } from "react";
import moment from "moment";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";


  
   
   



const SprintsComaprisonLineGraph = ({ graphData = [] }) => {

    const [data , setData]=useState([])

  useEffect(() => {
    // console.log(graphData ,'gdata')
    getCustomisedGraphData(graphData.reverse());
  }, [graphData]);

  const getCustomisedGraphData = async (graphData) => {
    try {
      let DataObj = await Promise.all(
        graphData?.map((sprint) => {
          const { startDate, endDate, name } = sprint;
          let start = new Date(startDate);
          let end = new Date(endDate);
          start = moment(start);
          end = moment(end);
          let duration = Math.ceil(end.diff(start, "days") / 7);
          return { SprintName: name, value: duration };
        })
      );
      setData(DataObj)
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <LineChart width={600} height={200} cx={10} 
    
    margin={{
        top: 5,
        right: 35,
        left: 10,
        bottom: 5,
      }}
    
    >
      <CartesianGrid strokeDasharray="3" />
      <XAxis
        dataKey="SprintName"
        type="category"
        allowDuplicatedCategory={true}  tick={{ fontSize: 10 }}
        interval={0} 
      />
       <Tooltip />
      <YAxis dataKey="value" allowDecimals={false} label={{ value: 'Weeks', angle: -90, position: '' , fontSize:"14px" }}   />
     
      {/* <Legend content={'Sprints'} /> */}
        <Line dataKey="value" data={data} name={'weeks'}  />
      
    </LineChart>
  );
};

export default SprintsComaprisonLineGraph;
