import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Button,
  Avatar,
  Checkbox,
  Badge,
  DialogTitle,
  DialogContent, TextField
} from "@mui/material";
import ButtonGroup from "@mui/material/ButtonGroup";
import Dialog from "@mui/material/Dialog";
import SelectMenuTextField from "../SelectMenuTextField";
import axios from "axios";
import API_BASE_URL from "../../components/config/appConfig";
import { issueStatus } from "../../utilities/Json";
import { useLocation } from "react-router-dom";
import { colors } from "../../utilities/colors";
import { Icons } from "../../utilities/Icons";
import BasicTextField from "../BasicTextField";
import CKTextEditor from "../CK-Text-Editor";
import { styled } from "@mui/material/styles";
import { Formik, Form, ErrorMessage } from "formik";
import { backlogissueSchema } from "../../Validations/schema";
import { IssueTypes } from "../../utilities/Json";
import { getSprintsList, getBacklogIssues, updateIssue } from "../../common/Redux/Reducer";
import SearchAutoComplete from "../SearchAutoComplete";
import { useDispatch, useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode";
import { setSelectedProjectData } from "../../common/Redux/Reducer";
import { clearSprintsListAndActiveSprintData } from "../../common/Redux/Reducer";

const CreateBacklogIssue = ({
  isCreateNewBackIssuse,
  setIsCreateNewBackIssue,
  sprint = '',
}) => {
  const IconGroupStyles = {
    backgroundColor: "transparent !important",
    border: "0px !important",
    height: "16px",
    width: "16px",
    cursor: "pointer",
  };

  const [open, setOpen] = React.useState(isCreateNewBackIssuse);
  const [reporters, setReporters] = useState([]);
  const [SelectedEmp, setSelectedEmp] = useState();
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isDisable, setIsDisable] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("To Do");
  const [projectname, setProjectname] = useState(localStorage.getItem("projectId"));
  const location = useLocation();
  const dispatch = useDispatch();
  const filterAssigneesArray = useSelector((state) => state.ProjamAppSlice.assignee);

  const { Projects,Skeleton } = useSelector((state) => state.ProjamAppSlice);
  const allProjectsList = Projects?.allProjectsList
  const activeSprint = Projects?.selectedProject?.ActiveSprint;
  const sprintsList = Projects?.selectedProject?.sprintsList;
  const selectedProjectData = Projects?.selectedProject?.selectedProjectData;
  const filteredProjects = allProjectsList?.data?.filter(project => project.active === "true");
  const projectOptions = filteredProjects || [];
  const token = localStorage.getItem("accessToken");
  const decoded = jwtDecode(token);



  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      right: 0,
      top: 1,
      padding: "0 4px",
    },
  }));


  let backlogId = Projects?.selectedProject?.selectedProjectBackLogId
  let projectId = Projects?.selectedProject?.selectedProjectData?._id

  const handleClose = () => {
    setOpen(false);
    setIsCreateNewBackIssue(false);
    if (location?.pathname === "/projects") {
      dispatch(setSelectedProjectData(""))
      setMembers([])
    }
  };


  useEffect(() => {
    fetchIssueTypes();
    getReporters();
    if (location?.pathname !== "/projects") {
      getProjectData();
    }
  }, []);

  useEffect(() => {
    async function fetchSprintsList() {
      if (selectedProjectData && selectedProjectData?.backlogs[0]) {
        await dispatch(getSprintsList({ backlogId: selectedProjectData?.backlogs[0], filterAssigneesArray: selectedProjectData?.contributors }));
        setMembers(selectedProjectData?.contributors)
      } else {
        await dispatch(clearSprintsListAndActiveSprintData())
      }
    }
    if (location?.pathname === "/projects") {
      fetchSprintsList()

    }

  }, [selectedProjectData])

  const fetchIssueTypes = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/issues/`);
      const mappedIssueTypes = response.data.map((type) => ({
        id: type.issuetype.id,
        name: type.issuetype.name,
      }));
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };
  const getReporters = async () => {
    try {
      let response = await axios.get(`${API_BASE_URL}/users/getReporters`);
      setReporters(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getProjectData = async () => {
    try {
      setLoading(true);
      let response = await axios(
        `${API_BASE_URL}/projects/getProjectsById/${projectId}`
      );
       backlogId = response?.data?.backlogs[0]
      setMembers(response?.data?.contributors);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // const options = location.pathname === "/projects/active-sprints" ? [{ ...activeSprint }] : sprintsList;
  const options = location?.pathname?.includes( "/active-sprints") ? [{ ...activeSprint }] : sprintsList;

   const handleSubmit = async (values, { resetForm }) => {
      console.log('values', values);
      console.log(newDescription, "NewDescription")
    if (values?.sprint && values?.sprint !== "backlog") {
      try {
        const Data = { ...values, status: selectedStatus, assignee: SelectedEmp, description: newDescription };
        const response = await axios.post(
          `${API_BASE_URL}/issues/createIssue?userid=${decoded?.id}`,
          Data
        );
        handleClose();
        await dispatch(getSprintsList({ backlogId: selectedProjectData?.backlogs[0], filterAssigneesArray: filterAssigneesArray }));

      } catch (error) {
        console.error("Error submitting form:", error);
      } finally {
        // setSubmitting(false);
      }
    } else {
      try {
        let Data = { ...values, status: selectedStatus, assignee: SelectedEmp, description: newDescription, sprint: null };
        const response = await axios.post(
          `${API_BASE_URL}/issues/createBacklogIssue`,
          Data
        );
        backlogId &&  dispatch(getBacklogIssues(backlogId));
        handleClose();
      } catch (error) {
        console.error("Error submitting form:", error);
      } finally {
        // setSubmitting(false);
      }
    }
  };
  useEffect(() => {
    if (location.pathname === '/projects') {
      setIsDisable(false);
    } else {
      setIsDisable(true);
    }
  }, [projectname, location.pathname]);

  const createIssueFormInitialValues = {
    project: location?.pathname !== "/projects" ? projectId : (selectedProjectData && selectedProjectData?._id) || "",
    issueType: "Task",
    status: selectedStatus,
    summary: '',
    description: "",
    reporter: "",
    assignee: "",
    backlog: location?.pathname !== "/projects" ? backlogId : (selectedProjectData && selectedProjectData?.backlogs[0]) || "",
    sprint: location?.pathname?.includes( "/active-sprints") ? activeSprint?._id : sprint ? sprint : 'backlog',
  }

     

  const handleChangeProject = async (event) => {
    const selectedProject = projectOptions?.filter((eachProject) => eachProject?._id === event?.target?.value)
    if (selectedProject && selectedProject?.length > 0) {
      await dispatch(setSelectedProjectData(selectedProject[0]))
      setMembers(selectedProject[0]?.contributors)
    }
  }


  const [isEditingDesc, setIsEditingDesc] = useState(false);
  const [newDescription, setNewDescription] = useState('');
  const [isDecHovered, setIsDecHovered] = useState(false);

  const handleDecHover = () => {
    setIsDecHovered(true);
  };
  const handleBoxClick = (event) => {
    setIsEditingDesc(true);
  };

  const handleDescriptionChange = (data) => {
    setNewDescription(data);
    console.log(data)
  };

  // const handleUpdateDec = async () => {
  //     try {
  //         dispatch(updateIssue({ issueId: workFlowModalTaskData?._id, payload: { description: newDescription, } }));
  //         setIsEditingDesc(false);
  //         dispatch(getSprintsList({ backlogId: localStorage.getItem("backlogId"), filterAssigneesArray: filterAssigneesArray }));
  //     } catch (error) {
  //         console.error('Error updating summary:', error);
  //     }
  // };

  const handleCloseEditor = () => {
    setIsEditingDesc(false)
  }

  

  // Handle changing the selected status
  const handleStatusChange = (status) => {
   setSelectedStatus(status)
  };

  

  return (
    <React.Fragment>
      <Dialog
        maxWidth="xl"
        open={open}
        disableEscapeKeyDown
        slotProps={{
          backdrop: {
            onClick: (event) => event.stopPropagation(),
          },
        }}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle sx={{ padding: "16px 45px 0px; !important" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: '0px'
            }}
          >
            <Typography
              sx={{
                fontSize: "18px !important",
                color: colors.font_Bold,
                fontWeight: "bold",
              }}
            >
              Create Issue
            </Typography>
            <Box sx={{ display: "flex", gap: "18px" }}>
              <Avatar sx={IconGroupStyles} alt="Remy Sharp" src={Icons.dash} />
              <Avatar
                sx={IconGroupStyles}
                alt="Remy Sharp"
                src={Icons.close}
                onClick={handleClose}
              />
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ padding: "20px -4px; !important" }}>
          <Formik
            initialValues={createIssueFormInitialValues}
            validationSchema={backlogissueSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({ handleChange, handleBlur, values, isSubmitting }) => (
              <Form>
                <Box
                  sx={{
                    minWidth: "500px",
                    minHeight: "500px",
                    padding: "24px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "18px",
                    maxHeight: "80vh",
                    overflowL: "scroll",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={{ fontSize: "12px" }}>
                      Required fields are marked with an asterisk
                      <span style={{ color: colors.required_Star_Color }}>*</span>
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "18px",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Avatar
                        sx={IconGroupStyles}
                        alt="Remy Sharp"
                        src={Icons.eye}
                      />
                      <Button
                        sx={{
                          backgroundColor: `${colors.btn_grey_background} !important`,
                          border: "0px !important",
                          color: "#000000 !important",
                          borderRadius: "5px  !important",
                          fontSize: "10px !important",
                          textTransform: "capitalize",
                        }}
                        className="p-1"
                      >
                        Import Issues
                      </Button>
                      <Avatar
                        sx={IconGroupStyles}
                        alt="Remy Sharp"
                        src={Icons.MenuHorizantal}
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "flex-start",
                      gap: "18px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        flex: 1,
                        gap: "1px",
                      }}
                    >
                      <SelectMenuTextField
                        labelText="Projects"
                        options={projectOptions}
                        onChange={handleChangeProject}
                        value={selectedProjectData?._id || ""}
                        defaultValue={selectedProjectData?._id || ""}
                        isDisable={isDisable}
                      />
                      <ErrorMessage
                        name="project"
                        className="error-message text-danger mx-1"
                        style={{ fontSize: "12px" }}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        flex: 1,
                        gap: "1px",
                      }}
                    >
                      <SelectMenuTextField
                        labelText="Sprints"
                        options={options}
                        onChange={handleChange("sprint")}
                        onBlur={handleBlur("sprint")}
                        value={values.sprint}
                        defaultValue={values.sprint}
                      />
                      <ErrorMessage
                        name="sprint"
                        // component="span"
                        className="error-message text-danger mx-1"
                        style={{ fontSize: "12px" }}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        flex: 1,
                        gap: "1px",
                      }}
                    >
                      <SelectMenuTextField
                        labelText="Issue type"
                        options={IssueTypes}
                        onChange={handleChange("issueType")}
                        onBlur={handleBlur("issueType")}
                        value={values.issueType}
                        defaultValue="Task"
                      />
                      <ErrorMessage
                        name="issueType"
                        component="span"
                        className="error-message text-danger mx-1"
                        style={{ fontSize: "12px" }}
                      />
                    </Box>
                  </Box>
                  <Typography sx={{ color: colors.text_green, fontSize: "12px" }}>
                    Learn about issue types
                  </Typography>

                  <Box>
                    <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>
                      Status
                      <span style={{ color: colors.required_Star_Color }}>*</span>
                    </Typography>
                    <ButtonGroup
                      variant="contained"
                      aria-label="outlined button group"
                      className="shadow-none d-flex mt-1"
                      sx={{ flexWrap: 'wrap' }}
                    >
                      {issueStatus.map((status, i) => {
                        return (
                          <StyledBadge
                            key={i}
                            badgeContent={
                              selectedStatus === status.name ? (
                                <Avatar
                                  sx={{
                                    height: "13px",
                                    width: "13px",
                                    backgroundColor: "transparent",
                                    right: '15px'
                                  }}
                                  src={Icons.btnselect}
                                />
                              ) : null
                            }
                          >
                            <Button
                             onClick={() => setSelectedStatus(status.name)}
                              sx={{
                                backgroundColor:
                                selectedStatus === status.name
                                    ? `${colors.primary} !important`
                                    : `${colors.btn_grey_background} !important`,
                                border: "0px !important",
                                color:
                                selectedStatus === status.name
                                    ? `#FFFFFF !important`
                                    : "#000000 !important",
                                borderRadius: "5px  !important",
                                fontSize: "12px !important",
                                textTransform: "capitalize",
                                // width:'100%'
                              }}
                              className="me-3"
                            >
                              {status.name}
                            </Button>
                          </StyledBadge>
                        );
                      })}
                    </ButtonGroup>
                  </Box>
                  <Typography sx={{ fontSize: "12px" }}>
                    This is the issues initial status creation upon.
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "flex-start",
                      gap: "18px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        flex: 1,
                        gap: "1px",
                      }}
                    >
                      <BasicTextField
                        labelText="Title"
                        onChange={handleChange("summary")}
                        onBlur={handleBlur("summary")}
                        value={values.summary}
                      />
                      <ErrorMessage
                        name="summary"
                        component="span"
                        className="error-message text-danger mx-1"
                        style={{ fontSize: "12px" }}
                      />
                    </Box>
                  </Box>
                  {/* <Box>
                    <CKTextEditor
                      editorLabelText="Description"
                      // payloadText="Words Not Enough? Type: to add emoji"
                      onChange={handleChange("description")}
                      onBlur={handleBlur("description")}
                      value={values.description}
                    />
                  </Box> */}
                  <Typography className="fw-bold">Description</Typography>
                  {!newDescription && !isEditingDesc && (
                    <TextField
                      onClick={handleBoxClick}
                      onMouseEnter={handleDecHover}
                      onMouseLeave={() => setIsDecHovered(false)}
                      variant="outlined"
                      placeholder="Add a Description"
                      style={{
                        backgroundColor: isDecHovered ? 'lightgray' : 'transparent',
                        width: '770px'
                      }}
                      className="textfield_Description"
                    />
                  )}
                  {isEditingDesc && (<>
                    <Box>
                      <CKTextEditor
                        payloadText={newDescription}
                        onChange={handleDescriptionChange}
                        value={newDescription}
                      />
                    </Box>
                  </>)}

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "flex-start",
                      gap: "18px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        flex: 1,
                        gap: "1px",
                      }}
                    >
                      <SearchAutoComplete
                        labelName="Assignee"
                        data={members}
                        setSelectedEmp={setSelectedEmp}
                      />
                      <ErrorMessage
                        name="assignee"
                        component="span"
                        className="error-message text-danger mx-1"
                        style={{ fontSize: "12px" }}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        flex: 1,
                        gap: "1px",
                      }}
                    >
                      <SelectMenuTextField
                        labelText="Reporter"
                        options={reporters}
                        onChange={handleChange("reporter")}
                        onBlur={handleBlur("reporter")}
                        value={values.reporter}
                      />
                      <ErrorMessage
                        name="reporter"
                        component="span"
                        className="error-message text-danger mx-1"
                        style={{ fontSize: "12px" }}
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      paddingBottom: "24px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: colors.font_Bold,
                        fontWeight: "bold",
                        fontSize: "14px",
                      }}
                    >
                      {/* <Checkbox /> Link another issue */}
                    </Typography>
                    <ButtonGroup
                      variant="contained"
                      aria-label="outlined button group"
                      className="shadow-none d-flex justify-content-between align-items-center gap-3"
                    >
                      <Button
                        sx={{
                          backgroundColor: `transparent !important`,
                          border: `1.5px solid ${colors.primary} !important`,
                          color: `${colors.primary} !important`,
                          borderRadius: "5px  !important",
                          fontSize: "10px !important",
                          textTransform: "capitalize",
                        }}
                        className="px-3"
                        type="button"
                        onClick={handleClose}
                      >
                        Close
                      </Button>
                      <Button
                        sx={{
                          backgroundColor: `${colors.primary} !important`,
                          border: "0px !important",
                          color: "#FFFFFF !important",
                          borderRadius: "5px  !important",
                          fontSize: "10px !important",
                          textTransform: "capitalize",
                        }}
                        className="px-3"
                        type="submit"
                      >
                        Submit
                      </Button>
                    </ButtonGroup>
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default CreateBacklogIssue;


