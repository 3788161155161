import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from "react-redux";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Typography, Button, ClickAwayListener, Popover } from "@mui/material";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { experimentalStyled as styled } from "@mui/material/styles";
import { getIssueTypeIcon, getPriorityIcon } from '../../utilities/Icons';
import { Tooltip } from "@mui/material";
import { taskTypesList } from '../../utilities/Json';
import BackgroundLetterAvatarsLogo from '../AvatarLogo';
import { Textarea } from '@mui/joy';
import IssueTypeMenuList from '../../common/IssueTypeMenuList';
import { FiPlus } from "react-icons/fi";
import { IssueStatusCardSkeleton, NormalSkeleton, CircularSkeleton } from '../../common/Skeleton';
import SearchableDropdown from '../Modal/SearchableDropDown';
import { getSprintsList, updateIssue } from '../../common/Redux/Reducer';

const EachTask = styled(Paper)(({ theme }) => ({
    backgroundColor: '#F4F6F9',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'start',
    color: theme.palette.text.secondary,
    minHeight: '150px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    border: '1px solid #CCCCCC',
    borderRadius: '12px',
    // minWidth: '238px',
    width: '100%'

}));



const ActiveSprintAssigneesLayout = ({ searchTerm = '', activeSprintId = '', issueStatusList = [], handleCreateIssueClick = () => { }, isEditing = false, currentTaskIndex = 0, issueType = "Task", setIssueType = () => { }, handleChangeIssueTitle = () => { }, handleClickAway = () => { }, handleTextareaSubmit = () => { }, onChangeModalStatus = () => { }, handleColumnDrop = () => { }, draggedTodoItem = {}, selectedIssueType = '', selectPriorityType = '' }) => {

    const { Projects, assignees, Skeleton, assigneeLayoutSkeleton } = useSelector((state) => {
        return state.ProjamAppSlice;
    });

    const SprintData = Projects?.selectedProject?.activeSprintAssigneesLayoutData
    const contributors = Projects?.selectedProject?.contributors

    const [data, setData] = useState(SprintData?.AssigneGroupObj || [])
    const [Employee, setEmployee] = useState('');

    const dispatch = useDispatch()

    useEffect(() => {
        if (SprintData?.AssigneGroupObj?.length > 0) {
            if (assignees && assignees?.length > 0) {
                let filteredAssigneesData = SprintData?.AssigneGroupObj?.filter((eachEmployee) =>
                    (assignees?.includes(eachEmployee?.employeeDetails?.employeeName) || eachEmployee?.employeeDetails?.employeeName === "unassigned")
                );

                // Filter issues based on searchTerm
                filteredAssigneesData = filteredAssigneesData?.map((eachEmployee) => {
                    return {
                        ...eachEmployee,
                        assignedIssues: eachEmployee?.assignedIssues?.filter((eachIssue) =>
                            eachIssue?.summary?.toLowerCase().includes(searchTerm.toLowerCase())
                        )
                    };
                });
                if (selectedIssueType) {
                    const fitleredIssueTypeData = filteredAssigneesData?.map((eachEmployee) => {
                        return {
                            ...eachEmployee,
                            assignedIssues: eachEmployee?.assignedIssues?.filter((eachIssue) => eachIssue?.issueType?.toLowerCase() === selectedIssueType?.toLowerCase())
                        }
                    });
                    setData(fitleredIssueTypeData)
                }
                if (selectPriorityType) {
                    const fitleredIssueTypeData = filteredAssigneesData?.map((eachEmployee) => {
                        return {
                            ...eachEmployee,
                            assignedIssues: eachEmployee?.assignedIssues?.filter((eachIssue) => eachIssue?.priority?.toLowerCase() === selectedIssueType?.toLowerCase())
                        }
                    });
                    setData(fitleredIssueTypeData)
                }

                else {
                    console.log('Filtered Assignees Data:', filteredAssigneesData);
                    setData(filteredAssigneesData)
                }
            } else {
                if (selectedIssueType) {
                    const fitleredIssueTypeData = SprintData?.AssigneGroupObj?.map((eachEmployee) => {
                        return {
                            ...eachEmployee,
                            assignedIssues: eachEmployee?.assignedIssues?.filter((eachIssue) => eachIssue?.issueType?.toLowerCase() === selectedIssueType?.toLowerCase())
                        }
                    })
                    setData(fitleredIssueTypeData);
                } else {
                    setData(SprintData?.AssigneGroupObj)
                }
            }
        }
    }, [assignees, selectedIssueType, SprintData, searchTerm, selectPriorityType]);


    const [anchorEl, setAnchorEl] = useState({ id: "", element: null });

    const handlePopOverClick = (event, rowId) => {
        setAnchorEl({ id: rowId, element: event.currentTarget });
    };

    const handlePopOverClose = () => {
        setAnchorEl({ id: "", element: null });
    };

    const handleAssigneeUpdate = async (taskId, selectedUser) => {
        dispatch(updateIssue({ issueId: taskId, payload: { assignee: selectedUser ? [selectedUser] : [] } }));
        dispatch(getSprintsList({ backlogId: localStorage.getItem("backlogId"), filterAssigneesArray: [] }));
        handlePopOverClose();

    };

    return (
        <div className='my-3'>
            {data?.map((eachEmployee, index) =>
                eachEmployee?.assignedIssues?.length > 0 || eachEmployee?.employeeDetails?.employeeName === "unassigned" ? <Box
                    sx={{ flexGrow: 1 }}
                    className="px-3 my-0"
                    key={`${eachEmployee?.employeeDetails?.employeeId}-${index}`}
                >
                    {(!Skeleton && !assigneeLayoutSkeleton) ? <Accordion
                        defaultExpanded={true}
                        className="issues-accordian-main-cls"
                    >
                        <div className="d-flex align-items-center">
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon sx={{ fontSize: '1.3 rem !important' }} />}
                                aria-controls={`panel1-content-${eachEmployee?.employeeDetails?.employeeId}`}
                                id={`panel1-header-${eachEmployee?.employeeDetails?.employeeId}`}
                                className="issues-accordian-summary-cls"
                            >
                                <div className='d-flex align-items-center'>
                                    <BackgroundLetterAvatarsLogo LogoTitle={eachEmployee?.employeeDetails?.employeeName} RequiredFontSize='10px !important' extraIdName="assignee-layout-avatar-cls" />
                                    <Typography sx={{ fontSize: "14px" }}>
                                        <b className="me-1">{eachEmployee?.employeeDetails?.employeeName}</b>
                                        ({eachEmployee?.assignedIssues?.length === 1 ? `${eachEmployee?.assignedIssues?.length} issue` : `${eachEmployee?.assignedIssues?.length} issues`})
                                    </Typography>
                                </div>
                            </AccordionSummary>
                        </div>
                        <AccordionDetails className="px-0">
                            <Grid
                                container
                                // spacing={{ xs: 2, md: 1 }}
                                columns={{ xs: 4, sm: 8, md: 12 }}
                                justifyContent="space-between"
                                alignItems="stretch"
                                flexWrap={'nowrap'}
                            >

                                {issueStatusList?.map((eachTask, index) => (

                                    <Grid item xs={2} sm={2} md={2} key={eachTask.title} sx={{
                                        maxWidth: '50% !important', paddingRight: '16px !important',
                                        '@media (max-width: 1400px)': {
                                            minWidth: `195px !important`,
                                            maxWidth: '450px !important',
                                            paddingRight: 2,
                                        }
                                    }}>
                                        <EachTask
                                            className="shadow-none p-1 task-card-hover-cls"
                                            onDragOver={(e) => e.preventDefault()}
                                            onDrop={(e) =>
                                                handleColumnDrop(
                                                    eachTask.title,
                                                    // eachProjectTask.name
                                                )
                                            }
                                        >
                                            {eachEmployee?.assignedIssues?.map((eachIssue, index) =>
                                                eachTask?.title?.toLowerCase() ===
                                                    eachIssue?.status?.toLowerCase() ? (
                                                    <Box
                                                        key={eachIssue?._id}
                                                        sx={{
                                                            backgroundColor: "#FFFFFF",
                                                            borderRadius: "5px",
                                                            minHeight: "50px",
                                                            width: "100%",
                                                            border: "1px solid #E3E3E3",
                                                        }}
                                                        className="pt-2 pb-1 px-2 d-flex flex-column justify-content-between task-item mb-1"
                                                        // draggable={eachIssue?.status?.toLowerCase() !== 'done'}
                                                        draggable
                                                        onDragStart={(e) =>
                                                            // (draggedTodoItem.current = eachIssue._id)
                                                            (draggedTodoItem.current = { id: eachIssue?._id, status: eachIssue?.status })
                                                        }
                                                        onDragOver={(e) => e.preventDefault()}
                                                    >

                                                        <Typography
                                                            className="task-title"
                                                            onClick={() =>
                                                                onChangeModalStatus(eachIssue)
                                                            }
                                                        >
                                                            {eachIssue?.summary}
                                                        </Typography>
                                                        <div
                                                            className="d-flex justify-content-between align-items-center mt-2"
                                                            style={{ width: "100%" }}
                                                        >
                                                            <div className='d-flex align-items-center'>
                                                                <Tooltip
                                                                    title={eachIssue?.issueType || "Story"}
                                                                >
                                                                    {getIssueTypeIcon(eachIssue?.issueType)}
                                                                </Tooltip>
                                                                <p className='ms-1 mb-0 issue-para-name-cls'>{eachIssue?.name}</p>
                                                            </div>
                                                            <div className="d-flex align-items-center" style={{ flexGrow: 1, justifyContent: "flex-end" }}>
                                                                <Tooltip
                                                                    title={eachIssue?.priority || "Urgent"}
                                                                >
                                                                    {getPriorityIcon(eachIssue?.priority)}
                                                                </Tooltip>
                                                                <Button size="small"
                                                                    className="assingeeBtnDropdown_main"
                                                                    aria-describedby={eachIssue?._id}
                                                                    onClick={(event) => handlePopOverClick(event, eachIssue?._id)}
                                                                >
                                                                    <BackgroundLetterAvatarsLogo LogoTitle={eachEmployee?.employeeDetails?.employeeName !== "unassigned" ? eachEmployee?.employeeDetails?.employeeName : ''} RequiredFontSize='8px !important' extraIdName="assignee-layout-issue-card-avatar-cls" />
                                                                </Button>
                                                                <Popover
                                                                    id={eachIssue?._id}
                                                                    open={anchorEl?.id === eachIssue?._id}
                                                                    anchorEl={anchorEl?.element}
                                                                    onClose={handlePopOverClose}
                                                                    anchorOrigin={{
                                                                        vertical: "bottom",
                                                                        horizontal: "left",
                                                                    }}
                                                                    PaperProps={{
                                                                        sx: {
                                                                            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                                                                            borderRadius: 0,
                                                                            padding: 1,
                                                                            width: 270,
                                                                        },
                                                                    }}
                                                                >
                                                                    <Box>
                                                                        <SearchableDropdown
                                                                            workFlowModalTaskData={eachIssue}
                                                                            members={contributors}
                                                                            handleAssigneeUpdate={handleAssigneeUpdate}
                                                                        />
                                                                    </Box>
                                                                </Popover>
                                                            </div>
                                                        </div>
                                                    </Box>
                                                ) : null
                                            )}
                                            {index === currentTaskIndex && isEditing && Employee?.employeeId === eachEmployee?.employeeDetails?.employeeId ? (
                                                <ClickAwayListener onClickAway={handleClickAway}>
                                                    <form
                                                        onSubmit={(event) => {
                                                            if (eachEmployee?.employeeDetails?.employeeName === "unassigned") {
                                                                handleTextareaSubmit(event, eachTask.title, [])
                                                            } else {
                                                                handleTextareaSubmit(event, eachTask.title, eachEmployee?.employeeDetails)
                                                            }
                                                        }
                                                        }
                                                    >
                                                        <Textarea
                                                            color="success"
                                                            placeholder="What needs to be done?"
                                                            className="sprint-grid-layout-create-issue-input"
                                                            minRows={2}
                                                            maxRows={10}
                                                            autoFocus
                                                            onChange={handleChangeIssueTitle}
                                                            endDecorator={
                                                                <div
                                                                    className="d-flex justify-content-between align-items-end"
                                                                    style={{ width: "100%" }}
                                                                >
                                                                    <IssueTypeMenuList
                                                                        labelText="Issue type"
                                                                        ItemsList={taskTypesList}
                                                                        setSelectedValue={setIssueType}
                                                                        selectedValue={issueType}
                                                                    />
                                                                    <Button
                                                                        type="submit"
                                                                        sx={{ ml: "auto" }}
                                                                        size="small"
                                                                        variant="contained"
                                                                        color="success"
                                                                        className="sprint-grid-layout-create-issue-btn"
                                                                    >
                                                                        Create
                                                                    </Button>
                                                                </div>
                                                            }
                                                        />
                                                    </form>
                                                </ClickAwayListener>
                                            ) : (
                                                <button
                                                    onClick={() => {
                                                        setEmployee(eachEmployee?.employeeDetails)
                                                        handleCreateIssueClick(index)

                                                    }}
                                                    className={`${index !== 0 ? "d-none" : ""
                                                        } active-sprints-create-issue-btn-cls py-2`}
                                                >
                                                    <FiPlus className="me-1" />
                                                    Create Issue
                                                </button>
                                            )}
                                        </EachTask>
                                    </Grid>))}
                            </Grid>
                        </AccordionDetails>
                    </Accordion> :
                        <div>
                            <div className='d-flex align-items-center my-2'>
                                <CircularSkeleton />
                                <NormalSkeleton width='200px' extraStyles=" ms-2" />
                            </div>
                            <Grid
                                container
                                spacing={{ xs: 2, md: 1 }}
                                columns={{ xs: 4, sm: 8, md: 12 }}
                                justifyContent="space-between"
                                alignItems="stretch"
                            >
                                {issueStatusList?.map((eachTask, index) => (
                                    <Grid item xs={2} sm={2} md={2} key={eachTask.title}>
                                        <EachTask
                                            className="shadow-none p-1 task-card-hover-cls">
                                            <IssueStatusCardSkeleton />
                                        </EachTask>
                                    </Grid>))}
                            </Grid>
                        </div>}

                </Box> : null
            )}
        </div>
    )
}


export default ActiveSprintAssigneesLayout