import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  IconButton,
  Box,
} from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import './index.scss'

const RemoveUserFromProject = ({ open, handleClose, handleConfirmRemove, userName }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Box display="flex" alignItems="center" className="dialog-title">
          <WarningAmberIcon  sx={{ marginRight: 1 , color:"#FFAB00 !important"}} />
          <b > Remove {userName} </b>
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {userName} won't be able to work on this project anymore.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} sx={{color:"#172b4d !important"}}>
          Cancel
        </Button>
        <Button onClick={handleConfirmRemove} sx={{color:"#60A630 !important"}} autoFocus>
          Remove
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RemoveUserFromProject;
