import React, { useState, useEffect } from "react";
import IssueAnalysis from "./Issue_Analysis";
import { Box, Grid, Typography } from "@mui/material";
import SprintAnalysis from "./Sprint_Analysis";
import BurndownChart from "./BurndownChart";
import axios from "axios";
import API_BASE_URL from "../components/config/appConfig";
import BreadcrumbsComponent from "../components/BreadcrumbsComponent";
import { NormalSkeleton, CircularSkeleton } from "../common/Skeleton";
import { useSelector, useDispatch } from "react-redux";
import SelectMenuTextField from "../components/SelectMenuTextField";
import { getLastFiveActiveSprintInfo, setSkeletonStatus } from "../common/Redux/Reducer";


const Reports = () => {
  const [barGraphData, setBarGraphData] = useState([]);
  const [pieChartData, setPieChartData] = useState([])
  const [sprintOptions, setSprintOptions] = useState([]);
  const [selectedSprint, setSelectedSprint] = useState(null) 
  const [burnDownSelectedSprintId, setBurnDownSelectedSprintId] = useState(null);
  const [burnDownChartData, setBurnDownChartData] = useState([])
  const dispatch = useDispatch()
  const { Skeleton,Projects } = useSelector((state) => state.ProjamAppSlice)
  let backlogId = Projects?.selectedProject?.selectedProjectBackLogId
  let projectId = Projects?.selectedProject?.selectedProjectData?._id
  let LastFiveActiveSprint = Projects.selectedProject.lastFiveSprintsInfo || []


  useEffect(() => {
    if(backlogId && projectId , LastFiveActiveSprint.length>0){
      getProjectInfo();
    }   
  }, [backlogId , projectId ,LastFiveActiveSprint ]);  

  const handleChange = (e) => {
    const selectedSprintId = e.target.value;
    setSelectedSprint(selectedSprintId);
    let selectedsprintData = sprintOptions.filter((eachSprint) => eachSprint?._id === selectedSprintId);
    setPieChartData((prev) => selectedsprintData);
  };
  
  const handeBurndownSprintChange = (e) => {
    setBurnDownSelectedSprintId(e.target.value);
    let burnDownData = sprintOptions.filter((eachSprint) => eachSprint?._id === e.target.value);
    setBurnDownChartData(burnDownData)

  }

  const getProjectInfo = async () => {
    try {
    //  await dispatch(setSkeletonStatus({ Skeleton: true }))
      // let response = await axios(`${API_BASE_URL}/sprints/lastFiveActive?backlogId=${backlogId}&projectId=${projectId}`);
   // let response=  await dispatch(getLastFiveActiveSprintInfo({backlogId , projectId})) 
    let  response = LastFiveActiveSprint
  //  console.log(response , 'redux')
      setSprintOptions(response)
      let AciveSprint = response?.filter((sprint) => {
        return sprint?.active === true
      })
      setSelectedSprint(AciveSprint?._id)
      setPieChartData(AciveSprint)
      setBurnDownChartData(AciveSprint)
    } catch (error) {
      console.log(error);
    } finally {
    //  await dispatch(setSkeletonStatus({ Skeleton: false }))
    }
  };

  return (
    <>
      <div className="ps-2"> {!Skeleton ? <BreadcrumbsComponent /> : <NormalSkeleton width={200} extraStyles="my-3 " />}</div>
      <Grid container spacing={1.2}>
        <Grid item xs={12} sm={6} >
          <Box
            className='shadow rounded'
            sx={{
              backgroundColor: "white",
              display: "flex",
              flexDirection: "column",
              py: 3,
              px: 2
            }}
          >
            <Box sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              py: !Skeleton ? 3 : 0
            }} >
              {!Skeleton ? <Typography sx={{ textAlign: 'center', fontWeight: "bold" }}>
                Issues Analysis
              </Typography> : <NormalSkeleton width={200} extraStyles="my-1" />}
              {!Skeleton ? <Box sx={{ width: 200 }}>
                <SelectMenuTextField
                  key={JSON.stringify(sprintOptions)}
                  labelText="Select Sprint"
                  options={sprintOptions}
                  onChange={(e) => handleChange(e)}
                  defaultValue={selectedSprint}
                />
              </Box> : <NormalSkeleton width={210} height={40} extraStyles="my-1" />}
            </Box>
            {!Skeleton ? <IssueAnalysis pieChartData={pieChartData} /> : <div className="d-flex justify-content-center"><CircularSkeleton width={200} height={200} /></div>}
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box
            className='shadow rounded'
            sx={{
              backgroundColor: "white",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              py: 3
            }}
          >
            {!Skeleton ? <Typography sx={{ textAlign: 'center' }}>
              Sprint Analysis
            </Typography> : <NormalSkeleton width={200} extraStyles="my-1" />}
            {!Skeleton ? <SprintAnalysis barGraphData={barGraphData} /> : <NormalSkeleton width={"90%"} height={220} extraStyles="my-1" />}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            className='shadow rounded'
            sx={{
              backgroundColor: "white",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              py: 3
            }}
          >
            <Box sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              p: !Skeleton ? 3 : 0,
              px: 3
            }} >
              {!Skeleton ? <Typography sx={{ textAlign: 'center' }}>
                Burndown Chart
              </Typography> : <NormalSkeleton width={200} extraStyles="my-1" />}
              {!Skeleton ? <Box >
                <SelectMenuTextField
                key={JSON.stringify(sprintOptions)}
                  labelText="Select Sprint"
                  options={sprintOptions}
                  onChange={(e) => handeBurndownSprintChange(e)}
                  defaultValue={burnDownSelectedSprintId}
                />
              </Box> : <NormalSkeleton width={250} height={40} extraStyles="my-1" />}
            </Box>
            {!Skeleton ? <BurndownChart burnDownChartData={burnDownChartData} /> : <NormalSkeleton width={"96%"} height={200} extraStyles="my-1" />}
          </Box>
        </Grid>
      </Grid>
      {/* </>
      )} */}
    </>
  );
};

export default Reports;
