import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  DialogActions,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { colors } from "../../utilities/colors";
import API_BASE_URL from "../config/appConfig";

const ActiveandInactiveModal = ({
  open,
  toggleModal,
  handleActive,
  projectData,
}) => {
 
  return (
    <Dialog
      open={open}
      aria-labelledby="sprint-start-dialog-title"
      maxWidth="sm"
      onClose={toggleModal}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginRight: 2,
        }}
      >
        <DialogTitle id="sprint-start-dialog-title">
          <Typography
            sx={{
              fontSize: "14px !important",
              color: colors.font_Bold,
              fontWeight: "bold",
            }}
          >
            Change Project Status
          </Typography>
        </DialogTitle>
        <IconButton aria-label="close" size="small">
          <CloseIcon
            fontSize="small"
            onClick={toggleModal}
          />
        </IconButton>
      </Box>
      <DialogContent>
        <Typography variant="body1">
          Do you want to change the status of the project:
        </Typography>
        <Typography sx={{ fontWeight: "bold", fontSize: "12px" }}>
          {projectData.title}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <ButtonGroup
            variant="contained"
            aria-label="outlined button group"
            className="shadow-none d-flex justify-content-between align-items-center gap-3"
          >
            <Button
              sx={{
                backgroundColor: `transparent !important`,
                border: `1.5px solid ${colors.primary} !important`,
                color: `${colors.primary} !important`,
                borderRadius: "5px  !important",
                fontSize: "10px !important",
                textTransform: "capitalize",
              }}
              className="px-3"
              type="button"
              onClick={toggleModal}
            >
              No
            </Button>
            <Button
              sx={{
                backgroundColor: `${colors.primary} !important`,
                border: "0px !important",
                color: "#FFFFFF !important",
                borderRadius: "5px  !important",
                fontSize: "10px !important",
                textTransform: "capitalize",
              }}
              className="px-3"
              type="submit"
              onClick={() => handleActive(projectData._id)}
            >
              Yes
            </Button>
          </ButtonGroup>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

ActiveandInactiveModal.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  handleActive: PropTypes.func.isRequired,
  projectData: PropTypes.object.isRequired,
};

export default ActiveandInactiveModal;
