import React, { useState } from "react";
import { Grid } from "@mui/material";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const CKTextEditor = ({ editorLabelText = "", payloadText = "", onChange }) => {
  const payload = {
    name: "",
    desc: payloadText,
    isDefault: "",
    isSystem: "",
  };
  const [errPayload, setErrPayload] = useState({});
  const [details, setDetails] = useState(payload);

  const sanitizeData = (data) => {
    return data.replace(/>\s+</g, '><').trim();
  };

  const handleEditorChange = (event, editor) => {
    let data = editor.getData();
    data = sanitizeData(data);
    setDetails({ ...details, desc: data });
    onChange(data);
  };

  function createCustomUploadAdapter(loader) {
    return {
      upload() {
        return loader.file.then((file) => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
              const base64 = reader.result;
              const storageKey = `image-${Date.now()}`;
              // localStorage.setItem(storageKey, base64);
              resolve({ default: base64 });
            };
            reader.onerror = reject;
            reader.readAsDataURL(file);
          });
        });
      },

      abort() {
      }
    };
  }

  function CustomUploadAdapterPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      return createCustomUploadAdapter(loader);
    };
  }

  return (
    <>
      <Grid container spacing={1}>
        <Grid item md={12} sm={12}
          className="search-bar"
          // style={{ height: '400px', overflow: 'auto' }}
          >
          {/* <label className="form-label">
            <b>{editorLabelText}</b>
          </label> */}
          <CKEditor
            error={errPayload && errPayload.desc === ""}
            helperText={
              errPayload && errPayload.desc === "" ? "Data Required" : ""
            }
            editor={ClassicEditor}
            data={details.desc}
            onReady={(editor) => {
            }}
            config={{
              extraPlugins: [CustomUploadAdapterPlugin],
            }}
            onChange={handleEditorChange}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default CKTextEditor;
