import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./containers/Home/index";
import LandingPage from "./containers/Home/LandingPage";
import Login from "./containers/Home/Login";
import Dashboard from "./containers/Dashboard/index";
import ProjectsPage from "./containers/Dashboard/ProjectPages/LandingProjectsPage";
import ProjectDetailsPage from "./containers/Dashboard/ProjectPages/ProjectDetailsPage/index";
import ActiveSprintPage from "./containers/Dashboard/ProjectPages/ProjectDetailsPage/ActiveSprintPage";
import BacklogPage from "./containers/Dashboard/ProjectPages/ProjectDetailsPage/BacklogPage";
import NotFoundPage from "./containers/NotFound/index";
import Register from "./containers/Home/Register";
import Protected from "./common/protect/Protected";
import IssuesList from "./components/List/IssuesList";
import Reports from "./Reports";
import ProjectSettings from "./containers/Dashboard/ProjectPages/ProjectDetailsPage/ProjectSettings";
import ViewReports from "./containers/ProjectsReports";
import Profile from './containers/Home/Profile';
import ForgotPassword from "./containers/Home/ForgotPassword"

const AppRoots = () => {
 
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />}>
            <Route index element={<LandingPage />} />
            <Route path="login" element={<Login />} />
            <Route path="register" element={<Register />} />
            <Route path="forgot-password" element={<ForgotPassword />} />

          </Route>
          <Route element={<Protected />}>
            <Route path="/projects" element={<Dashboard />}>
              <Route index element={<ProjectsPage />} />
              <Route path="profile" element={<Profile />} />
             
              <Route element={<ProjectDetailsPage />}>
                <Route path=":projectSlug/active-sprints" element={<ActiveSprintPage />} />
                <Route path=":projectSlug/backlog" element={<BacklogPage />} />
                <Route path=":projectSlug/issues-list" element={<IssuesList />} />
                <Route path=":projectSlug/dashboard" element={<Reports />} />
                <Route path="viewreports" element={<ViewReports/>}/>
                <Route path=':projectSlug/settings' element={<ProjectSettings />} />
              </Route>
            </Route>
           
          </Route>
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default AppRoots;
