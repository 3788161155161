import React from 'react'
import { Outlet } from 'react-router-dom';
import { Box } from '@mui/system';
import Footer from "../../components/Footer"
import Header from "../../components/TopBar/index"
import "./home.scss"
const index = () => {
    const pages = ['Features', 'Product guide', 'Templates', 'Pricing', 'Enterprise'];
    return (
        <Box>
            <Header pages={pages} isUserLoggedIn={false} />
            <Box pt={8}>
                <Outlet />
            </Box>
            <Footer />

        </Box>
    )
}

export default index