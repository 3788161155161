import React, { useState, useRef, useEffect } from 'react';
import Styled from 'styled-components';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Modal } from '@mui/material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ShareIcon from '@mui/icons-material/Share';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import LinkIcon from '@mui/icons-material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SelectMenuItems from '../SelectMenuItems';
import {
    Grid, TextField, Accordion,
    AccordionSummary,
    AccordionDetails, Button, FormControlLabel, Checkbox,
} from '@mui/material';
import CKTextEditor from '../CK-Text-Editor/index'
import "./modal.css";
import LockOpenIcon from '@mui/icons-material/LockOpen';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import Avatar from '@mui/material/Avatar';
import LanIcon from '@mui/icons-material/Lan';
import axios from 'axios';
import { getSprintsList, updateIssue, createIssue, getBacklogIssues } from "../../common/Redux/Reducer";
import { useSelector, useDispatch } from "react-redux";
import API_BASE_URL from "../../components/config/appConfig";
import DeleteConfirmationModal from './DeleteConfirmationModal';
import moment from "moment";
import IssueTypeMenuList from '../../common/IssueTypeMenuList'
import { MenuItemsList, taskTypesList, taskPriorityList } from '../../utilities/Json';
import { getPriorityIcon } from '../../utilities/Icons';

import SearchableDropdown from './SearchableDropDown';
import { jwtDecode } from "jwt-decode";
import BackgroundLetterAvatarsLogo from '../AvatarLogo';
import Tooltip from '@mui/material/Tooltip';
import LabelsDropdown from './LabalsDropDown';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import MultiSelect from './MultiSelect';
import { colors } from '../../utilities/colors';
// import EditIcon from '@mui/icons-material/Edit';
// import DeleteIcon from '@mui/icons-material/Delete';
// import Popover from "@mui/material/Popover";
// import CloseIcon from '@mui/icons-material/Close';
import { setLoaderStatus } from '../../common/Redux/Reducer';
import Skeleton from '@mui/material/Skeleton';
import IssueTestCases from "../IssueTestCases/index"
const ModalBody = Styled(Box)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    border-radius: 4px; 
    max-height: 90%;
    overflow: auto;
`

const buttonStyles = {
    backgroundColor: "red",
    color: "#fff",
    borderRadius: "8px",
    textTransform: "none",
    '&:hover': {
        backgroundColor: "red",
    }
};

const btnGroupBtnStyles = { backgroundColor: "#F4F5F7 !important", border: '0px !important', color: "#000000 !important", fontSize: '13px', fontWeight: 500, textTransform: "none !important" }

// Helper function to capitalize the first letter
const capitalizeFirstLetter = (string) => {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1);
};

const ViewWorkFlowModalPage = (props) => {
    const { width = '90%', hideBackdrop = false, open = false, handleChangeModalOpen = () => { }, issueStatusList, activeSprintName, setSelectedIssue = () => { }, projectDetails, handleSprintIssueClick = () => { }, selectedissueId = "null", getUpdatedBurnDownInfo = () => { } } = props;
    const [isEditing, setIsEditing] = useState(false);
    const [workFlowModalTaskData, setWorkFlowModalData] = useState({})
    const [newSummary, setNewSummary] = useState('');
    const [isHovered, setIsHovered] = useState(false);
    const [isDecHovered, setIsDecHovered] = useState(false);
    const [currentStatus, setCurrentStatus] = useState(workFlowModalTaskData?.status);
    const [storyPointEstimate, setStoryPointEstimate] = useState(workFlowModalTaskData?.storyPointEstimate || 0);
    const [isEditingStoryPoint, setIsEditingStoryPoint] = useState(false);
    const [isHoveredEstimate, setIsHoveredEstimate] = useState(false);
    const [isEditingDesc, setIsEditingDesc] = useState(false);
    const [newDescription, setNewDescription] = useState(workFlowModalTaskData?.description);
    const [testCases, setTestCases] = useState(workFlowModalTaskData?.testCases || "");
    const [isEditingTestCases, setIsEditingTestCases] = useState(false);
    const [isTestCasesHovered, setIsTestCasesHovered] = useState(false);

    const [status, setStatus] = useState(workFlowModalTaskData?.status);
    const [editingCommentId, setEditingCommentId] = useState(null);
    const [editingCommentText, setEditingCommentText] = useState('');
    const [priority, setPriority] = useState(() => {
        const foundType = taskPriorityList.find(type => type.name === workFlowModalTaskData?.priority);
        return foundType || taskPriorityList[0];
    });
    const [displayMode, setDisplayMode] = useState('');
    const [history, setHistory] = useState();
    const [openDeleteCommentDialog, setOpenDeleteCommentDialog] = useState(false);
    const [commentIdToDelete, setCommentIdToDelete] = useState(null);
    const [isCommentEditing, setIsCommentEditing] = useState(false);
    const [textComment, setTextComment] = useState('');
    const textCommentFieldRef = useRef();
    const [assignee, setAssignee] = useState(workFlowModalTaskData?.assignee?.length > 0 ? workFlowModalTaskData?.assignee[0] : { employeeName: 'unassigned' });
    const [userDetails, setUserDetails] = useState('userDetails')
    // const [comments, setComments] = useState(workFlowModalTaskData?.comments || []);
    const [comments, setComments] = useState([]);

    const textInputRef = useRef(null);
    const textInputRefstoryPoint = useRef(null);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    const { Projects } = useSelector((state) => state.ProjamAppSlice)
    const contributors = Projects?.selectedProject?.contributors;
    const issueStatus = Projects?.selectedProject?.selectedProjectData?.IssueStatus;
    const dispatch = useDispatch();
    const filterAssigneesArray = useSelector((state) => state.ProjamAppSlice.assignee)
    const [commetnslength, setCommetnslength] = useState('0')
    const [issueType, setIssueType] = useState(() => {
        const foundType = taskTypesList.find(type => type.name === workFlowModalTaskData?.issueType);
        return foundType || taskTypesList[0];
    });

    const [showSearchField, setShowSearchField] = useState(false);
    const [selectedIssues, setSelectedIssues] = useState([]);
    const [linkedIssues, setLinkedIssues] = useState(workFlowModalTaskData?.linkedissues || []);
    const AllIssues = Projects?.selectedProject?.ActiveSprint?.issues;
    const filteredIssues = Array.isArray(AllIssues) ? AllIssues.filter(issue => !selectedIssues.includes(issue) && issue.summary !== workFlowModalTaskData?.summary) : [];
    // const [linkedIssueStatus, setLinkedIssueStatus] = useState(workFlowModalTaskData?.linkedissues?.status);    
    const [linkedIssueStatuses, setLinkedIssueStatuses] = useState(() => {
        if (workFlowModalTaskData?.linkedissues) {
            return workFlowModalTaskData.linkedissues.reduce((acc, issue) => {
                acc[issue._id] = issue.status || '';
                return acc;
            }, {});
        }
        return {};
    });
    const [linkBtn, setLinkingBtn] = useState(false)
    const [disabledBtn, setDisabledBtn] = useState(false);
    const [addComment, setaddComment] = useState(false)
    const [loading, setLoading] = useState(true);
    const [replyLoad, setRplyLoad] = useState(false)
    const [savelink, setSaveLinking] = useState(false)
    const multiSelectRef = useRef();

    useEffect(() => {
        getIssueById(selectedissueId)
    }, [])

    const getIssueById = async (id) => {
        dispatch(setLoaderStatus({ loader: true }))
        // http://localhost:8000/api/v1/issues/getIssuesById/66b45bd7db22c42e4571dae3
        //let {workFlowModalTaskData} = props
        try {
            let res = await axios(`${API_BASE_URL}/issues/getIssuesById/${id}`)
            // workFlowModalTaskData=res?.data
            if (res?.data) {
                setWorkFlowModalData(res?.data);
                setCommetnslength(res?.data.comments?.length || 0)
                // console.log(commetnslength ,'commetnslength')
                dispatch(setLoaderStatus({ loader: false }))
            }
        } catch (error) {
            console.error("error");
        }
    }


    const handleLinkedissueStatusChange = async (issueId, newStatus) => {
        // if (linkedIssueStatuses[issueId] !== newStatus) {
        //     try {
        //         const updatedData = await updateStatus(issueId, newStatus);
        //         setWorkFlowModalData(updatedData)
        //         if (updatedData) {
        //             setLinkedIssueStatuses(prevStatuses => ({
        //                 ...prevStatuses,
        //                 [issueId]: newStatus
        //             }));
        //             dispatch(getSprintsList({
        //                 backlogId: localStorage.getItem("backlogId"),
        //                 filterAssigneesArray: []
        //             }));
        //         }
        //     } catch (error) {
        //         console.error("Error updating status:", error);
        //     }
        // }
    };

    const handleIssuesChange = (event, value) => {
        setSelectedIssues(value);
    };

    const handleLinkClick = async () => {
        const issueIds = selectedIssues.map(issue => issue._id);
        setLinkingBtn(true)
        try {
            const response = await axios.put(
                `${API_BASE_URL}/issues/updateIssue/${workFlowModalTaskData._id}?userid=${userDetails.id}`,
                {
                    linkedissues: issueIds
                });
            setLinkedIssues(response.data.linkedissues);
            setWorkFlowModalData(response.data)
            setSelectedIssue(response.data)
            // setComments(response.data?.comments);
            const newStatuses = response.data.linkedissues.reduce((acc, issue) => {
                acc[issue._id] = issue.status || '';
                return acc;
            }, {});
            setLinkedIssueStatuses(newStatuses);
            setShowSearchField(false)
            setSelectedIssues([])
            // dispatch(getSprintsList({ backlogId: Projects.selectedProject.selectedProjectBackLogId, filterAssigneesArray: [] }));
            // console.log("linkedIssues After res", response.data.linkedissues);
        } catch (err) {
            console.log(err);
        } finally {
            setLinkingBtn(false);
        }
    };
    // console.log("backlogId:Projects.selectedProject.selectedProjectBackLogId",Projects.selectedProject.selectedProjectBackLogId)
    const [anchorEl, setAnchorEl] = React.useState(null);
    let openPopOver = Boolean(anchorEl);
    const id = openPopOver ? "simple-popover" : undefined;
    const handlePopOverClick = (event, rowId) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopOverClose = () => {
        setAnchorEl(null);
    };


    // const [linkedIssueAssignees, setLinkedIssueAssignees] = useState(() => {
    //     if (linkedIssues) {
    //         return linkedIssues.reduce((acc, issue) => {
    //             acc[issue._id] = issue.assignee[0] || null;
    //             return acc;
    //         }, {});
    //     }
    //     return {};
    // });

    // const handleLinkedIssueAssigneeUpdate = async (issueId, selectedUser) => {
    //     console.log('Selected User  66c4624f85450b4bfc2d8a9a==:', selectedUser, "==>", issueId);

    //     if (!selectedUser || !issueId) return;

    //     try {
    //         // const updatedData = await dispatch(updateIssue({
    //         //     issueId: issueId,
    //         //     payload: {
    //         //         linkedissues: {
    //         //             assignee: selectedUser ? [selectedUser] : []
    //         //         }
    //         //     }
    //         // }));

    //         // console.log("updatedData", updatedData);
    //         // if (updatedData) {
    //         //     setWorkFlowModalData(prevData => ({
    //         //         ...prevData,
    //         //         linkedissues: prevData.linkedissues.map(issue => 
    //         //             issue._id === issueId ? { ...issue, assignee: selectedUser ? [selectedUser] : [] } : issue
    //         //         )
    //         //     }));
    //         //     dispatch(getSprintsList({
    //         //         backlogId: localStorage.getItem("backlogId"),
    //         //         filterAssigneesArray: []
    //         //     }));
    //         // }
    //     } catch (error) {
    //         console.error('Error updating assignee:', error);
    //     }
    // };

    useEffect(() => {
        if (workFlowModalTaskData?.status) {
            setCurrentStatus(workFlowModalTaskData.status);
        }
    }, [workFlowModalTaskData, currentStatus])


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (textInputRef.current && !textInputRef.current.contains(event.target)) {
                handleBlurSummery()
                setIsEditing(false);
                setIsHovered(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [textInputRef.current, newSummary]);


    const handleHover = () => {
        setIsHovered(true);
    };

    const handleEdit = () => {
        setIsEditing(true);
        setNewSummary(newSummary ? newSummary : workFlowModalTaskData.summary);
    };

    const handleChange = (event) => {
        setNewSummary(event.target.value);
    };

    // const handleSave = async () => {
    //     try {
    //         // dispatch(updateIssue({ issueId: workFlowModalTaskData?._id, payload: { summary: newSummary } }));
    //         const response = await dispatch(updateIssue({ 
    //             issueId: workFlowModalTaskData?._id, 
    //             payload: { summary: newSummary } 
    //           })).unwrap();
    //           setSelectedIssue(response)
    //           setWorkFlowModalData(response)
    //         setComments(response?.comments);
    //         setIsEditing(false);
    //          dispatch(getSprintsList({ backlogId: Projects.selectedProject.selectedProjectBackLogId, filterAssigneesArray: filterAssigneesArray }));
    //     } catch (error) {
    //         console.error('Error updating summary:', error);
    //     }
    // };

    const handleSave = async () => {
        if (newSummary === workFlowModalTaskData?.summary) {
            setIsEditing(false);
            return;
        }
        try {
            const response = await dispatch(updateIssue({
                issueId: workFlowModalTaskData?._id,
                payload: { summary: newSummary }
            })).unwrap();
            setSelectedIssue(response);
            setWorkFlowModalData(response);
            // setComments(response?.comments);
            setIsEditing(false);
            dispatch(getSprintsList({
                backlogId: Projects.selectedProject.selectedProjectBackLogId,
                filterAssigneesArray: filterAssigneesArray
            }));
            dispatch(getBacklogIssues(`${Projects.selectedProject.selectedProjectBackLogId}`))

        } catch (error) {
            console.error('Error updating summary:', error);
        }
    };

    const handleBlurSummery = () => {
        handleSave();
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSave();
            setIsEditing(false);
        }
    };

    const handleDecHover = () => {
        setIsDecHovered(true);
    };
    const handleTestCasesHover = () => {
        setIsTestCasesHovered(true);
    };
    const [selectedImage, setSelectedImage] = useState(null);

    const handleBoxClick = (event, isTestCases = false) => {
        if (event.target.tagName === 'IMG') {
            setSelectedImage(event.target.src);
            setIsEditingDesc(false)
            setIsEditingTestCases(false)
        }
        if (isTestCases) {
            setIsEditingTestCases(true)
            setIsEditingDesc(false);
        } else {
            setIsEditingTestCases(false)
            setIsEditingDesc(true);
        }

    };
    const handleCloseImage = () => {
        setSelectedImage(null)
        setIsEditingDesc(false)
    }

    const handleDescriptionChange = (data) => {
        setNewDescription(data);
        // console.log(data)
    };
    const handleTestCasesChange = (data) => {
        setTestCases(data)
    }


    const handleUpdateDec = async () => {
        setDisabledBtn(true)
        try {
            const response = await dispatch(updateIssue({
                issueId: workFlowModalTaskData?._id,
                payload: { description: newDescription }
            })).unwrap();
            setSelectedIssue(response)
            setWorkFlowModalData(response)
            setIsEditingDesc(false);
            // dispatch(getSprintsList({ backlogId: localStorage.getItem("backlogId"), filterAssigneesArray: filterAssigneesArray }));
            dispatch(getSprintsList({ backlogId: Projects.selectedProject.selectedProjectBackLogId, filterAssigneesArray: filterAssigneesArray }));
            dispatch(getBacklogIssues(`${Projects.selectedProject.selectedProjectBackLogId}`))

        } catch (error) {
            console.error('Error updating summary:', error);
        } finally {
            setDisabledBtn(false);
        }
    };
    const handleUpdateTestCases = async () => {
        setDisabledBtn(true)
        try {
            const response = await dispatch(updateIssue({
                issueId: workFlowModalTaskData?._id,
                payload: { testCases: testCases }
            })).unwrap();
            setSelectedIssue(response)
            setWorkFlowModalData(response)
            setIsEditingTestCases(false);
            // dispatch(getSprintsList({ backlogId: localStorage.getItem("backlogId"), filterAssigneesArray: filterAssigneesArray }));
            dispatch(getSprintsList({ backlogId: Projects.selectedProject.selectedProjectBackLogId, filterAssigneesArray: filterAssigneesArray }));
            dispatch(getBacklogIssues(`${Projects.selectedProject.selectedProjectBackLogId}`))

        } catch (error) {
            console.error('Error updating summary:', error);
        } finally {
            setDisabledBtn(false);
        }
    }

    const handleCloseEditor = () => {
        setIsEditingDesc(false);
        setIsEditingTestCases(false);
        setNewDescription(workFlowModalTaskData?.description || "")
        setTestCases(workFlowModalTaskData?.testCases || "")
    }

    const handleDeleteIssue = async () => {
        try {
            const response = await axios.delete(`${API_BASE_URL}/issues/deleteIssue/${workFlowModalTaskData._id}`);
            // console.log('Delete response:', response.data);
            // dispatch(getSprintsList({ backlogId: localStorage.getItem("backlogId"), filterAssigneesArray: filterAssigneesArray }));
            dispatch(getSprintsList({ backlogId: Projects.selectedProject.selectedProjectBackLogId, filterAssigneesArray: filterAssigneesArray }));
            dispatch(getBacklogIssues(`${Projects.selectedProject.selectedProjectBackLogId}`))
            handleChangeModalOpen();
            setOpenDeleteDialog(false);
        } catch (error) {
            console.error('Error deleting issue:', error);
        }
    };

    const handleCloseModel = () => {
        // dispatch(getSprintsList({ backlogId: Projects.selectedProject.selectedProjectBackLogId, filterAssigneesArray: filterAssigneesArray }));
        // dispatch(getBacklogIssues(`${backlogId: Projects.selectedProject.selectedProjectBackLogId}`))
        // dispatch(getBacklogIssues(`${Projects.selectedProject.selectedProjectBackLogId}`))
        handleChangeModalOpen();
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (textInputRefstoryPoint.current && !textInputRefstoryPoint.current.contains(event.target)) {
                handleBlur();
                setIsEditingStoryPoint(false);
                setIsHoveredEstimate(false)
            }
        };
        if (isEditingStoryPoint) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [storyPointEstimate]);

    const handlestoryPointEstimateChange = (event) => {
        const value = event.target.value;

        if (!isNaN(value) && value <= 8) {
            setStoryPointEstimate(value);
        }
    };

    const handleHoverEstimate = () => {
        setIsHoveredEstimate(true);
    };

    const handleSaveStoryPoint = async () => {
        if (storyPointEstimate === workFlowModalTaskData?.storyPointEstimate) {
            setIsEditingStoryPoint(false);
            return;
        }
        try {
            // dispatch(updateIssue({ issueId: workFlowModalTaskData?._id, payload: { storyPointEstimate: storyPointEstimate } }));
            const response = await dispatch(updateIssue({
                issueId: workFlowModalTaskData?._id,
                payload: { storyPointEstimate: storyPointEstimate }
            })).unwrap();
            setSelectedIssue(response)
            setWorkFlowModalData(response)
            setIsEditingStoryPoint(false);
            dispatch(getSprintsList({ backlogId: Projects.selectedProject.selectedProjectBackLogId, filterAssigneesArray: filterAssigneesArray }));
            dispatch(getBacklogIssues(`${Projects.selectedProject.selectedProjectBackLogId}`))
            getUpdatedBurnDownInfo()
        } catch (error) {
            console.error('Error updating story point estimate:', error);
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSaveStoryPoint();
        }
    };

    const handleBlur = () => {
        handleSaveStoryPoint();
    };

    const handleEditEstimate = () => {
        setIsEditingStoryPoint(true);
    };

    const handleIssueTypeChange = async (selectedType) => {
        setIssueType(selectedType);
        try {
            // dispatch(updateIssue({ issueId: workFlowModalTaskData?._id, payload: { issueType: selectedType.name } }));
            const response = await dispatch(updateIssue({
                issueId: workFlowModalTaskData?._id,
                payload: { issueType: selectedType.name }
            })).unwrap();
            setSelectedIssue(response)
            setWorkFlowModalData(response)
            // dispatch(getSprintsList({ backlogId: localStorage.getItem("backlogId"), filterAssigneesArray: filterAssigneesArray }));
            dispatch(getSprintsList({ backlogId: Projects.selectedProject.selectedProjectBackLogId, filterAssigneesArray: filterAssigneesArray }));
            dispatch(getBacklogIssues(`${Projects.selectedProject.selectedProjectBackLogId}`))

        } catch (error) {
            console.error('Failed to update issue type:', error);
        }
    };

    const updateStatus = async (issueId, newStatus) => {
        const token = localStorage.getItem("accessToken");
        const decoded = jwtDecode(token);
        const userId = decoded?.id;
        try {
            const response = await axios.put(`${API_BASE_URL}/issues/updateIssue/${issueId}?userid=${userId}`, {
                status: newStatus,
            });
            setWorkFlowModalData(response.data)
            setSelectedIssue(response.data)
            return response.data;

        } catch (error) {
            console.error('Error updating status:', error);
            return null;
        }
    };

    const handleStatusChange = async (newStatus) => {
        // const statusToSend =
        //     newStatus === 'Code Review' ? 'In Review' :
        //         newStatus === 'In Testing' ? 'QA' :
        //             newStatus; 
        if (newStatus !== currentStatus) {
            const updatedData = await updateStatus(workFlowModalTaskData._id, newStatus);
            if (updatedData) {
                setCurrentStatus(newStatus);
                // dispatch(getSprintsList({
                //     backlogId: localStorage.getItem("backlogId"),
                //     filterAssigneesArray: []
                // }));
                dispatch(getSprintsList({ backlogId: Projects.selectedProject.selectedProjectBackLogId, filterAssigneesArray: [] }));
                dispatch(getBacklogIssues(`${Projects.selectedProject.selectedProjectBackLogId}`))
                if (newStatus == 'Done' || currentStatus == 'Done') {
                    getUpdatedBurnDownInfo()
                }

            }
        }
    };


    const handleAssigneeUpdate = async (taskId, selectedUser) => {
        // console.log("taskId", taskId, " ", "selectedUser", selectedUser)
        try {
            // dispatch(updateIssue({ issueId: taskId, payload: { assignee: selectedUser ? [selectedUser] : [] } }));
            const response = await dispatch(updateIssue({
                issueId: workFlowModalTaskData?._id,
                payload: { assignee: selectedUser ? [selectedUser] : [] }
            })).unwrap();
            setSelectedIssue(response)
            setWorkFlowModalData(response)
            setAssignee(selectedUser);
            dispatch(getSprintsList({ backlogId: Projects.selectedProject.selectedProjectBackLogId, filterAssigneesArray: [] }));
            dispatch(getBacklogIssues(`${Projects.selectedProject.selectedProjectBackLogId}`))
            // dispatch(getSprintsList({ backlogId: localStorage.getItem("backlogId"), filterAssigneesArray: [] }));
        } catch (error) {
            console.error('Error updating assignee:', error);
        }
    };

    const handleCommentFieldClick = () => {
        setIsCommentEditing(true);
    };

    const handleCommentEditorChange = (data) => {
        setTextComment(data);
    };

    const handleClose = () => {
        getSprintsList()
        setIsCommentEditing(false)
        setTextComment('')
    }


    useEffect(() => {
        getUserDetails()
    }, [])

    const getUserDetails = async () => {
        let token = localStorage.getItem('accessToken');
        if (token) {
            const decoded = jwtDecode(token)
            setUserDetails(decoded)
        }
    }

    const handleComment = async () => {
        if (!textComment.trim()) {
            setIsCommentEditing(false);
            return;
        }
        try {
            setaddComment(true)
            const response = await axios.post(`${API_BASE_URL}/issues/addcomment/${workFlowModalTaskData._id}`, {
                userId: userDetails.id,
                comment: textComment,
            });
            // setWorkFlowModalData(response.data)
            // setSelectedIssue(response.data)
            // setComments(response.data?.comments)
            setCommetnslength(response?.data?.comments?.length)
            setIsCommentEditing(false);
            setTextComment('');
            setDisplayMode('comments')
            getAllComments();
            // dispatch(getSprintsList({ backlogId: localStorage.getItem("backlogId"), filterAssigneesArray: filterAssigneesArray }));
            // dispatch(getSprintsList({ backlogId: Projects.selectedProject.selectedProjectBackLogId, filterAssigneesArray: filterAssigneesArray }));

        } catch (error) {
            console.error('Error updating summary:', error);
        } finally {
            setaddComment(false)
        }
    };

    const handleEditComment = (commentId, commentText) => {
        setEditingCommentId(commentId);
        setEditingCommentText(commentText);
    }

    const handleCommentUpdateEditorChange = (data) => {
        setEditingCommentText(data);
    };

    const handleUpdateComment = async (commentId) => {
        try {
            const response = await axios.put(`${API_BASE_URL}/comment/update/${commentId}`, {
                userId: userDetails.id,
                comment: editingCommentText,
                issueId: workFlowModalTaskData._id,
            });
            // setComments(response.data?.comments);
            setEditingCommentId(null);
            setEditingCommentText('');
            getAllComments();
            // dispatch(getSprintsList({ backlogId: Projects.selectedProject.selectedProjectBackLogId, filterAssigneesArray: filterAssigneesArray }));
            // dispatch(getSprintsList({ backlogId: localStorage.getItem("backlogId"), filterAssigneesArray: filterAssigneesArray }));
        } catch (error) {
            console.error('Error updating comment:', error);
        }
    };

    // const sortedComments = comments ? [...comments].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) : [];

    const handleOpenDeleteCommentDialog = (commentId) => {
        setCommentIdToDelete(commentId);
        setOpenDeleteCommentDialog(true);
    };

    const handleDeleteComment = async () => {
        if (!commentIdToDelete) return;
        try {
            const response = await axios.delete(`${API_BASE_URL}/comment/delete/${commentIdToDelete}?issueId=${workFlowModalTaskData._id}`);
            setCommetnslength(response?.data?.comments?.length)
            //console.log(commetnslength ,'647')
            // setComments(response.data.comments);
            // dispatch(getSprintsList({ backlogId: localStorage.getItem("backlogId"), filterAssigneesArray }));
            // dispatch(getSprintsList({ backlogId: Projects.selectedProject.selectedProjectBackLogId, filterAssigneesArray: [] }));
            setOpenDeleteCommentDialog(false);
            setCommentIdToDelete(null);
            getAllComments();
        } catch (error) {
            console.error('Error deleting comment:', error);
        }
    };

    const handleCloseUpdateComment = () => {
        setEditingCommentId(null)
        setEditingCommentText('')
        setTextComment('')
    }

    const handlePriorityChange = async (selectedType) => {
        setPriority(selectedType);
        try {
            // dispatch(updateIssue({ issueId: workFlowModalTaskData?._id, payload: { priority: selectedType.name } }));
            const response = await dispatch(updateIssue({
                issueId: workFlowModalTaskData?._id,
                payload: { priority: selectedType.name }
            })).unwrap();
            setSelectedIssue(response)
            setWorkFlowModalData(response)
            dispatch(getSprintsList({ backlogId: Projects.selectedProject.selectedProjectBackLogId, filterAssigneesArray: filterAssigneesArray }));
            dispatch(getBacklogIssues(`${Projects.selectedProject.selectedProjectBackLogId}`))
            // dispatch(getSprintsList({ backlogId: localStorage.getItem("backlogId"), filterAssigneesArray: filterAssigneesArray }));
        } catch (error) {
            console.error('Failed to update issue type:', error);
        }
    };

    const handleLabelChange = async (newLabel) => {
        console.log('Selected Label:', newLabel);
        try {
            // dispatch(updateIssue({ issueId: workFlowModalTaskData?._id, payload: { label: newLabel.value } }));
            const response = await dispatch(updateIssue({
                issueId: workFlowModalTaskData?._id,
                payload: { label: newLabel.value }
            })).unwrap();
            setSelectedIssue(response)
            setWorkFlowModalData(response)
            // dispatch(getSprintsList({ backlogId: Projects.selectedProject.selectedProjectBackLogId, filterAssigneesArray: filterAssigneesArray }));

            // dispatch(getSprintsList({ backlogId: localStorage.getItem("backlogId"), filterAssigneesArray: filterAssigneesArray }));
        } catch (error) {
            console.error('Failed to update issue type:', error);
        }
    };

    const fetchCommentsHistory = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/issuehistory/gethistory/${workFlowModalTaskData?._id}`);
            setHistory(response.data);
        } catch (err) {
            console.log(err);
        }
    };

    const getAllComments = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/comment/getAllcomments/${workFlowModalTaskData?._id}`);
            setComments(response.data.comments.reverse());
            // console.log("Comments",response.data.comments)
            setCommetnslength(response.data.comments?.length)
            setLoading(false)
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        // fetchCommentsHistory();
    }, [issueType, priority, filterAssigneesArray, storyPointEstimate, assignee, status, comments]);


    const commentsRef = useRef(null);
    const skeletonRef = useRef(null);
    const [shouldScroll, setShouldScroll] = useState(false);

    useEffect(() => {
        if (shouldScroll) {
            if (loading && skeletonRef.current) {
                skeletonRef.current.scrollIntoView({ behavior: 'smooth' });
            } else if (!loading && commentsRef.current) {
                commentsRef.current.scrollIntoView({ behavior: 'smooth' });
            }
            setShouldScroll(false);
        }
    }, [loading, comments, shouldScroll]);


    const handleButtonClick = (mode) => {
        if (displayMode !== mode) {
            setDisplayMode(mode);
            if (mode === 'history') {
                fetchCommentsHistory();
            } else if (mode === 'comments') {
                setShouldScroll(true)
                getAllComments();
            }
        }
    };

    const getTaskTypeIcon = (typeName) => {
        const taskType = taskTypesList.find(type => type.name === typeName);
        return taskType ? taskType.icon : null;
    };

    const getTaskPriorityIcon = (priorityName) => {
        const taskPriority = taskPriorityList.find(priority => priority.name === priorityName);
        return taskPriority ? taskPriority.icon : null
    }

    const applyImageStyles = (html) => {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = html;
        const images = tempDiv.querySelectorAll('img');
        images.forEach(img => {
            img.style.width = '500px';
            img.style.height = 'auto';
        });

        return tempDiv.innerHTML;
    };

    const [replyingToCommentId, setReplyingToCommentId] = useState(null);
    const [replyText, setReplyText] = useState('');
    const [isReplyCommentEditing, setIsReplyCommentEditing] = useState(false);
    const [openDeleteReplyDialog, setOpenDeleteReplyDialog] = useState(false);


    const handleReplyClickButton = (commentId) => {
        // setReplyingToCommentId(commentId);
        // setIsReplyCommentEditing(true);
        if (replyingToCommentId === commentId) {
            // If the comment is already being replied to, toggle off
            setReplyingToCommentId(null);
            setIsReplyCommentEditing(false);
        } else {
            // Set the replying comment ID and enable editing
            setReplyingToCommentId(commentId);
            // setIsReplyCommentEditing(true);
        }
    };

    const handleReplyCommentEditorChange = (data) => {
        setReplyText(data);
        console.log(data, 'data')
    };
    // console.log("userDetails",userDetails)
    const handleSubmitReply = async (comment, issueId) => {
        const formattedComment = `<span class="mention">@${comment.name} </span> ${replyText}`;
        try {
            setRplyLoad(true)
            const response = await axios.post(`${API_BASE_URL}/comment/reply`, {
                userId: userDetails.id,
                name: userDetails.name,
                email: userDetails.email,
                // role: userDetails.role,
                comment: formattedComment,
                parentCmnt: comment._id,
                issueId: issueId
            }, {
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            // console.log('API Response:', response.data);
            // setComments(response.data?.comments)
            setReplyingToCommentId(null);
            setReplyText('');
            setIsReplyCommentEditing(false);
            //setWorkFlowModalData(response?.data)
            //setSelectedIssue(response?.data)
            getAllComments();
            // dispatch(getSprintsList({ backlogId: Projects.selectedProject.selectedProjectBackLogId, filterAssigneesArray: [] }));
            // dispatch(getSprintsList({ backlogId: localStorage.getItem("backlogId"), filterAssigneesArray: [] }));
        }
        catch (err) {
            console.error('API Request Error:', err.response ? err.response.data : err.message);
        } finally {
            setRplyLoad(false)
        }
    };

    const handleCloseReply = () => {
        setIsReplyCommentEditing(false);
        setReplyText('');
    };
    // const sortedReplies = Array.from(comment?.replies || []).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    const [editingReplyId, setEditingReplyId] = useState(null);
    const [replyContent, setReplyContent] = useState('');

    const handleEditClick = (reply) => {
        setEditingReplyId(reply._id);
        setReplyContent(reply.comment);
    };

    const handleUpdateClick = async () => {
        if (editingReplyId) {
            try {
                const response = await axios.put(`${API_BASE_URL}/comment/updateReply/${editingReplyId}`, {
                    comment: replyContent,
                    issueId: workFlowModalTaskData._id
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });
                // setComments(response.data?.comments)
                // setWorkFlowModalData(response.data);
                // setSelectedIssue(response.data)
                setEditingReplyId(null);
                setReplyContent('');
                getAllComments();

                // dispatch(getSprintsList({ backlogId: Projects.selectedProject.selectedProjectBackLogId, filterAssigneesArray: [] }));
                // dispatch(getSprintsList({ backlogId: localStorage.getItem("backlogId"), filterAssigneesArray: [] }));

            } catch (error) {
                console.error('Error updating reply:', error);
            }
        }
    };

    const handleCancelEdit = () => {
        setEditingReplyId(null);
        setReplyContent('');
    };

    const handleReplyCommentUpdateEditorChange = (data) => {
        setReplyContent(data);
    };
    const [replyToDelete, setReplyToDelete] = useState(null);

    const handleDeleteCommentReply = async (replyId) => {
        try {
            const response = await axios.delete(`${API_BASE_URL}/comment/deleteReply/${replyId}?issueId=${workFlowModalTaskData?._id}`);
            // console.log("response.data", response.data)
            // setComments(response.data?.comments);
            // setWorkFlowModalData(response.data);
            // setSelectedIssue(response.data)

            setOpenDeleteReplyDialog(false);
            getAllComments();
            // dispatch(getSprintsList({ backlogId: localStorage.getItem("backlogId"), filterAssigneesArray: [] }));
        } catch (error) {
            console.error('Error deleting reply:', error);
        }
    };

    const [openDeleteLinkedissueModal, setOpenDeleteLinkedissueModal] = useState(false);
    const [issueToDelete, setIssueToDelete] = useState(null);

    const handleDeleteLinkedClick = (issue) => {
        setIssueToDelete(issue);
        setOpenDeleteLinkedissueModal(true);
    };
    const handleDeleteLinkedIssue = async () => {
        try {
            const response = await axios.put(`${API_BASE_URL}/issues/unlinkfrom/${workFlowModalTaskData?._id}?userid=${userDetails.id}`, {
                unlinkIssue: issueToDelete._id
            });
            // console.log("response.data", response.data)
            // setWorkFlowModalData(response.data);
            // setSelectedIssue(response.data);
            setLinkedIssues(response.data.linkedissues);
            setOpenDeleteLinkedissueModal(false);
            // dispatch(getSprintsList({ backlogId: Projects.selectedProject.selectedProjectBackLogId, filterAssigneesArray: [] }));
            // dispatch(getSprintsList({ backlogId: localStorage.getItem("backlogId"), filterAssigneesArray: [] }));
        } catch (error) {
            console.error('Error deleting reply:', error);
        }
    };

    const [showInputLink, setShowInputLink] = useState(false)
    const [createIssueLink, setCreateIssueLink] = useState('')
    const handleCreateLink = () => {
        setShowInputLink(!showInputLink)
    }

    // const handleSaveLink = async () => {
    //     setSaveLinking(true)
    //     const Values = {
    //         project: Projects?.selectedProject?.selectedProjectData?._id,
    //         summary: createIssueLink,
    //         backlog: Projects.selectedProject.selectedProjectBackLogId,
    //         sprint: workFlowModalTaskData?.sprint,
    //     };

    //     if (createIssueLink) {
    //         try {
    //             const createResponse = await dispatch(createIssue(Values)).unwrap();
    //             const newIssueId = createResponse?._id;
    //             const issueIdsToUpdate = [...selectedIssues.map(issue => issue._id), newIssueId];
    //             const updateResponse = await axios.put(
    //                 `${API_BASE_URL}/issues/updateIssue/${workFlowModalTaskData._id}?userid=${userDetails.id}`,
    //                 {
    //                     linkedissues: issueIdsToUpdate
    //                 }
    //             );
    //             setLinkedIssues(updateResponse.data.linkedissues);
    //             setWorkFlowModalData(updateResponse.data);
    //             setSelectedIssue(updateResponse.data);
    //             const newStatuses = updateResponse.data.linkedissues.reduce((acc, issue) => {
    //                 acc[issue._id] = issue.status || '';
    //                 return acc;
    //             }, {});
    //             setLinkedIssueStatuses(newStatuses);
    //             setShowSearchField(false);
    //             setSelectedIssues([]);
    //             // dispatch(getSprintsList({ backlogId: Projects.selectedProject.selectedProjectBackLogId, filterAssigneesArray: [] }));
    //             // await dispatch(getSprintsList({ backlogId: localStorage.getItem("backlogId"), filterAssigneesArray: [] }));
    //         } catch (error) {
    //             console.error("Error during save and update:", error);
    //         }
    //         setShowInputLink(false);
    //         setCreateIssueLink('');
    //         setSaveLinking(false)
    //     }
    // };

    const handleSaveLink = async () => {
        setSaveLinking(true)
        const Values = {
            project: Projects?.selectedProject?.selectedProjectData?._id,
            summary: createIssueLink,
            backlog: Projects.selectedProject.selectedProjectBackLogId,
            sprint: workFlowModalTaskData?.sprint,
        };
        if (createIssueLink) {
            try {
                let createResponse;
                let newIssueId;
                if (!Values.sprint) {
                    const Data = {
                        project: Values.project,
                        summary: createIssueLink,
                        backlog: Values.backlog,
                        sprint: null,
                    };
                    createResponse = await axios.post(
                        `${API_BASE_URL}/issues/createBacklogIssue`,
                        Data
                    );
                    newIssueId = createResponse?.data?._id;
                    // console.log("createBacklogIssue ID:", newIssueId);
                } else {
                    createResponse = await dispatch(createIssue(Values)).unwrap();
                    newIssueId = createResponse?._id;
                    // console.log("createIssue ID:", newIssueId);
                }
                const issueIdsToUpdate = [...selectedIssues.map(issue => issue._id), newIssueId];
                const updateResponse = await axios.put(
                    `${API_BASE_URL}/issues/updateIssue/${workFlowModalTaskData._id}?userid=${userDetails.id}`,
                    {
                        linkedissues: issueIdsToUpdate
                    }
                );
                setLinkedIssues(updateResponse.data.linkedissues);
                setWorkFlowModalData(updateResponse.data);
                setSelectedIssue(updateResponse.data);
                setSelectedIssue(updateResponse.data);
                const newStatuses = updateResponse.data.linkedissues.reduce((acc, issue) => {
                    acc[issue._id] = issue.status || '';
                    return acc;
                }, {});
                setLinkedIssueStatuses(newStatuses);
                setShowSearchField(false);
                setSelectedIssues([]);
                // dispatch(getSprintsList({ backlogId: Projects.selectedProject.selectedProjectBackLogId, filterAssigneesArray: [] }));
                // await dispatch(getSprintsList({ backlogId: localStorage.getItem("backlogId"), filterAssigneesArray: [] }));
                dispatch(getSprintsList({ backlogId: Projects.selectedProject.selectedProjectBackLogId, filterAssigneesArray: [] }));
                dispatch(getBacklogIssues(`${Projects.selectedProject.selectedProjectBackLogId}`))
            } catch (error) {
                console.error("Error during save and update:", error);
            }
            setShowInputLink(false);
            setCreateIssueLink('');
            setSaveLinking(false)
            setSaveLinking(false);
        }
    };


    const handleChangeCreatLink = (e) => {
        setCreateIssueLink(e.target.value)
    }

    const handleClickOpenImage = (event) => {
        if (event.target.tagName === 'IMG') {
            setSelectedImage(event.target.src);
        }
    };

    const handleClickOpenReplyImage = (event) => {
        if (event.target.tagName === 'IMG') {
            setSelectedImage(event.target.src);
        }
    };


    const handleLinkeModel = (issue) => {
        // console.log("LinkedIssue")
        getIssueById(issue._id);
        setWorkFlowModalData(issue)
        setSelectedIssue(issue)
        setDisplayMode('')
    }

    useEffect(() => {
        // console.log('Current workFlowModalTaskData:', workFlowModalTaskData);
        const token = localStorage.getItem('accessToken');
        if (workFlowModalTaskData) {
            const foundType = taskPriorityList.find(type => type.name === workFlowModalTaskData?.priority);
            const foundIssueType = taskTypesList.find(type => type.name === workFlowModalTaskData?.issueType);
            const updatedStatuses = workFlowModalTaskData?.linkedissues?.reduce((acc, issue) => {
                acc[issue._id] = issue.status || '';
                return acc;
            }, {});
            setCurrentStatus(workFlowModalTaskData?.status)
            setStoryPointEstimate(workFlowModalTaskData?.storyPointEstimate || 0)
            setNewDescription(workFlowModalTaskData?.description)
            setTestCases(workFlowModalTaskData?.testCases)
            setStatus(workFlowModalTaskData?.status)
            setPriority(foundType || taskPriorityList[0])
            setAssignee(workFlowModalTaskData?.assignee?.length > 0 ? workFlowModalTaskData?.assignee[0] : { employeeName: 'unassigned' });
            // setComments(workFlowModalTaskData?.comments || [])
            setIssueType(foundIssueType || taskTypesList[0])
            setLinkedIssues(workFlowModalTaskData?.linkedissues || []);
            setLinkedIssueStatuses(updatedStatuses);
            // setDisplayMode('')

            if (token) {
                try {
                    const decoded = jwtDecode(token);
                    setUserDetails(decoded);
                } catch (error) {
                    console.error('Failed to decode token:', error);
                }
            }

        }
    }, [workFlowModalTaskData]);

    useEffect(() => {
        if (showSearchField && multiSelectRef.current) {
            multiSelectRef.current.focus();
        }
    }, [showSearchField]);

    return (
        <div>
            <Modal open={open} hideBackdrop={hideBackdrop} onClose={handleClose} className='py-1 border-none'>

                <ModalBody width={width} maxWidth={'90%'} className='py-3'>
                    <Box className='ticket-id-share-icons'>
                        <Box className='d-flex justify-content-between align-items-start '>
                            <Box className='d-flex align-items-center'>
                                {issueType.icon}
                                <Typography id="modal-modal-title" variant="h5" sx={{ fontSize: '15px', color: '', marginLeft: '7px' }}>
                                    {workFlowModalTaskData?.name}
                                </Typography>
                            </Box>

                            <Box>
                                <IconButton >
                                    <LockOpenIcon />
                                </IconButton>
                                <IconButton aria-label="upload picture" component="label" className='p-1 mx-1'>
                                    <VisibilityOutlinedIcon /><span className='ms-1 ' style={{ fontSize: '13px' }}>1</span>
                                </IconButton>
                                <IconButton >
                                    <ThumbUpOffAltIcon />
                                </IconButton>
                                <IconButton aria-label="upload picture" component="label" className='p-1 mx-1'>
                                    <ShareIcon />
                                </IconButton>
                                <IconButton aria-label="upload picture" component="label" className='p-1 mx-1'>
                                    <MoreHorizIcon />
                                </IconButton>
                                <IconButton aria-label="upload picture" component="label" onClick={handleCloseModel} className='p-1 mx-1'>
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                        </Box>
                        <hr />
                    </Box>
                    <Grid container spacing={1} >
                        <Grid item md={8} sm={12} className='border-right' >
                            <Box className='task_Work_Update_textfield' >
                                {!isEditing ? (
                                    <Typography
                                        onClick={handleEdit}
                                        onMouseEnter={handleHover}
                                        onMouseLeave={() => setIsHovered(false)}
                                        className='task_Work_Update_editable-text-text'
                                        style={{
                                            border: isHovered ? '1px solid #000' : '1px solid transparent',
                                            backgroundColor: isHovered ? 'lightgray' : 'transparent',
                                            // width: isHovered ? '62%' : '',
                                            borderRadius: isHovered ? '3px' : '',
                                        }}
                                    >
                                        {newSummary ? newSummary : workFlowModalTaskData?.summary}
                                    </Typography>
                                ) : (
                                    <TextField
                                        inputRef={textInputRef}
                                        className='task_Work_Update_editable-text'
                                        value={newSummary ? newSummary : workFlowModalTaskData.summary}
                                        onChange={handleChange}
                                        onKeyDown={handleKeyDown}
                                        onBlur={handleBlurSummery}
                                        autoFocus
                                    />
                                )}
                            </Box>
                            <Box sx={{ overflow: 'auto', maxHeight: '450px' }} className='me-3 pe-3' >
                                <Typography className='' sx={{ fontSize: 26, fontWeight: '600' }} >{workFlowModalTaskData?.title}</Typography>
                                <Box className='my-2'>
                                    <Button sx={btnGroupBtnStyles} className='py-1 me-1 task_Work_main-cls-button' style={{ pointerEvents: 'none' }}><AttachFileIcon className='me-1' /> Attach</Button>
                                    <Button sx={btnGroupBtnStyles} className='mx-1 py-1 task_Work_main-cls-button' style={{ pointerEvents: 'none' }}><LanIcon className='me-1' /> Add a child issue</Button>
                                    <Button sx={btnGroupBtnStyles} className='task_Work_main-cls-button mx-1 py-1' onClick={() => {
                                        setShowSearchField(true)
                                        setShowInputLink(false)
                                    }} endIcon={<ExpandMoreIcon />}><LinkIcon className='me-1' /> Link Issue</Button>
                                    <Button sx={btnGroupBtnStyles} className='mx-1 py-1 task_Work_main-cls-button' style={{ pointerEvents: 'none' }} endIcon={<ExpandMoreIcon className='ExpandMoreIcon' />}> Create</Button>
                                    <Button sx={btnGroupBtnStyles} className='mx-1 py-1 task_Work_main-cls-button' onClick={() => { setIsEditingTestCases(true) }}><MenuIcon className='me-1' /> Test Cases</Button>
                                </Box>
                                <Typography className='mb-2' sx={{ fontWeight: 600, mt: 3 }}>Description</Typography>
                                {!newDescription && !isEditingDesc && (
                                    <TextField
                                        onClick={handleBoxClick}
                                        onMouseEnter={handleDecHover}
                                        onMouseLeave={() => setIsDecHovered(false)}
                                        variant="outlined"
                                        placeholder="Add a Description"
                                        style={{
                                            backgroundColor: isDecHovered ? 'lightgray' : 'transparent',
                                        }}
                                        className="textfield_Description"
                                        onFocus={() => setIsEditingDesc(true)}
                                    />
                                )}
                                {newDescription && !isEditingDesc && (
                                    <Box
                                        onClick={handleBoxClick}
                                        className="editable-box"
                                        onMouseEnter={handleDecHover}
                                        onMouseLeave={() => setIsDecHovered(false)}
                                        style={{
                                            backgroundColor: isDecHovered ? 'lightgray' : 'transparent',
                                            // width: '90%',
                                            height: '30%',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            objectFit: 'contain',
                                            padding: '8px',

                                        }}
                                        dangerouslySetInnerHTML={{ __html: applyImageStyles(newDescription) }}

                                    />
                                )}

                                {isEditingDesc && (
                                    <Box className="mb-4">
                                        <CKTextEditor
                                            payloadText={newDescription}
                                            onChange={handleDescriptionChange}
                                        />
                                        <Button
                                            size="small"
                                            variant="contained"
                                            className="task_Work-button_save text-white mt-3"
                                            onClick={handleUpdateDec}
                                            disabled={disabledBtn}
                                        >
                                            {disabledBtn ? 'Saving...' : 'Save'}
                                        </Button>
                                        <Button
                                            size="small"
                                            variant="outlined"
                                            color="error"
                                            className="task_Work-button_cancel mt-3 ms-3"
                                            onClick={handleCloseEditor}
                                        >
                                            Cancel
                                        </Button>
                                    </Box>
                                )}
                                {linkedIssues?.length > 0 && (
                                    <Box sx={{ mb: 3 }}>
                                        <Typography sx={{ fontWeight: 600, mt: 1 }}>Linked Issues:</Typography>
                                        <ul style={{ listStyleType: 'none', padding: 4, margin: 0 }}>
                                            {linkedIssues?.map((issue, index) => (
                                                <li key={index} className='mb-2'>
                                                    <div className="issue-card" >
                                                        <div className='Linkissue-main' onClick={() => handleLinkeModel(issue)}>
                                                            <FormControlLabel
                                                                control={<Checkbox checked className="Linkedissue-checkbox" />}
                                                                label={
                                                                    <>
                                                                        <span className="Linkissue-name">{capitalizeFirstLetter(issue?.name)}</span>
                                                                        {" - "}
                                                                        {/* <span className="Linkissue-summary text-capitalize">{issue.summary}</span> */}
                                                                        <span className="Linkissue-summary">{capitalizeFirstLetter(issue?.summary)}
                                                                        </span>
                                                                    </>
                                                                }
                                                            />
                                                        </div>
                                                        <div className='d-flex align-items-center me-2'>
                                                            <Box>
                                                                <Tooltip title={issue?.priority || "Urgent"}>
                                                                    <Typography>{getPriorityIcon(issue?.priority)}</Typography>
                                                                </Tooltip>
                                                            </Box>

                                                            <Button
                                                                aria-describedby={id}
                                                                onClick={(event) => handlePopOverClick(event, issue?._id)}
                                                                className="assingeeBtnDropdownBacklog_main"
                                                            >
                                                                <BackgroundLetterAvatarsLogo
                                                                    LogoTitle={
                                                                        issue?.assignee
                                                                            ? issue?.assignee?.length > 0 ? issue?.assignee[0]?.employeeName : ''
                                                                            : issue?.status
                                                                    }
                                                                    RequiredFontSize="10px"
                                                                />
                                                            </Button>
                                                            {/* <Popover
                                                                id={id}
                                                                open={openPopOver}
                                                                anchorEl={anchorEl}
                                                                onClose={handlePopOverClose}
                                                                anchorOrigin={{
                                                                    vertical: "bottom",
                                                                    horizontal: "left",
                                                                }}
                                                                PaperProps={{
                                                                    sx: {
                                                                        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                                                                        borderRadius: 0,
                                                                        padding: 1,
                                                                        width: 220,
                                                                    },
                                                                }}
                                                            >
                                                                <Box>
                                                                    <SearchableDropdown
                                                                        workFlowModalTaskData={workFlowModalTaskData}
                                                                        members={contributors}
                                                                        handleAssigneeUpdate={handleLinkedIssueAssigneeUpdate}
                                                                    />
                                                                </Box>
                                                            </Popover> */}
                                                            <Box >
                                                                <SelectMenuItems
                                                                    title={linkedIssueStatuses?.[issue._id] || ''}
                                                                    showHeader={false}
                                                                    backGroundColor='#E3E4E9'
                                                                    // ItemsList={MenuItemsList}
                                                                    RequiredFontSize='5px'
                                                                    className='task_Work_main-cls-button'
                                                                    handleStatuUpdate={(newStatus) => handleLinkedissueStatusChange(issue._id, newStatus)}
                                                                />
                                                            </Box>
                                                            <Box>
                                                                <Button size='small' className='unlinked'
                                                                    onClick={() => handleDeleteLinkedClick(issue)}
                                                                ><CloseIcon /></Button>
                                                            </Box>
                                                        </div>
                                                    </div>
                                                </li>
                                            ))}

                                        </ul>

                                    </Box>
                                )}
                                {showSearchField && (
                                    <Box sx={{ mt: 1, mr: 6 }}>
                                        {!showInputLink && showSearchField && (
                                            <MultiSelect options={filteredIssues}
                                                ref={multiSelectRef}
                                                onChange={handleIssuesChange} value={selectedIssues} />
                                        )}
                                        {showInputLink && (
                                            <TextField
                                                placeholder='create tast to Link'
                                                sx={{
                                                    m: 1,
                                                    width: '100%',
                                                    "& .MuiInputBase-root": {
                                                        padding: "4px 4px",
                                                    },
                                                }}
                                                onChange={handleChangeCreatLink}
                                                size="small"
                                            />
                                        )}
                                        <Box className="mt-2 d-flex justify-content-between" >
                                            <Box>
                                                <Button
                                                    sx={{
                                                        backgroundColor: `transparent !important`,
                                                        border: `1.5px solid ${colors.primary} !important`,
                                                        color: `${colors.primary} !important`,
                                                        borderRadius: "5px  !important",
                                                        // fontSize: "10px !important",
                                                        textTransform: "capitalize",
                                                        marginLeft: 1
                                                    }}
                                                    className="px-2"
                                                    onClick={handleCreateLink}
                                                >
                                                    Create and link</Button>
                                            </Box>
                                            {!showInputLink && (
                                                <Box className="" sx={{ display: "flex", gap: 1 }}>
                                                    <Button
                                                        sx={{
                                                            backgroundColor: `${colors.primary} !important`,
                                                            border: "0px !important",
                                                            color: "#FFFFFF !important",
                                                            borderRadius: "5px  !important",
                                                            // fontSize: "10px !important",
                                                            textTransform: "capitalize",
                                                        }}
                                                        className="px-3"
                                                        type="button"
                                                        disabled={selectedIssues.length === 0 || linkBtn}
                                                        onClick={handleLinkClick}
                                                    >
                                                        {linkBtn ? 'Linking...' : 'Link'}
                                                    </Button>
                                                    <Button
                                                        sx={{
                                                            backgroundColor: `transparent !important`,
                                                            border: `1.5px solid ${colors.primary} !important`,
                                                            color: `${colors.primary} !important`,
                                                            borderRadius: "5px  !important",
                                                            // fontSize: "10px !important",
                                                            textTransform: "capitalize",
                                                        }}
                                                        className="px-3"
                                                        type="submit"
                                                        onClick={() => {
                                                            setShowSearchField(false);
                                                            setSelectedIssues([]);
                                                        }}
                                                    >
                                                        Cancel
                                                    </Button>
                                                </Box>
                                            )}
                                            {showInputLink && (
                                                <Box sx={{ display: "flex", gap: 1 }} className="mb-3">
                                                    <Button
                                                        sx={{
                                                            backgroundColor: `${colors.primary} !important`,
                                                            border: "0px !important",
                                                            color: "#FFFFFF !important",
                                                            borderRadius: "5px  !important",
                                                            fontSize: "10px !important",
                                                            textTransform: "capitalize",
                                                        }}
                                                        className="px-3"
                                                        type="button"
                                                        onClick={handleSaveLink}
                                                        disabled={savelink}
                                                    >
                                                        {savelink ? "Saving..." : "Save"}
                                                    </Button>
                                                    <Button
                                                        sx={{
                                                            backgroundColor: `transparent !important`,
                                                            border: `1.5px solid ${colors.primary} !important`,
                                                            color: `${colors.primary} !important`,
                                                            borderRadius: "5px  !important",
                                                            fontSize: "10px !important",
                                                            textTransform: "capitalize",
                                                        }}
                                                        className="px-3"
                                                        type="submit"
                                                        onClick={() => {
                                                            setShowSearchField(false);
                                                            setSelectedIssues([]);
                                                        }}
                                                    >
                                                        Cancel
                                                    </Button>
                                                </Box>
                                            )}
                                        </Box>
                                    </Box>
                                )}
                                {(isEditingTestCases || testCases) ? <IssueTestCases
                                    testCases={testCases}
                                    isEditingTestCases={isEditingTestCases}
                                    handleBoxClick={handleBoxClick}
                                    handleTestCasesHover={handleTestCasesHover}
                                    setIsTestCasesHovered={setIsTestCasesHovered}
                                    setIsEditingTestCases={setIsEditingTestCases}
                                    isTestCasesHovered={isTestCasesHovered}
                                    applyImageStyles={applyImageStyles}
                                    handleTestCasesChange={handleTestCasesChange}
                                    handleUpdateTestCases={handleUpdateTestCases}
                                    disabledBtn={disabledBtn}
                                    handleCloseEditor={handleCloseEditor}
                                /> : null}
                                <Typography sx={{ fontWeight: 600, mt: 5, me: 1 }}>Activity</Typography>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography >Shows :</Typography>
                                    <Box className='my-2 ms-2'>
                                        <Button
                                            className={`btnCommentGroup-smallBtn mx-2 ${displayMode === 'comments' ? 'active-button' : ''}`}
                                            onClick={() => handleButtonClick('comments')}> View Comments</Button>
                                        <Button
                                            className={`btnCommentGroup-smallBtn mx-2 ${displayMode === 'history' ? 'active-button' : ''}`}
                                            onClick={() => handleButtonClick('history')}>View History</Button>
                                    </Box>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, mb: 2 }}>
                                    <BackgroundLetterAvatarsLogo
                                        LogoTitle={userDetails.name}
                                    />
                                    <Box sx={{ flex: 1, marginLeft: 2 }}>
                                        {isCommentEditing ? (
                                            <CKTextEditor
                                                payloadText={textComment}
                                                onChange={handleCommentEditorChange}
                                            />
                                        ) : (
                                            <TextField
                                                fullWidth
                                                ref={textCommentFieldRef}
                                                value={textComment}
                                                onClick={handleCommentFieldClick}
                                                onChange={(e) => setTextComment(e.target.value)}
                                                variant="outlined"
                                                placeholder="Add a Comment..."
                                                sx={{
                                                    '& .MuiOutlinedInput-root': {
                                                        '& fieldset': {
                                                            borderColor: 'black',
                                                        },
                                                    },
                                                    '& .MuiInputBase-input': {
                                                        padding: '4px 8px',
                                                        fontSize: '14px',
                                                        minHeight: '35px',
                                                        paddingLeft: '20px'
                                                    },
                                                }}
                                            />
                                        )}
                                    </Box>
                                </Box>
                                {isCommentEditing && (
                                    <Box className='px-5 ms-2'>
                                        <Button variant="contained" className='task_Work-button_save  text-white me-3'
                                            onClick={handleComment}
                                            disabled={addComment}
                                            size="small">
                                            {addComment ? "Submit..." : "Submit"}
                                        </Button>
                                        <Button variant="outlined" color='error' className='task_Work-button_cancel ' onClick={handleClose} size="small">Close</Button>
                                    </Box>
                                )}
                                <Box className='mb-4'>
                                    {displayMode === 'comments' && (
                                        comments?.length > 0 ? (
                                            comments?.map((comment, index) => {
                                                const sortedReplies = Array.from(comment?.replies || []).sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                                                return (
                                                    <Box ref={commentsRef} key={index} sx={{ mt: 2 }}>
                                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                            <Box className='me-3'>
                                                                <BackgroundLetterAvatarsLogo LogoTitle={comment.name} />
                                                            </Box>
                                                            <Box>
                                                                <Typography>
                                                                    <span className='fw-bold me-3'>{comment.name}</span>
                                                                    <Tooltip title={moment(new Date(comment.createdAt)).format("DD MMM YY - HH:mm")}>
                                                                        <span>{moment(comment.createdAt).fromNow()}</span>
                                                                    </Tooltip>
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                        <Box className='ms-5 me-5' sx={{ overflow: 'auto' }}>
                                                            {editingCommentId === comment._id ? (
                                                                <>
                                                                    <CKTextEditor
                                                                        payloadText={editingCommentText}
                                                                        onChange={handleCommentUpdateEditorChange}
                                                                    />
                                                                    <Button
                                                                        size='small'
                                                                        variant="contained"
                                                                        className='task_Work-button_save text-white me-3'
                                                                        onClick={() => handleUpdateComment(comment._id)}

                                                                    >
                                                                        Save
                                                                    </Button>
                                                                    <Button
                                                                        variant="outlined"
                                                                        color='error'
                                                                        className='task_Work-button_cancel'
                                                                        onClick={handleCloseUpdateComment}
                                                                        size="small"
                                                                    >
                                                                        Close
                                                                    </Button>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <Typography onClick={handleClickOpenImage} className='ms-1' dangerouslySetInnerHTML={{ __html: applyImageStyles(comment.comment) }} />
                                                                    {comment?.userId === userDetails.id && (
                                                                        <>
                                                                            <Button
                                                                                size='small'
                                                                                sx={{ color: 'black' }}
                                                                                onClick={() => handleEditComment(comment._id, comment.comment)}
                                                                            >
                                                                                Edit
                                                                            </Button>
                                                                            <Button
                                                                                size='small'
                                                                                sx={{ color: 'black' }}
                                                                                onClick={() => handleOpenDeleteCommentDialog(comment._id)}
                                                                            >
                                                                                Delete
                                                                            </Button>

                                                                        </>
                                                                    )}
                                                                    <Button
                                                                        size='small'
                                                                        sx={{ color: 'black' }}
                                                                        onClick={() => handleReplyClickButton(comment._id)}
                                                                    >
                                                                        Reply
                                                                    </Button>
                                                                </>
                                                            )}
                                                        </Box>

                                                        {replyingToCommentId === comment._id && (<>
                                                            <Box sx={{ alignItems: 'center', width: '89%', mt: 2, mb: 3, marginLeft: 6 }}>
                                                                <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', mt: 2, mb: 2 }}>
                                                                    <BackgroundLetterAvatarsLogo LogoTitle={userDetails.name} />
                                                                    <Box sx={{ flex: 1, marginLeft: 1 }}>
                                                                        {isReplyCommentEditing ? (
                                                                            <CKTextEditor
                                                                                payloadText={replyText}
                                                                                onChange={handleReplyCommentEditorChange}
                                                                            />
                                                                        ) : (
                                                                            <TextField
                                                                                fullWidth
                                                                                value={replyText}
                                                                                onClick={() => setIsReplyCommentEditing(true)}
                                                                                onChange={(e) => setReplyText(e.target.value)}
                                                                                variant="outlined"
                                                                                placeholder={`@${comment.name}`}
                                                                                sx={{
                                                                                    '& .MuiOutlinedInput-root': {
                                                                                        '& fieldset': {
                                                                                            borderColor: 'black',
                                                                                        },
                                                                                    },
                                                                                    '& .MuiInputBase-input': {
                                                                                        padding: '4px 8px',
                                                                                        fontSize: '14px',
                                                                                        minHeight: '35px',
                                                                                        paddingLeft: '20px'
                                                                                    },
                                                                                }}
                                                                            />
                                                                        )}
                                                                    </Box>
                                                                </Box>

                                                                {isReplyCommentEditing && (
                                                                    <Box className='ms-5'>
                                                                        <Button
                                                                            variant="contained"
                                                                            className='task_Work-button_save text-white me-3'
                                                                            onClick={() => handleSubmitReply(comment, workFlowModalTaskData._id)}
                                                                            size="small"
                                                                            disabled={replyLoad}
                                                                        >
                                                                            {replyLoad ? "Reply..." : "Reply"}
                                                                        </Button>
                                                                        <Button
                                                                            variant="outlined"
                                                                            color='error'
                                                                            className='task_Work-button_cancel'
                                                                            onClick={handleCloseReply}
                                                                            size="small"
                                                                        >
                                                                            Close
                                                                        </Button>
                                                                    </Box>
                                                                )}

                                                            </Box>

                                                        </>)}
                                                        {sortedReplies?.length > 0 && (
                                                            <Box sx={{ marginLeft: 6 }}>
                                                                {sortedReplies?.map((reply) => (
                                                                    <Box key={reply._id} sx={{ alignItems: 'center', width: '70%', mt: 2, mb: 3 }}>
                                                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                            <Box className='me-3'>
                                                                                <BackgroundLetterAvatarsLogo LogoTitle={reply.name} />
                                                                            </Box>
                                                                            <Box>
                                                                                <Typography>
                                                                                    <span className='fw-bold me-3'>{reply.name}</span>
                                                                                    <Tooltip title={moment(new Date(reply.createdAt)).format("DD MMM YY - HH:mm")}>
                                                                                        <span>{moment(reply.createdAt).fromNow()}</span>
                                                                                    </Tooltip>
                                                                                </Typography>
                                                                            </Box>
                                                                        </Box>
                                                                        {/* <Box sx={{ marginLeft: 6 }}>
                                                                            <Typography className='ms-1' dangerouslySetInnerHTML={{ __html: applyImageStyles(reply.comment) }} />
                                                                            <Button><EditIcon/></Button>
                                                                            <Button><DeleteIcon/></Button>
                                                                        </Box> */}
                                                                        <Box sx={{ marginLeft: 6 }}>
                                                                            {editingReplyId === reply._id ? (
                                                                                <Box>

                                                                                    <CKTextEditor
                                                                                        payloadText={replyContent}
                                                                                        onChange={handleReplyCommentUpdateEditorChange}
                                                                                    />
                                                                                    <Button onClick={handleUpdateClick}>Update</Button>
                                                                                    <Button onClick={handleCancelEdit}>Cancel</Button>
                                                                                </Box>
                                                                            ) : (
                                                                                <Box>
                                                                                    <Typography onClick={handleClickOpenReplyImage} className='ms-1' dangerouslySetInnerHTML={{ __html: applyImageStyles(reply?.comment) }} />
                                                                                    <Button
                                                                                        size='small'
                                                                                        sx={{ color: 'black' }}
                                                                                        onClick={() => handleEditClick(reply)}>
                                                                                        {/* <EditIcon /> */}Edit
                                                                                    </Button>
                                                                                    <Button
                                                                                        size='small'
                                                                                        sx={{ color: 'black' }}
                                                                                        onClick={() => {
                                                                                            setReplyToDelete(reply._id);
                                                                                            setOpenDeleteReplyDialog(true);
                                                                                        }}>
                                                                                        {/* <DeleteIcon /> */}
                                                                                        Delete
                                                                                    </Button>
                                                                                </Box>
                                                                            )}
                                                                        </Box>
                                                                    </Box>

                                                                ))}
                                                            </Box>
                                                        )
                                                        }

                                                    </Box>
                                                )
                                            })
                                        ) : (
                                            loading ? (
                                                <Box ref={skeletonRef} sx={{ mt: 2 }}>
                                                    {[1, 2].map((_, index) => (
                                                        <Box key={index} sx={{ mt: 2 }}>
                                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                <Skeleton variant="circular" width={40} height={40} />
                                                                <Box sx={{ ml: 2 }}>
                                                                    <Skeleton width="85px" height="30px" />
                                                                </Box>
                                                            </Box>
                                                            <Box sx={{ mt: 1 }}>
                                                                <Skeleton width="95%" height="40px" />
                                                            </Box>
                                                        </Box>
                                                    ))}
                                                </Box>
                                            ) : (
                                                <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 7 }}>
                                                    <Typography className='fw-bold fs-5'>No comments available</Typography>
                                                </Box>
                                            )
                                        )
                                    )}


                                    {displayMode === 'history' && (
                                        <Box className='mb-4'>
                                            {history?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                                                .filter(comment => comment.action !== 'updated description' && comment.action !== 'updated summary')
                                                .map((comment, index) => (
                                                    <Box key={index} sx={{ mt: 2 }}>
                                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                            <Box className='me-3'>
                                                                <BackgroundLetterAvatarsLogo LogoTitle={comment?.name} />
                                                            </Box>
                                                            <Box>
                                                                <Typography>
                                                                    <span className='fw-bold me-2'>{comment.name}</span>
                                                                    <span className='me-3'>
                                                                        {comment.action}
                                                                    </span>
                                                                    <Tooltip title={moment(new Date(comment.createdAt)).format("DD MMM YY - HH:mm")}>
                                                                        <span>{moment(comment.createdAt).fromNow()}</span>
                                                                    </Tooltip>
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                        <Box className='ms-5 mt-2 d-flex'>
                                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                <Box>
                                                                    {comment.action === 'updated assignee' ? (
                                                                        <BackgroundLetterAvatarsLogo LogoTitle={comment.previous} />
                                                                    ) : comment.action === 'updated issueType' ? (
                                                                        <Box>
                                                                            {getTaskTypeIcon(comment.previous)}
                                                                        </Box>
                                                                    ) : comment.action === 'updated priority' ? (
                                                                        <Box>
                                                                            {getTaskPriorityIcon(comment.previous)}
                                                                        </Box>
                                                                    ) : ''}
                                                                </Box>
                                                                <Box>
                                                                    <Button className={`history_btn ${comment.action === 'updated status' ? 'bold' : ''}
                                                                    ${comment.previous === 'Done' ? 'done' : comment.previous === 'In Progress' ? 'inProgress' : ''}`}>

                                                                        {comment.action.includes('created') ? '' : comment.previous === '' ? 'Unassigned' : comment.previous}
                                                                    </Button>
                                                                    {comment.action.includes('created') ? '' : <ArrowRightAltIcon fontSize='20px' />}
                                                                </Box>
                                                            </Box>
                                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                <Box className='ms-2'>
                                                                    {comment.action === 'updated assignee' ? (
                                                                        <BackgroundLetterAvatarsLogo LogoTitle={comment.latest} />
                                                                    ) : comment.action === 'updated issueType' ? (
                                                                        <Box>
                                                                            {getTaskTypeIcon(comment.latest)}
                                                                        </Box>
                                                                    ) : comment.action === 'updated priority' ? (
                                                                        <Box>
                                                                            {getTaskPriorityIcon(comment.latest)}
                                                                        </Box>
                                                                    ) : ''}
                                                                </Box>
                                                                <Box>
                                                                    <Button className={`history_btn ${comment.action === 'updated status' ? 'bold' : ''}
                                                                     ${comment.latest === 'Done' ? 'done' :
                                                                            comment.latest === 'In Progress' ? 'inProgress' : ''
                                                                        }
                                                                    `} >

                                                                        {comment.action.includes('created') ? '' : comment.latest === '' ? 'Unassigned' : comment.latest}

                                                                    </Button>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                ))}
                                        </Box>
                                    )}

                                    < Modal
                                        open={!!selectedImage}
                                        onClose={handleCloseImage}
                                        aria-labelledby="image-modal"
                                        aria-describedby="image-modal-description"
                                    >
                                        <Box
                                            className='imageView-modal'
                                        >
                                            <IconButton
                                                onClick={handleCloseImage}
                                                className="modal-close-btn"
                                            >
                                                <CloseIcon />
                                            </IconButton>
                                            <img
                                                src={selectedImage}
                                                alt="selectedImage"
                                                style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                                            />
                                        </Box>
                                    </Modal>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item md={4} sm={12}>
                            <Box>
                                <Box className=' d-flex align-items-center'>
                                    <SelectMenuItems
                                        title={currentStatus}
                                        showHeader={false}
                                        backGroundColor='#E3E4E9'
                                        ItemsList={issueStatus}
                                        RequiredFontSize='10px'
                                        className='task_Work_main-cls-button my-3 '
                                        handleStatuUpdate={handleStatusChange}
                                    />

                                    <IssueTypeMenuList
                                        labelText={issueType.name}
                                        ItemsList={taskTypesList}
                                        setSelectedValue={handleIssueTypeChange}
                                        selectedValue={issueType}
                                        className='task_Work_main-cls-button '
                                        isbtnTextActive={true}
                                    />
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Box>
                                        <Accordion className='customAccordion' defaultExpanded>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                                className="accordion-summary"
                                            >
                                                <Typography>Details </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails className='accordion-details' sx={{ maxHeight: '330px', overflowY: 'auto' }}>

                                                <Box>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={6}>
                                                            <Typography className="task_Work_typography-small mt-3">Assignee</Typography>
                                                        </Grid>

                                                        <Grid item xs={6}>
                                                            <SearchableDropdown
                                                                workFlowModalTaskData={workFlowModalTaskData}
                                                                members={contributors}
                                                                handleAssigneeUpdate={handleAssigneeUpdate}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6} className="mt-3">
                                                            <Typography className="task_Work_typography-small">Label</Typography>
                                                        </Grid>
                                                        <Grid item xs={6} className="mt-2">
                                                            <LabelsDropdown
                                                                workFlowModalTaskData={workFlowModalTaskData}
                                                                labels={projectDetails?.labels}
                                                                onLabelChange={handleLabelChange}
                                                                className='task_work_Label d-flex justify-content-between'
                                                                projectDetails={projectDetails}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6} className="mt-3">
                                                            <Typography className="task_Work_typography-small">Sprint</Typography>
                                                        </Grid>
                                                        <Grid item xs={6} className="mt-2">
                                                            <Box className="badge rounded-pill bg-success ps-3">
                                                                {activeSprintName}
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={6} className="mt-3">
                                                            <Typography colorPrimary className="task_Work_typography-small">Story point estimate</Typography>
                                                        </Grid>
                                                        <Grid item xs={6} className="mt-2 ps-3">

                                                            {isEditingStoryPoint ? (
                                                                <TextField
                                                                    inputRef={textInputRefstoryPoint}
                                                                    value={storyPointEstimate}
                                                                    onChange={handlestoryPointEstimateChange}
                                                                    onKeyPress={handleKeyPress}
                                                                    onBlur={handleBlur}
                                                                    placeholder="Enter estimate"
                                                                    variant="outlined"
                                                                    size="small"
                                                                    fullWidth
                                                                    autoFocus
                                                                />
                                                            ) : (
                                                                <Typography
                                                                    onMouseEnter={handleHoverEstimate}
                                                                    onMouseLeave={() => setIsHoveredEstimate(false)}
                                                                    style={{
                                                                        border: isHoveredEstimate ? '1px solid #000' : '1px solid transparent',
                                                                        backgroundColor: isHoveredEstimate ? '#f5f5f5' : 'transparent'
                                                                    }}
                                                                    sx={{ backgroundColor: 'lightgray', borderRadius: '5px', p: 1, color: 'green' }} className="task_Work_typography-small " onClick={handleEditEstimate}>
                                                                    {storyPointEstimate}
                                                                </Typography>
                                                            )}
                                                        </Grid>
                                                        <Grid item xs={6} className="mt-4">
                                                            <Typography className="task_Work_typography-small">Priority</Typography>
                                                        </Grid>
                                                        <Grid item xs={6} className="mt-2 ps-3">
                                                            <IssueTypeMenuList
                                                                labelText={priority.name}
                                                                ItemsList={taskPriorityList}
                                                                setSelectedValue={handlePriorityChange}
                                                                selectedValue={priority}
                                                                className='task_Work_main-cls-button '
                                                                isbtnTextActive={true}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6} className="mt-2">
                                                            <Typography className="task_Work_typography-small">Reporter</Typography>
                                                        </Grid>
                                                        <Grid item xs={6} className="mt-2  ps-3">
                                                            <Box className="d-flex align-items-center gap-2">
                                                                <Box>
                                                                    <Avatar className="task_Work_avatar-small" />
                                                                </Box>
                                                                <Box>
                                                                    <Typography className="task_Work_typography-small">Subrahmanyam</Typography>
                                                                </Box>
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                </Box>

                                            </AccordionDetails>
                                        </Accordion>
                                    </Box>
                                </Box>


                                <Box className='d-flex justify-content-between mt-3'>
                                    <Box>
                                        <Typography className="task_Work_fontsize">created at - {moment(new Date(workFlowModalTaskData?.createdAt)).format("DD MMM HH:mm")} </Typography>
                                        <Typography className="task_Work_fontsize">updated at - {moment(new Date(workFlowModalTaskData?.updatedAt)).format("DD MMM HH:mm")}</Typography>
                                    </Box>
                                    <Box className='d-flex align-items-center'>
                                        <Button onClick={() => setOpenDeleteDialog(true)} variant="outlined" color="error" className='fw-bold'>Delete</Button>
                                    </Box>

                                </Box>

                            </Box>
                        </Grid>
                    </Grid>
                    <DeleteConfirmationModal
                        open={openDeleteDialog}
                        handleClose={() => setOpenDeleteDialog(false)}
                        handleConfirm={handleDeleteIssue}
                        btnText="Yes"
                        title="Are you sure you want to delete this issue?"
                        description=" Deleting this issue will remove it permanently. Are you sure you want to proceed?"
                        buttonStyles={buttonStyles}
                    />
                    <DeleteConfirmationModal
                        open={openDeleteCommentDialog}
                        handleClose={() => setOpenDeleteCommentDialog(false)}
                        handleConfirm={handleDeleteComment}
                        btnText="Yes"
                        title="Are you sure you want to delete this comment?"
                        description=" Deleting this issue will remove it permanently. Are you sure you want to proceed?"
                        buttonStyles={buttonStyles}
                    />
                    <DeleteConfirmationModal
                        open={openDeleteReplyDialog}
                        handleClose={() => setOpenDeleteReplyDialog(false)}
                        handleConfirm={() => handleDeleteCommentReply(replyToDelete)}
                        btnText="Yes"
                        title="Are you sure you want to delete this reply?"
                        description="Deleting this reply will remove it permanently. Are you sure you want to proceed?"
                        buttonStyles={buttonStyles}
                    />
                    <DeleteConfirmationModal
                        open={openDeleteLinkedissueModal}
                        handleClose={() => setOpenDeleteLinkedissueModal(false)}
                        handleConfirm={handleDeleteLinkedIssue}
                        btnText="Yes"
                        title="Are you sure you want to Unlinked this linked issue?"
                        description="Unlinked this linked issue will Unlinked it permanently. Are you sure you want to proceed?"
                        buttonStyles={buttonStyles}
                    />
                </ModalBody>

            </Modal >
        </div >
    )
}

export default ViewWorkFlowModalPage
