import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./style.css";
import axios from "axios";
import API_BASE_URL from "../../components/config/appConfig";
import SprintsComaprisonLineGraph from "../../Reports/LineGraph";
import CustomPieChart from "../../Reports/PieChart";

const ViewReports = () => {
  const location = useLocation();
  const { projectIds } = location.state || {};

  const [expanded, setExpanded] = React.useState(null);
  const [ProjectsReports, setProjectsReports] = useState([]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };

  useEffect(() => {
    getReports();
  }, [projectIds]);

  //  { category: 'Sprint-1', value: 2 },

  const getReports = async () => {
    try {
      const multipleReports = await axios.post(
        `${API_BASE_URL}/projects/reports`,
        {
          projectIds: projectIds,
        }
      );

      setProjectsReports(multipleReports.data);

    } catch (error) {
      console.log(error);
    }
  };

  const calculateSprintCompletionPercentage = (sprint) => {
    const totalIssues = sprint.totalIssues ?? 0;
    const issuesDone = sprint.IssuesDone ?? 0;
    return totalIssues > 0 ? Math.floor((issuesDone / totalIssues) * 100) : 0;
  };

  return (
    <Box className="view-reports-outerBox">
      {ProjectsReports &&
        ProjectsReports?.map((project) => {
          const sprintsInfo = project.sprintsInfo ?? [];
          const activeSprint = sprintsInfo.find((sprint) => sprint.active);
          let displayCompletionPercentage = 0;

          if (activeSprint) {
            displayCompletionPercentage = calculateSprintCompletionPercentage(activeSprint);
          } else {
            const lastCompletedSprint = sprintsInfo
              .filter((sprint) => !sprint.active && sprint.completed)
              .sort((a, b) => new Date(b.endDate) - new Date(a.endDate))[0];

            if (lastCompletedSprint) {
              displayCompletionPercentage = calculateSprintCompletionPercentage(lastCompletedSprint);
            }
          }
          return (
            <Accordion
              key={project?.ProjectId}
              expanded={expanded === project.ProjectId}
              onChange={handleChange(project.ProjectId)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel-${project?.ProjectId}-content`}
                id={`panel-${project?.ProjectId}-header`}
              >
                <Typography
                  sx={{ width: "20%", flexShrink: 0 }}
                  className="FontSize"
                >
                  {project?.projectName}
                </Typography>
                {expanded !== project.ProjectId && (
                  <Box
                    sx={{ display: 'flex', gap: 8, width: "70%", justifyContent: 'space-around' }}
                  >
                    <Typography className="FontSize" sx={{ color: 'green' }}>Completion: {displayCompletionPercentage}%</Typography>
                    <Typography className="FontSize" sx={{ width: "28%" }}>
                      {(() => {
                        const activeSprints = project?.sprintsInfo?.filter((sprint) => sprint.active);

                        if (activeSprints && activeSprints.length > 0) {
                          return (
                            <Typography className="FontSize">
                              Current Sprint: {activeSprints[0].name}
                            </Typography>
                          );
                        } else {
                          const lastActiveSprint = project?.sprintsInfo
                            ?.filter((sprint) => !sprint.active)
                            .sort((a, b) => new Date(b.endDate) - new Date(a.endDate))
                            .shift();
                          return lastActiveSprint ? (
                            <Typography className="FontSize">
                              Last Sprint: {lastActiveSprint.name}
                            </Typography>
                          ) : (
                            <Typography className="FontSize">
                              No Active Sprint
                            </Typography>
                          );
                        }
                      })()}
                    </Typography>
                    <Typography className="FontSize">Total Employees: {project?.contributors.length}</Typography>
                  </Box>
                )}
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{ display: "flex", justifyContent: "space-between", padding: "10px" }}>
                  <Box>

                    <CustomPieChart completed={project?.sprintsInfo[project.sprintsInfo.length - 1]?.IssuesDone} totalTickets={project?.sprintsInfo[project.sprintsInfo.length - 1]?.totalIssues} />
                    <Typography>
                      {" "}
                      {`Completed Issues - ${project?.sprintsInfo[project.sprintsInfo.length - 1]?.IssuesDone}`}{" "}
                    </Typography>
                    <Typography>
                      {`Total Issues - ${project?.sprintsInfo[project.sprintsInfo.length - 1]?.totalIssues}`}{" "}
                    </Typography>
                    <Typography>{`Sprint : ${project?.sprintsInfo[project.sprintsInfo.length - 1]?.name}`}</Typography>
                  </Box>
                  <Box>
                    <SprintsComaprisonLineGraph graphData={project?.sprintsInfo} />
                  </Box>
                  <Box>
                    <Typography>{`No of contributors - ${project?.contributors.length}`}</Typography>
                  </Box>

                </Box>
              </AccordionDetails>
            </Accordion>
          );
        })}
    </Box>
  );
};

export default ViewReports;
