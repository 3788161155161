import React, { useState, createContext, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { Box } from "@mui/system";
import Header from "../../components/TopBar/index";
import ModalPage from "../../components/Modal/ModalPage";
import './ProjectPages/projectpages.scss'
import { useDispatch, useSelector } from "react-redux";
import { fetchAllProjects } from "../../common/Redux/Reducer";

export const ProjectContext = createContext();

const Index = () => {
  const pages = [
    { text: "Projects", navUrl: "!#" },
    { text: "Teams", navUrl: "!#" }
  ];

  const dispatch = useDispatch();
  const { Projects } = useSelector((state) => state.ProjamAppSlice);
  const allProjectsList = Projects?.allProjectsList
  const [backlogId, setBacklogId] = useState(null);
  const [selectedProjectTitle, setSelectedProjectTitle] = useState("");
  const [projectsList, setProjectsList] = useState([]);

  const filteredProjects = selectedProjectTitle
    ? allProjectsList?.data.filter(project => project.id === selectedProjectTitle)
    : allProjectsList?.data || [];
  const token = localStorage.getItem("accessToken");
  const headers = {
    Authorization: `Bearer ${token}`,
  };
  useEffect(() => {
    dispatch(fetchAllProjects(headers));
  }, []);

  return (
    <Box>
      <Header pages={pages} isUserLoggedIn={true} allProjectsList={filteredProjects} />
      <Box pt={8}>
        <ProjectContext.Provider value={{ projectsList, setProjectsList, backlogId, setBacklogId, selectedProjectTitle, setSelectedProjectTitle }}>
          <Outlet />
          <ModalPage />
        </ProjectContext.Provider>
      </Box>
    </Box>
  );
};

export default Index;
