import React from "react";
import TextField from "@mui/material/TextField";
import { Typography } from "@mui/material";

const BasicTextField = ({ labelText, important = true, onChange, value = '' }) => {
  return (
    <TextField
      onChange={onChange}
      id="outlined-basic"
      label={
        <Typography id="demo-simple-select-label">
          {labelText}
          {important ? <span className="text-danger ms-1">*</span> : null}
        </Typography>
      }
      variant="outlined"
      className="search-bar my-1"
      size="small"
      value={value}
    />
  );
};

export default BasicTextField;
