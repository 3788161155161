import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useSelector } from 'react-redux';

const Loader = (props) => {
    const { loader } = useSelector((state) => state.ProjamAppSlice)
    return (
        <div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 999 }}
                open={loader}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            {props.children}
        </div>
    )
}
export default Loader;