import axios from "axios";
import API_BASE_URL from "../components/config/appConfig";
import { jwtDecode } from "jwt-decode";

export const notificationSubscription = async () => {
  try {
    function urlBase64ToUint8Array(base64String) {
      const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
      // eslint-disable-next-line
      const base64 = (base64String + padding)
        .replace(/\-/g, "+")
        .replace(/_/g, "/");

      const rawData = window.atob(base64);
      const outputArray = new Uint8Array(rawData.length);

      for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
      }

      return outputArray;
    }
    const convertedVapidKey = urlBase64ToUint8Array(
      "BHXE9tFuwkKPnObhc_Kdtv4zAQpDEINjb2zRpneJxl2jIb0Bh2LrEimvUMo7XB9A_Hvfjjv7BD_izV2ZLoz7YA8"
    );
    // console.log(convertedVapidKey ,'convertedVapidKey')

    let registrationOfServiceWorker = await registerServiceWorker();
    // //console.log(registrationOfServiceWorker , 'register servicer-worker');

    let premission = await requestNotificationPermission();
    //console.log(premission ,'premission')

    const saveSubscription = async (subscription = {}) => {
      try {
        const token = localStorage.getItem("accessToken");
        let decoded = "";
        if (token) {
          decoded = jwtDecode(token);
        }

        const subscriptionDetails = await axios.post(
          `${API_BASE_URL}/notification/saveSubscription`,
          {
            subscription,
            employeeEmail: decoded?.email,
          }
        );

      } catch (error) {
        console.log(error);
      }
    };

    if ("serviceWorker" in navigator) {
      try {
        let ServiceWork = await navigator.serviceWorker.ready;
        if (!ServiceWork.pushManager) {
          console.log("pushManager unavailabe");
          return;
        }
        let subscripionCheck = await ServiceWork.pushManager.getSubscription();
      
        if (subscripionCheck) {
          saveSubscription(subscripionCheck);
         return 
        } else {
          let createSubscription = await ServiceWork.pushManager.subscribe({
            applicationServerKey: convertedVapidKey,
            userVisibleOnly: true,
          });
          saveSubscription(createSubscription);
        }
      } catch (error) {}
    } else {
      console.log("service worker not exist");
    }
  } catch (error) {
    console.log(error, "error");
  }
};

// for registering serviceworker  , it will register on time only
const registerServiceWorker = async () => {
  const swRegistration = await navigator.serviceWorker.register(
    "service-worker.js"
  );
  return swRegistration;
};

const requestNotificationPermission = async () => {
  const permission = await window.Notification.requestPermission();
  if (permission !== "granted") {
    throw new Error("Permission not granted for Notification");
  }

  return permission;
};
