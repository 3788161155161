import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MailIcon from "@mui/icons-material/Mail";
import { NavLink, Outlet } from "react-router-dom";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import TerminalIcon from "@mui/icons-material/Terminal";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import SettingsIcon from '@mui/icons-material/Settings';
import FormatAlignJustifyOutlinedIcon from "@mui/icons-material/FormatAlignJustifyOutlined";
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";
import { CiViewColumn } from "react-icons/ci";
import DashboardIcon from '@mui/icons-material/Dashboard';
import { useSelector } from "react-redux";
import { NormalSkeleton } from "../../common/Skeleton";
// import { useParams } from 'react-router-dom';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { IconButton, Tooltip } from "@mui/material";
import { Icon } from "@iconify/react";
import "./sidebar.scss";
const drawerWidth = 240;

const initialSidebarData = {
  Planning: true,
};

export default function ClippedDrawer({ selectedProjectData }) {
  const [open, setOpen] = React.useState(initialSidebarData);
  const [isSidebarOpen, setSidebarOpenStatus] = useState(true)

  const { Skeleton } = useSelector((state) => state.ProjamAppSlice)

  const handleClick = (text) => {
    setOpen({ ...open, [text]: !open[text] });
  };

  let { Projects } = useSelector((state) => state.ProjamAppSlice)
  let projectslug = Projects?.selectedProject?.projectSlug


  const handleSidebarOpenStatus = () => {
    setSidebarOpenStatus(!isSidebarOpen)
  }

  const getListItems = (heading) => {

    if (heading === 'Reports') {
      return [
        {
          title: "DashBoard",
          navUrl: `/projects/${projectslug}/dashboard`,
          icon: <DashboardIcon style={{ fontSize: "22px" }} />,
        },
      ]
    }

    else if (heading === "Planning") {
      return [
        {
          title: "Sprint Board",
          navUrl: `/projects/${projectslug}/active-sprints`,
          icon: <CiViewColumn style={{ fontSize: "22px" }} />,
        },
        {
          title: "Backlog",
          navUrl: `/projects/${projectslug}/backlog`,
          icon: <FormatAlignJustifyOutlinedIcon />,
        },
        {
          title: "List",
          navUrl: `/projects/${projectslug}/issues-list`,
          icon: <FormatListBulletedOutlinedIcon />,
        },
      ];
    }
    return [];
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Drawer
        variant="permanent"
        sx={{
          width: isSidebarOpen ? drawerWidth : 100,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: isSidebarOpen ? drawerWidth : 100,
            boxSizing: "border-box",
            overflow: 'visible',
            transition: isSidebarOpen ? 'all .3s ease' : 'all .5s ease',
          },

        }}
      >
        <Tooltip title={isSidebarOpen ? 'close' : 'open'} placement="right">
          <IconButton className="side-menu-close-and-open-cls" onClick={handleSidebarOpenStatus} sx={{ top: isSidebarOpen ? '83px' : '75px', right: '-14px' }}>
            {/* <MenuOpenIcon /> */}
            <Icon icon={isSidebarOpen ? "fluent-mdl2:page-left" : "fluent-mdl2:page-right"} />
          </IconButton>
        </Tooltip>
        <Toolbar />
        <Box sx={{ overflow: "auto" }}>
          <List >
            <ListItem disablePadding sx={{ cursor: 'none' }}>
              <ListItemButton className="project-title-list-btn-cls">
                <Tooltip title={selectedProjectData?.title} placement="bottom">
                  <ListItemIcon>
                    <TerminalIcon />
                  </ListItemIcon>
                </Tooltip>

                {isSidebarOpen ? <ListItemText
                  primary={selectedProjectData?.title || "Projects-Reports"}
                  secondary={selectedProjectData?.type || ""}
                  primaryTypographyProps={{
                    style: {
                      fontSize: "16px",
                      fontWeight: "bold",
                    },
                  }}
                  secondaryTypographyProps={{
                    className: 'secondaryTypography'
                  }}

                /> : null}
              </ListItemButton>
            </ListItem>
          </List>

          <Divider />
          {["Reports",
            "Planning",
            // "Development"
          ].map((heading, index) => (
            <List key={heading} className="mt-0 pt-0">
              <ListItem
                disablePadding
                className="d-flex flex-column align-items-start"
                sx={{ width: "100%" }}
              >

                <ListItemButton
                  onClick={() => handleClick(heading)}
                  sx={{ width: "100%" }}
                >
                  <Tooltip title={heading} placement="bottom">
                    <ListItemIcon>
                      {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                    </ListItemIcon>
                  </Tooltip>
                  {isSidebarOpen ? <ListItemText primary={heading} /> : null}
                  {open[heading] ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse
                  in={open[heading]}
                  timeout="auto"
                  unmountOnExit
                  sx={{ width: "100%" }}
                >
                  <List component="div" disablePadding>
                    {getListItems(heading).map((eachItem, idx) => (
                      !Skeleton ? <NavLink key={eachItem.title} to={eachItem.navUrl}>
                        <ListItem disablePadding>

                          <ListItemButton sx={{ pl: isSidebarOpen ? 4 : 4 }}>
                            <Tooltip title={eachItem?.title} placement="bottom"> <ListItemIcon>{eachItem.icon}</ListItemIcon></Tooltip>
                            {isSidebarOpen ? <ListItemText primary={eachItem.title} /> : null}
                          </ListItemButton>

                        </ListItem>
                      </NavLink> : <div key={eachItem.title} className="my-2 px-3"><NormalSkeleton NormalSkeleton variant='rectangular' height={30} width="100%" /></div>
                    ))}
                  </List>
                </Collapse>
              </ListItem>
            </List>
          ))}
          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              width: "100%",
            }}
          >
            <Divider />
            <List className="mt-0 pt-0" sx={{ overflow: isSidebarOpen ? "auto" : 'hidden' }}>
              <ListItem disablePadding sx={{ width: "100%" }}>
                <NavLink
                  to={`/projects/${projectslug}/settings`}
                  style={({ isActive }) => ({
                    width: "100%",
                    textDecoration: 'none',
                    color: 'black',
                    backgroundColor: isActive ? '#F0F7EB' : 'inherit', // Keep active style if necessary
                    borderRight: isActive ? '5px solid #60A630' : 'none',
                  })}
                >
                  <ListItemButton sx={{ width: "100%" }} className="d-flex justify-content-center">
                    <Tooltip title={'Project Settings'} placement="top"><ListItemIcon>
                      <SettingsIcon style={{ fontSize: "22px" }} />
                    </ListItemIcon></Tooltip>
                    {isSidebarOpen ? <ListItemText primary="Project Settings" /> : null}
                  </ListItemButton>
                </NavLink>
              </ListItem>
            </List>

          </Box>
        </Box>

      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 1, overflow: "auto", maxWidth: '100%' }}>
        <Outlet />
      </Box>
    </Box>
  );
}
