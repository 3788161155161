import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import IconButton from "@mui/material/IconButton";
import AppTitleLogo from '../../assets/images/footer-logo.png'
import styled from 'styled-components';
import { RiTwitterXLine } from "react-icons/ri";
import { FiFacebook } from "react-icons/fi";
import { FiYoutube } from "react-icons/fi";
import { PiLinkedinLogo } from "react-icons/pi";
import footerLinksData from './footer.json';

const { Product_links, RESOURCES_links, EXPAND_And_LEARN_links, ABOUT_ATLASSIAN } = footerLinksData;

const LogoText = styled(Box)` 
font-weight: 700;
letter-spacing: 1px; 
color:#010101;
`

const LogoSubText = styled(Typography)`
font-size: 16px;
font-weight: 400;
line-height: 26px;
color:#fff;
`
const FooterTitles = styled(Typography)`
font-size: 20px !important;
font-weight: 600 !important;
line-height: 30px !important;
color:#fff !important;
`
const FooterLinks = styled(Link)`
font-size: 16px !important;
font-weight: 400 !important;
line-height: 26px !important;
color:#fff !important;
display:inline-block;
margin-top:5px !important;
margin-bottom:5px !important;
`
const IconButtons = styled(IconButton)`
border:1px solid #f9fafb !important;
margin-right:5px !important;
background-color: #f6f7f9 !important;
`
const Footer = () => {
    return (
        <Box
            sx={{
                backgroundColor: "#0A2640",
                width: "100%",
                color: "#fff",
                paddingTop: { xs: 2, sm: 5 },
                paddingX: { xs: 1, sm: 2 },
            }}
        >
            <Box sx={{ paddingX: { xs: 1, sm: 2 } }}>
                <Grid container spacing={2} justifyContent="space-evenly" alignItems="flex-start">
                    <Grid item xs={12} sm={6} md={4}>
                        <div style={{ width: '80%' }}>
                            <Typography
                                noWrap
                                component="a"
                                href="/"
                                sx={{
                                    textDecoration: 'none',
                                }}
                                className="d-flex align-items-center"
                            >
                                <img src={AppTitleLogo} alt='App-Title-Logo' height={50} className='me-1' />
                                <LogoText fontSize={'20px !important'} ></LogoText>
                            </Typography>
                            <LogoSubText className="pe-md-5 mt-3">
                                Streamline Your Projects, Empower Your Teams
                            </LogoSubText>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                        <FooterTitles mb={2} variant="h6">
                            Product
                        </FooterTitles>
                        {Product_links.map((eachLink) => <>
                            <FooterLinks href="#" color="inherit" underline="none" key={eachLink}>
                                {eachLink}
                            </FooterLinks>
                            <br />
                        </>)}
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                        <FooterTitles mb={2} variant="h6">
                            Company
                        </FooterTitles>
                        {RESOURCES_links.map((eachLink) => <>
                            <FooterLinks href="#" color="inherit" underline="none" key={eachLink}>
                                {eachLink}
                            </FooterLinks>
                            <br />
                        </>)}

                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                        <FooterTitles mb={2} variant="h6">
                            Follow us
                        </FooterTitles>
                        <div>
                            <IconButtons href="#" color="inherit" >
                                <RiTwitterXLine style={{ width: '19px', height: '19px' }} />
                            </IconButtons>
                            <IconButtons href="#" color="inherit">
                                <FiFacebook style={{ width: '19px', height: '19px' }} />
                            </IconButtons>
                            <IconButtons href="#" color="inherit">
                                <FiYoutube style={{ width: '19px', height: '19px' }} />
                            </IconButtons>
                            <IconButtons href="#" color="inherit">
                                <PiLinkedinLogo style={{ width: '19px', height: '19px' }} />
                            </IconButtons>
                        </div>
                        {EXPAND_And_LEARN_links.map((eachLink) => <>
                            <FooterLinks href="#" color="inherit" underline="none" key={eachLink}>
                                {eachLink}
                            </FooterLinks>
                            <br />
                        </>)}

                    </Grid>

                </Grid>
            </Box>
            <div className="d-flex justify-content-center">
                <Box
                    sx={{
                        borderTop: 1,
                        borderColor: "#DEE1E6",
                        paddingY: { xs: 2, sm: 3 },
                        width: '91%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                    className='mt-4'
                >
                    <p className="m-0">
                        © 2024 Copyright:
                        {/* <b> */}
                        <img src={AppTitleLogo} alt='App-Title-Logo' height={22} className='mx-1' />
                        {/* Projam Software */}
                        {/* </b> */}
                    </p>
                    <ul className="d-flex ps-2 m-0 footer-bottom-links" style={{ paddingLeft: '1rem' }}>
                        <li className="me-1 "><a href="#privacy">Privacy</a></li>
                        <li className="me-1" ><a href="#terms">Terms</a></li>
                        <li className="me-0" ><a href="#sitemap">Sitemap</a></li>
                    </ul>
                </Box>
            </div>

        </Box>
    );
};

export default Footer;
