import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
// import { Typography } from "@mui/material";

export default function SelectMenuTextField({
  labelText = "Select",
  disable = false,
  minimumWidth = "150px",
  options = [],
  onChange = () => { },
  onBlur = () => { },
  defaultValue = "",
  isDisable,
}) {


  const [menuItem, setMenuItem] = useState('');
  const handleChange = (event) => {
    // console.log(event.target.value, "event.target.value")
    setMenuItem(event.target.value);
    onChange(event);
    onBlur(event)
  };

  //   useEffect(() => {
  //     if (defaultValue !== "" ) {
  //       setMenuItem(defaultValue)
  //       // console.log(defaultValue, 'defaultValue')
  //     }
  //   }, [defaultValue,options])

  //   useEffect(() => {
  //     getActiveSprintid(options);
  // }, [options]);

  

  useEffect(() => {
    if (defaultValue) {
      console.log('Setting menuItem to defaultValue');
      setMenuItem(defaultValue);
    } else {
      const activeSprint = options?.find((sprint) => sprint?.active === true);
      // console.log('Active sprint:', activeSprint);
      setMenuItem(activeSprint ? activeSprint?._id : '');
    }
    // console.log('Updated menuItem:', menuItem);
  }, []);


  // const getActiveSprintid = async(fiveSprints) => {
  //   console.log(fiveSprints,'fiveSprints')
  //   try {
  //     const activeSprint = await fiveSprints?.find((sprint) => sprint?.active === true);
  //     if (activeSprint) {
  //         console.log(activeSprint, 'activeSprint');
  //         setMenuItem(activeSprint?._id); 
  //     } 
  //   }
  //   catch (error) {
  //     console.log(error)
  //   }
  // }

  return (
    <Box
      sx={{
        minWidth: minimumWidth,
        display: "flex",
        flex: 1,
        maxWidth: "375px",
        flexDirection: "column",
      }}
      className="search-bar"
    >
      <FormControl fullWidth size="small">
        <InputLabel id="demo-simple-select-label">
          {labelText}
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={menuItem}
          defaultValue={menuItem}
          label={labelText}
          onChange={handleChange}
          disabled={isDisable}
        >
          {labelText === "Sprints" ?
            <MenuItem
              value={'backlog'}
            >
              Backlog Issues
            </MenuItem> : null}
          {options.map((option, index) => (
            <MenuItem
              key={index}
              value={option?._id ? option?._id : option?.name ? option?.name : option?.title}
            >
              {option?.name ? option?.name : option?.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
