export const colors = {
  primary: "#00AC56",
  secondary: "#03071E",
  font_Bold: "#262626",
  font_color: "#262626",
  required_Star_Color: "#D35D43",
  btn_grey_background: "#E5E5E5",
  text_green: "#60A630",
  green_theme_color: "#60A630",
  start_sprint_color: "#0E5FC1",
};
