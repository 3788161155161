import React, { useState } from 'react'
import { createContext } from "react";

export const ModelPageContext = createContext();

const ModelPageContextProvider = (props) => {
    const [modalState, setModalState] = useState({ open: false, createProject: false });
    return (
        <ModelPageContext.Provider value={{
            modalState, setModalState
        }} >
            {props.children}
        </ModelPageContext.Provider>
    );
}

export default ModelPageContextProvider