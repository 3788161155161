import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import "./topbar.scss";
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedProjectBackLogId } from '../../common/Redux/Reducer';
 
const NavDropdownLinks = ({ title = '', backGroundColor = 'transparent', RequiredFontSize = 'inherit', linkUrl = '#', allProjectsList = [] }) => {
    const [gallery, setGallery] = useState(null);
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { Projects } = useSelector((state) => state.ProjamAppSlice);
    let projectId = Projects?.selectedProject?.selectedProjectData?._id;
    const NavProjectsList = Projects?.allProjectsList?.data?.filter(project => project.active !== "false");
 
    const handleClick = (event) => {
        setGallery(event.currentTarget);
    };
 
    const handleClose = () => {
        setGallery(null);
    };
    const open = Boolean(gallery);
 
    let btnStyles = { backgroundColor: backGroundColor, color: '#7B7C7B' };
 
    const handleMenuItemClick = (projectId) => {
        const project = NavProjectsList?.find((proj) => proj._id === projectId);
 
        if (project && project.backlogs && project.backlogs.length > 0) {
            const backlogId = project.backlogs[0];
            const projectTitle = project.title;
            const ProjectslugString = project?.slugString;
 
            dispatch(setSelectedProjectBackLogId({ id: backlogId }));
            setGallery(null);
 
            const selectedProjectDetails = {
                projectId: projectId,
                backlogId: backlogId,
                projectName: projectTitle
            };
            document.querySelectorAll('.MenuItem').forEach(item => {
                item.classList.remove('active');
            });
            document.getElementById(projectId).classList.add('active');
            if (location.pathname === '/projects') {
                navigate(`/projects/${ProjectslugString}/active-sprints`, { state: { queryParams: selectedProjectDetails } });
            } else {
                navigate(`/projects/${ProjectslugString}/active-sprints`, { state: { queryParams: selectedProjectDetails } });
            }
        } else {
            console.error("No backlog found for this project");
        }
    };
 
    return (
        <div className='nav-dropdown-links'>
            <Button
                onClick={handleClick}
                className={` ${location.pathname.includes(linkUrl) ? "active" : ""} ${backGroundColor ? 'mx-1' : ''}`}
                endIcon={open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                style={{ boxShadow: 'none', textTransform: 'none', fontSize: RequiredFontSize, ...btnStyles }}
            >
                {title}
            </Button>
            {title === "Projects" && (
                <Menu
                    anchorEl={gallery}
                    open={open}
                    onClose={handleClose}
                    className="projects-menu"
                >
                    {NavProjectsList && NavProjectsList.length > 0 ? (
                        NavProjectsList?.map((project) => (
                            <MenuItem component="div" id={project._id} onClick={() => handleMenuItemClick(project._id)} className={`nav-menu-item px-4 ${(projectId === project._id && location.pathname !== '/projects') ? 'active-menu-link-item-cls' : ''}`} key={project._id}>
                                <NavLink to='#' className='navbar-nav-links-name'>
                                    {project.title}
                                </NavLink>
                            </MenuItem>
                        ))
                    ) : (
                        <MenuItem className="no-projects-assigned">
                            No Projects Assigned
                        </MenuItem>
                    )}
                </Menu>
            )}
        </div>
    );
};
 
export default NavDropdownLinks;