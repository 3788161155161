import React from 'react'
import Card from '@mui/joy/Card';
import Skeleton from '@mui/material/Skeleton';

export const IssueStatusCardSkeleton = ({ isStatus = false }) => {
    return (
        <Card variant="outlined" sx={{ gap: 2 }} className={`d-flex flex-${isStatus ? 'row' : "column"} justify-content-${isStatus ? 'start' : "between"} align-items-${isStatus ? 'center' : "start"} p-2`}>
            {!isStatus ? <Skeleton animation="wave" width={'100%'} /> : <Skeleton animation="wave" variant="circular" width={35} height={32} />}
            <div style={{ width: '100%' }} className={`d-flex flex-${isStatus ? 'column' : "row"} justify-content-${isStatus ? 'start' : "between"} align-items-${isStatus ? 'center' : "end"} `}>
                <Skeleton animation="wave" width={isStatus ? '100%' : '15px'} />
                {isStatus ? <Skeleton animation="wave" width={'100%'} /> : <Skeleton animation="wave" variant="circular" width={25} height={22} />}
            </div>
        </Card>

    )
}

export const NormalSkeleton = ({ width = '100px', variant = '', height = '', extraStyles = "my-1" }) => {
    return <Skeleton animation="wave" width={width} variant={variant} height={height} className={`me-2 ${extraStyles}`} style={{ borderRadius: '4px' }} />
}
export const CircularSkeleton = ({ width = 35, height = 32 }) => {
    return <Skeleton animation="wave" variant="circular" width={width} height={height} className='me-2' />
}
