import React, {forwardRef, useImperativeHandle,useRef} from "react";
import { TextField, Autocomplete, MenuItem, Box } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import './modal.css'; 

const MultiSelect = forwardRef(({ options,value, onChange },ref)=> {

  const inputRef = useRef();
  useImperativeHandle(ref, () => ({
    focus: () => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  }));
  return (
    <Autocomplete
    sx={{
      m: 1,
      width: '100%',
      "& .MuiAutocomplete-inputRoot": {
          padding: "3px 6px",
        },
    }}
      multiple
      options={options}
      value={value}
      getOptionLabel={(option) => option.summary}
      disableCloseOnSelect
      onChange={onChange}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Linking Issues"
          placeholder="Linking Issues"
          InputLabelProps={{
            sx: { 
              "&.Mui-focused": {
                color: "#60A630",
              },
            },
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "inherit", // Default border color
              },
              "&:hover fieldset": {
                borderColor: "#60A630", // Border color on hover
              },
              "&.Mui-focused fieldset": {
                borderColor: "#60A630", // Border color when focused
              },
            },
          }}
          inputRef={inputRef}
        />
      )}
      renderOption={(props, option, { selected }) => (
        <MenuItem
          {...props}
          key={option.summary}
          value={option.summary}
          sx={{ justifyContent: "space-between", minHeight: 36 }} 
        >
          {option.summary}
          {selected ? <CheckIcon color="info" /> : null}
        </MenuItem>
      )}
    />
  );
});



export default MultiSelect;