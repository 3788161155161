import React, { useEffect } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getUserVerify } from '../Redux/Reducer';
import { useNavigate } from 'react-router-dom';

const Protected = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = localStorage.getItem('accessToken');

    useEffect(() => {
        const headers = {
            Authorization: `Bearer ${token}`,
        };

        let intervalId;

        const handleVisibilityChange = () => {
            if (document.hidden) {
                intervalId = setInterval(() => {
                    if (token) {
                        dispatch(getUserVerify({ headers, navigate }));
                    }
                }, 5 * 60 * 1000); 
            } else {
                if (intervalId) {
                    clearInterval(intervalId);
                }
            }
        };

        if (token) {
            dispatch(getUserVerify({ headers, navigate }));
            document.addEventListener('visibilitychange', handleVisibilityChange);
        }
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [dispatch, navigate, token]);

    if (token) {
        return <Outlet />;
    }

    return <Navigate to="/login" />;
};

export default Protected;
