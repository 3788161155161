import React from "react";
import Avatar from "@mui/material/Avatar";
import AvatarGroup from "@mui/material/AvatarGroup";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import BackgroundLetterAvatarsLogo from "./AvatarLogo";
import { Box, Typography, Popover, Checkbox } from "@mui/material";
import { colors } from "../utilities/colors";
import { setFilterAssignees } from "../common/Redux/Reducer";
import { useSelector, useDispatch } from "react-redux";

export default function GroupAvatars({ setOpenAddPeopleModel }) {
  const dispatch = useDispatch();
  const { Projects, assignees } = useSelector((state) => state.ProjamAppSlice);
  const contributors = Projects?.selectedProject?.contributors; 
  const [anchorEl, setAnchorEl] = React.useState(null);
  let openPopOver = Boolean(anchorEl);
  const id = openPopOver ? "simple-popover" : null;
  const maxVisibleAvatars = 4;

  const handleAvatarClick = (name) => {
    dispatch(setFilterAssignees(name));
  };

  const handelAvtarCheckBoxclicked = (e, name) => {
    dispatch(setFilterAssignees(name));
  };

  const handlePopOverClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopOverClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {contributors && (
        <>
          <AvatarGroup max={maxVisibleAvatars + 1}>
            {contributors?.slice(0, maxVisibleAvatars).map((member) => (
              <BackgroundLetterAvatarsLogo
                key={member?.employeeId}
                LogoTitle={member?.employeeName}
                RequiredFontSize="10px"
                filterPeoplesArray={assignees}
                handleAvatarClick={() =>
                  handleAvatarClick(member?.employeeName)
                }
              />
            ))}
            {contributors.length > maxVisibleAvatars && (
              <span>
                <Avatar
                  className="onAvatar showmoreMembers"
                  aria-describedby={id}
                  onClick={(event) => handlePopOverClick(event)}
                  sx={{
                    bgcolor: colors.btn_grey_background,
                    fontSize: "18px",
                  }}
                >
                  {`+${contributors.length - maxVisibleAvatars}`}
                </Avatar>

                <Popover
                  id={id}
                  open={openPopOver}
                  anchorEl={anchorEl}
                  onClose={handlePopOverClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  className=""
                  PaperProps={{
                    sx: {
                      boxShadow: "none",
                      borderRadius: 0,
                      padding: 1,
                      backgroundColor: "transparent",
                    },
                  }}
                >
                  <Box className="rounded Avatar_PopOver_Box">
                    {contributors.slice(maxVisibleAvatars).map((eachMember) => {
                      const isChecked = assignees.includes(eachMember.employeeName);
                      return (
                        <Box id="Avatar_PopOver_List" key={eachMember?.employeeId}>
                          <Checkbox
                            checked={isChecked}
                            onChange={(e) =>
                              handelAvtarCheckBoxclicked(
                                e,
                                eachMember.employeeName
                              )
                            }
                          />
                          <Typography>{eachMember.employeeName}</Typography>
                        </Box>
                      );
                    })}
                  </Box>
                </Popover>
              </span>
            )}
          </AvatarGroup>
          <Avatar
            alt=""
            className="onAvatar"
            sx={{
              backgroundColor: "#72726E",
            }}
            onClick={() => setOpenAddPeopleModel((prev) => !prev)}
          >
            <PersonAddIcon />
          </Avatar>
        </>
      )}
    </>
  );
}
