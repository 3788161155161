
import { Typography,Box, Avatar } from "@mui/material";
import React ,{useEffect,useState}from "react";
import { PieChart, Pie, Legend ,Label,Tooltip } from "recharts";
import './style.css'
import BackgroundLetterAvatarsLogo from "../../components/AvatarLogo";
import CustomizedProgressBars from "../../components/Progressbar";


export default function IssueAnalysis({pieChartData}){

  const [data , setData]=useState([])
  const [labelValue,setLabelValue]=useState(80);
  const [AssigneesInfo,setAssigneesInfo]=useState(pieChartData[0]);
  const [DoneCountInfo,setDoneCountInfo]=useState([])

  
useEffect(()=>{
getPichartInfo(pieChartData[0]?.IssuecountObj)
getLableText(pieChartData[0])
getUsersDoneCount(pieChartData[0]?.AssigneGroupObj)
},[pieChartData])


const getUsersDoneCount = async(AssigneesInfo=[])=>{
  
try{

  let DoneCountInfoDataArray = await Promise.all(AssigneesInfo?.map((each)=>{
            let{employeeDetails ,assignedIssues}=each
            let EmpName=employeeDetails.employeeName;
            let doneCount = assignedIssues.filter((issue)=>{
          return issue.status =='Done'
})
        if(assignedIssues?.length==0){
        
          return {EmpName:EmpName ,doneCount:0 }
        }
        return {EmpName:EmpName ,doneCount:Math.ceil((doneCount.length*100)/assignedIssues?.length) }
       
            
  }))
 
setDoneCountInfo(DoneCountInfoDataArray)

}
catch(error){
console.log(error)
}
}



const getLableText =async(data)=>{
try{

const totalIssues=data?.issues?.length;
const completedIssues=data?.IssuecountObj?.Done
const labelText=Math.ceil((completedIssues*100/totalIssues))
setLabelValue(labelText)
}
catch(error){
  console.log(error)
}
}

const renderColor = (key) => {
    switch (key) {
      case 'To Do':
        return '#6c757d'; // secondary
      case 'In Progress':
        return '#17a2b8'; // info
      case 'In Review':
        return '#ffc107'; // warning
      case 'QA':
        return '#dc3545'; // danger
      case 'Done':
        return '#28a745'; // success
      case 'Production Ready':
        return '#343a40'; // dark
      default:
        return '#007bff'; // primary
    }
  };

  function CustomLabel({viewBox, value1=80, value2="Tasks Done"}){
    const {cx, cy} = viewBox;
    return (
     <text x={cx} y={cy} fill="#3d405c" className="recharts-text recharts-label" textAnchor="middle" dominantBaseline="central">
        <tspan x={cx} dy="-0.5em" alignmentBaseline="middle" fontSize="26">{value1}%</tspan>
        <tspan  x={cx} dy="1.8em" fontSize="14">{value2}</tspan>
     </text>
    )
  }
  
  const renderColorfulLegendText = (value= "" ,entry= "") => {
    return (
      <span style={{ color: "#596579", fontWeight: 500, padding: "10px" }}>
        {value}
      </span>
    );
  };
 


  const getPichartInfo= async(IssuecountObj={})=>{

    try{
       
    let dataArray= await Promise.all(Object.keys(IssuecountObj).map(async (eachkey)=>{
        return {name:eachkey , value:IssuecountObj[eachkey] , fill: renderColor(eachkey)}
    }))
   
    setData(dataArray)
    }
    catch(error){
    console.log(error)
    }
    }


    const getDoneCount=async (data)=>{
      const DoneIssues= data.filter((issue)=>{
        return issue.status=='Done'
      })
      return DoneIssues*100/data.length
    }




  return (
    <Box>
    <PieChart width={500} height={170}>
      <Pie
        data={data}
        cx={130}
        cy={80}
        startAngle={0}
        endAngle={360}
        innerRadius={60}
        outerRadius={80}
        fill="#8884d8"
        paddingAngle={0}
        dataKey="value"
        blendStroke
      >
        <Label width={30} position="center"
              content={<CustomLabel value1={labelValue} value2="Tasks Done"/>}>
      </Label>
     
      </Pie>
      <Tooltip/>
      <Legend
      cx={200}
      cy={150}
        height={100}
        iconType="circle"
        layout="vertical"
        verticalAlign="end"
        iconSize={10}
        padding={5}
        wrapperStyle={{ position: 'absolute', left: '300px', top: '20px' }}
        formatter={renderColorfulLegendText}
      />
      
    </PieChart>
    <Typography sx={{fontWeight:"bold",marginTop:'5px' }}>TeamWork Load</Typography>
    <Box className='outer-Box shadow' sx={{height:120}}>  
    <Box className='Pie-chart-Box px-3'>
    
      {pieChartData[0]?.AssigneGroupObj?.map((data , index)=>{
        let{employeeDetails ,assignedIssues}=data
         return <Box sx={{display:"flex", alignItems:"center",justifyContent:'space-between' , gap:"10px"}} key={index}>
          <BackgroundLetterAvatarsLogo 
          LogoTitle={employeeDetails?.employeeName}/> 
          <Typography >{employeeDetails?.employeeName}</Typography>
          <Box sx={{width:120}}>
          <CustomizedProgressBars value={DoneCountInfo[index]?.doneCount}/>
            </Box> 
            <Typography>{DoneCountInfo[index]?.doneCount}% completed</Typography>
            <Typography>{assignedIssues.length}</Typography>
          
         </Box>
        
      })}
    </Box>
    </Box>
    </Box>
  );
}

