import React from "react";
import { Autocomplete, TextField } from "@mui/material";

const SearchAutoComplete = ({
  labelName = "Search",
  data = [],
  setSelectedEmp = () => { },
  handelAssigneUpdate = () => { },
}) => {
  const handleSelectionChange = (event, value) => {
    const selectedUser = data.find((emp) =>
      emp.email ? emp.email === value : emp.employeeEmail === value
    );
    if (selectedUser) {
      setSelectedEmp(selectedUser);
      handelAssigneUpdate(selectedUser);
    }
  };

  return (
    <div>
      <Autocomplete
        freeSolo
        id="free-solo-2-demo"
        className="search-bar"
        disableClearable
        sx={{ minWidth: "250px" }}
        options={data.map((emp) => (emp.email ? emp.email : emp.employeeEmail))}
        onChange={handleSelectionChange}
        renderInput={(params) => (
          <TextField
            {...params}
            label={labelName}
            InputProps={{
              ...params.InputProps,
              type: "search",
            }}
            size="small"
          />
        )}
      />
    </div>
  );
};

export default SearchAutoComplete;
