import React from 'react';
import { TextField, IconButton } from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

const SearchTextField = ({ searchTerm = '', handleSearch = () => {} }) => {
    const onChangeSearchText = (event) => {
        handleSearch(event.target.value);
    };

    const onKeyDown = (event) => {
        if (event.key === ' ' && searchTerm.trim() === '') {
            event.preventDefault(); 
        }
    };

    return (
        <TextField
            className="search-bar my-2 mx-2"
            id="standard-bare"
            variant="outlined"
            placeholder="Search ..."
            type="search"
            size="small"
            onChange={onChangeSearchText}
            onKeyDown={onKeyDown}
            value={searchTerm}
            InputProps={{
                startAdornment: (
                    <IconButton
                        className="search-icon-btn ps-0"
                        sx={{ backgroundColor: "transparent !important" }}
                    >
                        <SearchOutlinedIcon
                            sx={{
                                backgroundColor: "transparent !important",
                                "&:hover": {
                                    backgroundColor: "transparent !important", 
                                }
                            }}
                        />
                    </IconButton>
                )
            }}
        />
    );
};

export default SearchTextField;
