import React from "react";
import { Box, Typography } from "@mui/material";
import Nodata from "../assets/images/Nodata.png";

const NoDataFound = () => {
  return (
    <Box sx={{ p: 2, border: "2px dashed #e0e0e0", borderRadius: "4px" }}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="150px"
      >
        <Box display="flex" justifyContent="center" alignItems="center">
          <img
            src={Nodata}
            style={{ width: "15%", height: "15%", marginRight: "8px" }}
            alt="No data"
          />
          <Box>
            <Typography variant="h6">Plan your sprint</Typography>
            <Typography variant="body2" color="textSecondary">
              Create new issues, to plan the work for this sprint. 
              <br />
              Select <strong>Start sprint</strong> when you're ready.
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default NoDataFound;
