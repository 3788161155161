import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Checkbox from "@mui/material/Checkbox";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import StarIcon from "@mui/icons-material/Star";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import BackgroundLetterAvatarsLogo from "../AvatarLogo";
import { useNavigate } from "react-router-dom";
import "./table.scss";
import projectsData from "./projectsData.json";
import { ProjectContext } from "../../containers/Dashboard";
import { Stack, Pagination, Typography, Switch } from "@mui/material";
import { useDispatch } from "react-redux";
import { setSelectedProjectBackLogId } from "../../common/Redux/Reducer";
import ActiveandInactiveModal from "../Modal/ActiveandInactiveModal";
import moment from "moment";
import API_BASE_URL from "../config/appConfig";
import axios from "axios";

const headCells = projectsData.headCells;

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, filteredProjects, setSelected, setSelectedProjectIds, selected } = props;

  const [selectAllReports, setSelectAllReports] = useState([]);
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const numSelected = selected.length;
  const rowCount = filteredProjects.length;

  const onSelectAllClick = (event) => {
    if (event.target.checked) {
      const allProjectIds = filteredProjects.map((project) => project._id);
      setSelected(allProjectIds);
      setSelectedProjectIds(allProjectIds);
    } else {
      setSelected([]);
      setSelectedProjectIds([]);
    }
  };


  return (
    <TableHead sx={{ backgroundColor: "#D5DCE5" }}>
      <TableRow sx={{ width: "100%" }}>
        <TableCell padding="checkbox" className="px-4 pe-5">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all projects',
            }}
            sx={{
              '& .MuiSvgIcon-root': { fontSize: '1rem' }  
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="left"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,

};

export default function JiraProjectsTable({
  searchTerm,
  selectedProject,
  projects = [],
  loading,
  setSelectedProjectIds
}) {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("title");
  const [selected, setSelected] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [error, setError] = useState(null);
  const [filteredProjectsList, setFilteredProjectsList] = useState(projects);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUserData, setSelectedUserData] = useState(null);
  const [switchState, setSwitchState] = useState({});
  const [riskPercentages, setRiskPercentages] = useState({});
  const [loadings, setLoadings] = useState({});
  const dispatch = useDispatch();
  const {
    projectsList,
    setProjectsList,
    setBacklogId,
    setSelectedProjectTitle,
  } = useContext(ProjectContext);


  const navigate = useNavigate();

  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
  };

  // useEffect(() => {
  //   const fetchRiskPercentages = async () => {
  //     const results = {};
  //     const loadingStatus = {};
  
  //     for (const row of filteredProjectsList) {
  //       loadingStatus[row?._id] = true;
  //       setLoadings(loadingStatus);
  
  //       try {
  //         const response = await axios.post(
  //           `${API_BASE_URL}/projects/reports`,
  //           { projectIds: [row._id] },
  //           { headers: { 'Content-Type': 'application/json' } }
  //         );
  //         const sprintsInfo = response.data[0]?.sprintsInfo || [];
  //         results[row?._id] = getRiskPercentage(sprintsInfo);
  //       } catch (error) {
  //         console.error("Error fetching risk percentage:", error);
  //         results[row._id] = "Error";
  //       }
  //       loadingStatus[row._id] = false;
  //     }
  //     setRiskPercentages(results);
  //     setLoadings(loadingStatus);
  //   };
  
  //   fetchRiskPercentages();
  // }, [filteredProjectsList]);
  

  const getRiskPercentage = (sprintsInfo) => {
    if (!sprintsInfo?._id) return "_";
    else{
    const sprint = sprintsInfo;
    const today = new Date();
    const endDate = new Date(sprint.endDate);
    const startDate = new Date(sprint.startDate);
    const totalDays = (endDate - startDate) / (1000 * 60 * 60 * 24);
    const daysLeft = (endDate - today) / (1000 * 60 * 60 * 24);
    
    const daysLeftPercentage = daysLeft >= 0 ? (daysLeft * 100) / totalDays : 0;
  
    const totalIssues = sprint.totalIssues || 0;
    const issuesCompleted = sprint.IssuesDone || 0;
    const issuesLeft = totalIssues - issuesCompleted;
    const issuesLeftPercentage = totalIssues > 0 ? (issuesLeft * 100) / totalIssues : 0;
    const difference = Math.abs(issuesLeftPercentage - daysLeftPercentage);  
    return difference > 30 ? "At Risk" : "On Track";
  };
}
  


  // const calculateRiskPercentage = (sprint) => {
  //   const today = new Date();
  //   const endDate = new Date(sprint.endDate);
  //   const startDate = new Date(sprint.startDate);

  //   const totalDays = (endDate - startDate) / (1000 * 60 * 60 * 24);
  //   const daysLeft = (endDate - today) / (1000 * 60 * 60 * 24);
  //   const daysLeftPercentage = (daysLeft * 100) / totalDays;

  //   const totalIssues = sprint.totalIssues;
  //   const issuesCompleted = sprint.IssuesDone;
  //   const issuesLeft = totalIssues - issuesCompleted;
  //   const issuesLeftPercentage = (issuesLeft * 100) / totalIssues;
  //   const difference = Math.abs(issuesLeftPercentage - daysLeftPercentage);

  //   return difference > 20 ? "At Risk" : "On Track";
  // };




  useEffect(() => {
    let filteredProjects = [...projects];
    if (searchTerm) {
      filteredProjects = filteredProjects.filter((project) =>
        project.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
    if (selectedProject) {
      filteredProjects = filteredProjects.filter(
        (project) => project.title === selectedProject
      );
    }

    filteredProjects.sort((a, b) => (a.active === "true" ? -1 : 1));
    setFilteredProjectsList([...filteredProjects]);
  }, [searchTerm, selectedProject, projects]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    const newOrder = isAsc ? "desc" : "asc";
    setOrder(newOrder);
    setOrderBy(property);

    // Create a new sorted array
    const sortedProjects = [...filteredProjectsList].sort((a, b) => {
      if (newOrder === "asc") {
        return a[property] > b[property] ? 1 : -1;
      } else {
        return a[property] < b[property] ? 1 : -1;
      }
    });
    setFilteredProjectsList(sortedProjects);
  };

  const handleClick = async (event, row) => {
    if (row) {
      const backlogId = row?.backlogs[0];
      if (backlogId) {
        // localStorage.setItem("backlogId", backlogId);
        // localStorage.setItem("projectId", row?._id);
        // localStorage.setItem("projectslug" , row?.slugString);
        // localStorage.setItem("projectName", row?.title);
        dispatch(setSelectedProjectBackLogId({ id: backlogId }));
        setBacklogId(backlogId);
        setSelectedProjectTitle(row?.title);
        const selectedProjectDetails = {
          projectId: row?._id,
          backlogId: backlogId,
          projectName: row?.title,
          projectslug: row?.slugString
        };
        navigate(`/projects/${row?.slugString}/active-sprints`, {
          state: { queryParams: selectedProjectDetails },
        });
      } else {
        console.error("No backlog found for this project");
      }
    }
  };


  // const handleSelectedProjects = (data) => {
  //   const isAvailable = selected.find(
  //     (eachData) => eachData?._id === data?._id
  //   );

  //   if (isAvailable) {
  //     const filteredSelectedData = selected.filter(
  //       (eachData) => eachData?._id !== data?._id
  //     );
  //     setSelected(filteredSelectedData);
  //     setSelectedProjectIds([...filteredSelectedData])
  //   } else {
  //     setSelected([...selected, data]);
  //     setSelectedProjectIds([...selected, data])
  //   }

  // };

  const handleSelectedProjects = (project) => {
    const selectedIndex = selected.indexOf(project._id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, project._id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
    setSelectedProjectIds(newSelected);
  };



  const isSelected = (id) => selected.find((eachData) => eachData?._id === id);

  const handleStatusClick = (event, row) => {
    setSelectedUserData(row);
    setIsModalOpen(true);
  };

  const handleActive = async (id) => {
    const newStatus = !switchState[id];
    try {
      const response = await axios.put(
        `${API_BASE_URL}/projects/updateProject/${id}`,
        {
          active: newStatus,
        }
      );
      if (response.status === 200) {
        setSwitchState((prevState) => ({
          ...prevState,
          [id]: newStatus,
        }));
        setSelectedUserData(null);
        setIsModalOpen(false);
      } else {
        console.error("Failed to update project status");
      }
    } catch (error) {
      console.error("Error updating project status:", error);
    }
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  useEffect(() => {
    const initialSwitchState = {};
    projects.forEach((project) => {
      initialSwitchState[project._id] = project.active === "true";
    });
    setSwitchState(initialSwitchState);
  }, [projects]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  // console.log("filteredProjectsList", filteredProjectsList);




  // const getRiskPercentage = async (row) => {
  //   try {
  //     const response = await axios.post(
  //       `${API_BASE_URL}/projects/reports`,
  //       {
  //         projectIds: row,
  //       }
  //     );
  //     console.log("response", response)
  //   } catch (error) {
  //     console.error("Error updating project status:", error);
  //   }
  // }



  return (
    <Box sx={{ width: "100%" }} className="px-2 pt-3">
      <Paper
        sx={{
          width: "100%",
          mb: 2,
          border: "0px !important",
          backgroundColor: "transparent ",
        }}
        className="shadow-none"
      >
        <TableContainer className="table-container">
          <Table
            sx={{ minWidth: 750, borderRadius: "30px" }}
            aria-labelledby="tableTitle"
            id="projects"
          >
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={filteredProjectsList.length}
              filteredProjects={filteredProjectsList}
              setSelected={setSelected}
              selected={selected}
              setSelectedProjectIds={setSelectedProjectIds}
            />
            <TableBody sx={{ backgroundColor: "#F4F6F9" }}>
              {filteredProjectsList?.length > 0 &&
                filteredProjectsList
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = selected.includes(row._id);
                    const labelId = `enhanced-table-checkbox-${index}`;
                     const riskPercentage = riskPercentages[row._id];
                    const isLoading = loadings[row._id];
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row._id}
                        selected={isItemSelected}
                        sx={{ cursor: "pointer" }}
                      >
                        <TableCell padding="checkbox" className="px-4 pe-5">
                          <button
                            style={{
                              border: 0,
                              backgroundColor: "transparent",
                              padding: 0,
                            }}
                            onClick={(event) => handleSelectedProjects(row)}
                          >
                            <Checkbox
                              checked={isItemSelected}
                              onChange={(event) => handleSelectedProjects(row)}
                            />
                          </button>
                        </TableCell>
                        <TableCell id={labelId} scope="row" align="left">
                          <Typography
                            className={`project-title-cls ${row.active === "false" ? "inactive-project" : ""
                              }`}
                            onClick={(event) => handleClick(event, row)}
                            style={{
                              pointerEvents:
                                row.active === "false" ? "none" : "auto",
                              textDecoration:
                                row.active === "false" ? "none" : "",
                            }}
                          >
                            {row.title}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">{row.key}</TableCell>
                         <TableCell align="left">
  
                         <span className={`pill 
        ${getRiskPercentage(row?.sprintInfo) === "At Risk" ? "pill-warning" : 
          getRiskPercentage(row?.sprintInfo) === "On Track" ? "pill-success" : 
          getRiskPercentage(row?.sprintInfo) === "_" ? "black" : "black"}`}
    >
                          
                          {getRiskPercentage(row?.sprintInfo)}
                          </span>
                          </TableCell>
                        {/* <TableCell align="left">{getRiskPercentage(row._id)}</TableCell> */}
                        {/* <TableCell align="left">
  {loadings[row._id] ? " " : riskPercentages[row._id] || "_"}
</TableCell> */}
{/* <TableCell align="left">
  {loadings[row._id] ? (
    " "
  ) : (
    <span 
      className={`pill 
        ${riskPercentages[row._id] === "At Risk" ? "pill-warning" : 
        riskPercentages[row._id] === "On Track" ? "pill-success" : 
        riskPercentages[row._id] === "_" ? "black" : "black"}`}
    >
      {riskPercentages[row._id] || "_"}
    </span>
  )}
</TableCell> */}


                        <TableCell align="left">{row.type}</TableCell>
                        <TableCell align="left">
                          {moment(row.createdAt).format("DD MMM YYYY")}
                        </TableCell>
                        <TableCell
                          align="left"
                          className="d-flex align-items-center"
                        >
                          {row.lead?.name && (
                            <>
                              <BackgroundLetterAvatarsLogo
                                LogoTitle={row.lead.name}
                              />
                              <span className="m-2">{row.lead.name}</span>
                            </>
                          )}
                        </TableCell>
                        <TableCell align="left">
                          <Switch
                            checked={switchState[row._id]}
                            onChange={(event) => handleStatusClick(event, row)}
                            inputProps={{ "aria-label": "controlled" }}
                            color="success"
                          />
                          {switchState[row._id] ? "Active" : "Inactive"}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              {filteredProjectsList?.length === 0 && (
                <TableRow style={{ height: 120 }} className="text-center py-5">
                  <TableCell
                    colSpan={headCells?.length + 1}
                    className="text-center py-5"
                    sx={{ fontSize: "20px" }}
                  >
                    <b>No Projects Available</b>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {filteredProjectsList?.length > 0 && (
          <div className="d-flex justify-content-end mt-2 table-pagination-container-cls">
            <Stack spacing={2}>
              <Pagination
                count={Math.ceil(filteredProjectsList.length / rowsPerPage)}
                page={page + 1}
                onChange={handleChangePage}
                shape="rounded"
                variant="outlined"
              />
            </Stack>
          </div>
        )}
      </Paper>
      {selectedUserData && (
        <ActiveandInactiveModal
          open={isModalOpen}
          toggleModal={toggleModal}
          handleActive={handleActive}
          projectData={selectedUserData}
          switchState={switchState}
        />
      )}
    </Box>
  );
}

JiraProjectsTable.propTypes = {
  searchTerm: PropTypes.string,
  selectedProject: PropTypes.string,
};
