import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import { Typography, Button, Tooltip } from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import BackgroundLetterAvatarsLogo from "../AvatarLogo";
import Checkbox from "@mui/material/Checkbox";
import SelectMenuItems from "../SelectMenuItems";
import "./backlogtable.css";
import { issueStatus } from "../../utilities/Json";
import { useDispatch, useSelector } from "react-redux";
import { getSprintsList, getBacklogIssues, getLastFiveActiveSprintInfo, SetlastfiveActiveSprintInfo } from "../../common/Redux/Reducer";
import CreateBacklogIssue from "../Create_Backlogissue";
import Popover from "@mui/material/Popover";
import axios from "axios";
import API_BASE_URL from "../config/appConfig";
import NoDataFound from "../NoDataFound";
import SearchableDropdown from "../Modal/SearchableDropDown";
import { Draggable } from "react-beautiful-dnd";
import { jwtDecode } from "jwt-decode";
import { getIssueTypeIcon, getPriorityIcon } from "../../utilities/Icons";

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  ...draggableStyle,
  backgroundColor: isDragging ? 'white' : 'white',
  boxShadow: isDragging ? '0 2px 10px rgba(0, 0, 0, 0.2)' : 'none',
});


const BacklogTable = ({
  data = [],
  sprint = null,
  handleIssueClick = () => { },
  handleSprintIssueClick = () => { },
  issuesSelected = [],
  setIssuesSelected = () => { },
  searchTerm,
  AllsprintDetails = [],
  draggedTodoItem,
  selectedIssueType = '',
  selectPriorityType = ''
}) => {
  const [isCreateNewBackIssuse, setIsCreateNewBackIssue] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [currentRowId, setCurrentRowId] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  // const [selectedIssuesArray, setSelectedIssuesArray] = useState(issuesSelected)
  const dispatch = useDispatch();
  const filterAssigneesArray = useSelector((state) => state.ProjamAppSlice.assignees);
  const { Projects } = useSelector((state) => state.ProjamAppSlice)
  const contributors = Projects?.selectedProject?.contributors
  const issueStatus = Projects?.selectedProject?.selectedProjectData?.IssueStatus;
  
  let backlogId = Projects?.selectedProject?.selectedProjectBackLogId
  let projectId = Projects?.selectedProject?.selectedProjectData?._id

  let openPopOver = Boolean(anchorEl);
  const id = openPopOver ? "simple-popover" : undefined;

  // useEffect(() => {
  //   setSelectedIssuesArray(issuesSelected);
  // }, [issuesSelected]);

  const handleIssueSelected = (e, id) => {
    const isChecked = e.target.checked;

    setIssuesSelected((prevSelectedIssues) => {
      let updatedIssues = [...prevSelectedIssues];

      if (isChecked && !issuesSelected.includes(id)) {
        if (!updatedIssues.includes(id)) {
          updatedIssues.push(id);
        }
      } else {
        updatedIssues = updatedIssues.filter((issueId) => issueId !== id);
      }
      return updatedIssues;
    });
  };


  const onCreateBackLogIssue = () => {
    setIsCreateNewBackIssue(true);
  };

  const handleStatuUpdate = async (issueId, currentStatus, newStatus) => {
    // Map frontend values to backend values
    // const statusToSend = 
    //     newStatus === 'Code Review' ? 'In Review' : 
    //     newStatus === 'In Testing' ? 'QA' : 
    //     newStatus;

    // // Log to check mappings
    // console.log('Current Status:', currentStatus);
    // console.log('New Status:', newStatus);
    // console.log('Status to Send:', statusToSend);

    // If the status hasn't changed, return early
    if (currentStatus === newStatus) {
      return;
    }
    try {
      // Log before making the API call
      console.log('Sending status update to backend...');
      const token = localStorage.getItem("accessToken");
      const decoded = jwtDecode(token);

      let response = await axios.put(
        `${API_BASE_URL}/issues/updateIssue/${issueId}?userid=${decoded?.id}`,
        { status: newStatus }
      );
      if(newStatus == 'Done'){
        let response =  dispatch(getLastFiveActiveSprintInfo({backlogId , projectId})) 
          dispatch(SetlastfiveActiveSprintInfo(response))
      }
      // Log the response from the backend
      //console.log('Backend Response:', response.data);

    } catch (error) {
      // Log any errors
      //console.error('Error updating status:', error);
    } finally {
      dispatch(
        getSprintsList({ backlogId: backlogId, filterAssigneesArray: filterAssigneesArray })
      );
      // Refresh backlog issues and sprint list
      dispatch(
        getBacklogIssues(
          backlogId,
          filterAssigneesArray
        )
      );
     
      // Log completion of the update
    //  console.log('Status update complete.');
    }
};


  const handelAssigneUpdate = async (issueId, empledata) => {
    const currentAssignee = data.find(issue => issue._id === issueId)?.assignee?.[0]?.employeeName;
    if (currentAssignee === empledata?.employeeName) {
      return;
    }

    try {
      const token = localStorage.getItem("accessToken");
      const decoded = jwtDecode(token);
      let response = await axios.put(
        `${API_BASE_URL}/issues/updateIssue/${issueId}?userid=${decoded?.id}`,
        {
          assignee: empledata ? [empledata] : [],
        }
      );
      // console.log("Assignee Updated ", response.data);
    } catch (error) {
      console.log("error", error);
    } finally {
      dispatch(getBacklogIssues(backlogId));
      dispatch(getSprintsList({ backlogId: backlogId, filterAssigneesArray: filterAssigneesArray }));
      handlePopOverClose();
    }
  };

  const handlePopOverClick = (event, rowId) => {
    setAnchorEl(event.currentTarget);
    setCurrentRowId(rowId);
  };

  const handlePopOverClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    let filteredData = data;

    if (searchTerm) {
      const lowercasedTerm = searchTerm.toLowerCase();
      filteredData = filteredData?.filter(
        (item) =>
          (item.name && item.name.toLowerCase().includes(lowercasedTerm)) ||
          (item.summary && item.summary.toLowerCase().includes(lowercasedTerm))
      );
    }

    if (selectedIssueType) {
      filteredData = filteredData?.filter(
        (eachIssue) => eachIssue?.issueType?.toLowerCase() === selectedIssueType?.toLowerCase()
      );
    }

    if (selectPriorityType) {
      filteredData = filteredData?.filter(
        (eachIssue) => eachIssue?.priority?.toLowerCase() === selectPriorityType?.toLowerCase()
      );
    }

    setFilteredData(filteredData?.length > 0 ? filteredData : []);

  }, [searchTerm, data, selectedIssueType, selectPriorityType]);
   
  const applyImageStyles = (html) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;
    const images = tempDiv.querySelectorAll('img');
    images.forEach(img => {
      img.style.width = '300px';  
      img.style.height = 'auto';  
    });

    return tempDiv.innerHTML;
  };

  return (
    <Box sx={{ width: "100%" }} className="px-3 py-2" >
      <Paper sx={{ width: "100%", mb: 1 }} className="shadow-none">
        <TableContainer sx={{ maxHeight: 450 }}>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <TableBody>
              {filteredData.map((row, index) => {
                return (
                  <Draggable key={row?._id} draggableId={row?._id || `issue-${index}`} index={index} >
                    {(provided, snapshot) => (

                      <div key={row?._id} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
                        <TableRow
                          hover
                          tabIndex={-1}
                          sx={{ cursor: "pointer" }}
                          className="d-flex align-items-center justify-content-between"
                          id="backlog-table-row"
                        >
                          <TableCell className="d-flex align-items-start"
                          sx={{width:'75%'}}
                          >
                            <Checkbox
                              disabled={row?.status == 'Done'}
                              sx={{ padding: "1px !important" }}
                              className="me-1"
                              onChange={(e) => handleIssueSelected(e, row?._id)}
                            />
                            <Tooltip title={row.issueType || "Story"}>
                              {getIssueTypeIcon(row.issueType)}
                            </Tooltip>
                            <Box
                              className="d-flex flex-column ml-2 "
                              onClick={() => {
                                if (sprint) {
                                  handleSprintIssueClick(row);
                                } else {
                                  handleIssueClick(row);
                                }
                              }}
                            >
                              <Typography sx={{ fontSize: "14px", color: "#7B7C7B" }}>
                                {row?.name}. {row?.summary}
                                <span style={{ fontSize: "12px", color: "#000000" }}>
                                  {row?.titleMsg ? row?.titleMsg : ""}
                                </span>
                              </Typography>
                              {/* <Box sx={{ width: '800px' }}> */}
                              <Typography
                                sx={{
                                  fontSize: "10px !important",
                                  color: "#7B7C7B",
                                  fontStyle: "italic",
                                  overflow: "hidden !important",
                                  whiteSpace: "normal !important",
                                  wordBreak: "break-word !important",
                                  width: "100%",
                                  display: "-webkit-box !important", 
                                  WebkitBoxOrient: "vertical !important", 
                                  WebkitLineClamp: 4, 
                                }}
                                className="mt-2"
                                dangerouslySetInnerHTML={{ __html: applyImageStyles(row?.description ? row?.description : "") }}
                              >
                              </Typography>
                              {/* </Box> */}
                            </Box>
                          </TableCell>
                          <TableCell
                            align="left"
                            className="d-flex align-items-center"
                          >
                            {row.meetings ? (
                              <Button
                                variant="contained"
                                sx={{
                                  backgroundColor: "#F49B08 !important",
                                  fontSize: "10px !important",
                                }}
                                className="px-1 py-1 mx-2"
                              >
                                MEETINGS, SCRUM, NON-ISSUE, RE..
                              </Button>
                            ) : null}
                            <SelectMenuItems
                              title={row?.status}
                              backGroundColor="#E3E4E9"
                              ItemsList={issueStatus}
                              RequiredFontSize="10px"
                              showHeader={false}
                              handleStatuUpdate={(values) =>
                                handleStatuUpdate(row?._id, row?.status, values)
                              }
                            />
                            <Button
                              aria-describedby={id}
                              onClick={(event) => handlePopOverClick(event, row?._id)}
                              className="assingeeBtnDropdownBacklog_main"
                            >
                              <BackgroundLetterAvatarsLogo
                                LogoTitle={
                                  row?.assignee
                                    ? row?.assignee?.length > 0 ? row?.assignee[0]?.employeeName : ''
                                    : row?.status
                                }
                                RequiredFontSize="10px"
                              />
                            </Button>
                            <Tooltip title={row.priority || "Urgent"}>
                              {getPriorityIcon(row.priority)}
                            </Tooltip>
                            <Popover
                              id={id}
                              open={openPopOver}
                              anchorEl={anchorEl}
                              onClose={handlePopOverClose}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                              }}
                              PaperProps={{
                                sx: {
                                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                                  borderRadius: 0,
                                  padding: 1,
                                  width: 270,
                                },
                              }}
                            >
                              <Box>
                                <SearchableDropdown
                                  workFlowModalTaskData={row}
                                  members={contributors}
                                  handleAssigneeUpdate={handelAssigneUpdate}
                                  currentRowId={currentRowId}
                                />
                              </Box>
                            </Popover>
                          </TableCell>
                        </TableRow>
                      </div>)}
                  </Draggable>
                );
              })}
              {filteredData.length === 0 && (
                <TableRow
                  style={{
                    height: 13,
                  }}
                >
                  <TableCell colSpan={6}>
                    <NoDataFound />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {!isCreateNewBackIssuse ? (
        <Typography
          sx={{ fontSize: "16px", cursor: "pointer" }}
          onClick={() => onCreateBackLogIssue()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="currentColor"
            className="bi bi-plus"
            viewBox="0 0 16 16"
          >
            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
          </svg>
          Create issue
        </Typography>
      ) : (
        <CreateBacklogIssue
          isCreateNewBackIssuse={isCreateNewBackIssuse}
          setIsCreateNewBackIssue={setIsCreateNewBackIssue}
          sprint={sprint}
        />
      )}
    </Box>
  );
};
export default BacklogTable;
