import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import { TbArrowBearRight2 } from "react-icons/tb";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import PinIcon from '@mui/icons-material/Pin';
import ClearIcon from "@mui/icons-material/Clear";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import PestControlIcon from '@mui/icons-material/PestControl';
import TaskIcon from '@mui/icons-material/Task';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { TbArrowBigRight } from "react-icons/tb";
import { BsHourglassSplit } from "react-icons/bs";
import InReviewImg from "../assets/images/in-review-img.svg";
import { BsCardChecklist } from "react-icons/bs";
import ProductionReadyImg from "../../src/assets/images/production-ready-img.svg";
import DoneIcon from '../../src/assets/images/DoneIcon.png';

export const Icons = {
  dash: require("../assets/images/dash.png"),
  close: require("../assets/images/close.png"),
  MenuHorizantal: require("../assets/images/three-dots.png"),
  eye: require("../assets/images/eye.png"),
  btnselect: require("../assets/images/btnselect.png"),
  arrowCircleRightOutlined: ArrowCircleRightOutlinedIcon,
  sprintarrow: TbArrowBearRight2,
  alternateEmailIcon: AlternateEmailIcon,
  pinicon: PinIcon,
  clearicon: ClearIcon
};

export const getIssueTypeIcon = (type) => {
  switch (type) {
    case "Story":
      return <div className='issue-type-icon-container' style={{ backgroundColor:'transparent', fontSize: 'inherit !important' }}><BookmarkIcon className='issue-type-icon' sx={{ color: '#F29F05' }} /></div>
    case "Bug":
      return <div className='issue-type-icon-container' style={{ backgroundColor:'transparent' }}><PestControlIcon style={{ color: 'red', fontSize: '20px' }} /></div>
    case "Task":
      return <div className='issue-type-icon-container' style={{ backgroundColor:'transparent' }}><TaskIcon style={{ color: '#0953C3', fontSize: '20px' }} /></div>
    default:
      return <div className='issue-type-icon-container' style={{ backgroundColor:'transparent', fontSize: 'inherit !important' }}><BookmarkIcon className='issue-type-icon' sx={{ color: '#F29F05' }} /></div>
  }
}

export const getPriorityIcon = (type) => {
  switch (type) {
    case "Urgent":
      return <div className='issue-type-icon-container' style={{ backgroundColor:'transparent', fontSize: 'inherit !important' }}><NotificationsActiveIcon className='issue-type-icon' sx={{ color: '#F29F05' }} /></div>
    case "High":
      return <div className='issue-type-icon-container' style={{ backgroundColor:'transparent'}}><KeyboardDoubleArrowUpIcon style={{ color: 'red', fontSize: '20px' }} /></div>
    case "Medium":
      return <div className='issue-type-icon-container' style={{  backgroundColor:'transparent' }}><DragHandleIcon style={{ color: '#0953C3', fontSize: '20px' }} /></div>
    case "Low":
      return <div className='issue-type-icon-container' style={{ backgroundColor:'transparent' }}><KeyboardDoubleArrowDownIcon style={{ color: '#0953C3', fontSize: '20px' }} /></div>
    default:
      return <div className='issue-type-icon-container' style={{  backgroundColor:'transparent', fontSize: 'inherit !important' }}><KeyboardDoubleArrowDownIcon style={{ color: '#0953C3', fontSize: '20px' }} /></div>
  }
}

export const getTaskIcon = (taskTitle) => {
  switch (taskTitle) {
    case "To Do":
      return <TbArrowBigRight color="#391085" />;
    case "In Progress":
      return <BsHourglassSplit color="#4E77B2" />;
    case "Code Review":
      return (
        <img src={InReviewImg} alt="InReviewImg" width="18px" height="18px" />
      );
    case "In Testing":
      return <BsCardChecklist color="#6B54E5" />;
    case "Production Ready":
      return (
        <img
          src={ProductionReadyImg}
          alt="ProductionReadyImg"
          width="18px"
          height="18px"
        />
      );
    case "Done":
      return <img src={DoneIcon} alt="Done" width="18px" height="18px" />;
    default:
      return null;
  }
};