
import React from "react";
import { PieChart, Pie, Label, Tooltip } from "recharts";



const CustomPieChart = ({ completed, totalTickets }) => {

  const remaining = totalTickets - completed;
  const data = [
    { name: "Completed Tickets", value: completed, fill: "#00C49F" },
    { name: "Total Tickets", value: remaining, fill: "#17a2b8" },
  ];

  function CustomLabel({ viewBox, value1 = 0, value2 = 0, value3 = "Tasks Done" }) {
    const { cx, cy } = viewBox;
    const perc = Math.floor((value1 * 100) / value2)
    return (
      <text
        x={cx}
        y={cy}
        fill="#3d405c"
        className="recharts-text recharts-label"
        textAnchor="middle"
        dominantBaseline="central"
      >
        <tspan x={cx} dy="-0.5em" alignmentBaseline="middle" fontSize="26">
          {perc}%
        </tspan>
        <tspan x={cx} dy="1.8em" fontSize="14">
          {value3}
        </tspan>
      </text>
    );
  }

  return (
    <PieChart width={250} height={200}>
      <Pie
        data={data}
        cx={100}
        cy={80}
        innerRadius={60}
        outerRadius={80}
        paddingAngle={0}
        dataKey="value"
      >

        <Label
          width={30}
          position="center"
          content={
            <CustomLabel
              value1={completed}
              value2={totalTickets}
              value3="Tasks Completed"
            />
          }
        ></Label>

      </Pie>
      <Tooltip />

    </PieChart>
  );
};

export default CustomPieChart;




