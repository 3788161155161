import React, { useState } from 'react';
import { Modal, Box, IconButton, Button, Typography, Avatar, ListItemAvatar } from '@mui/material';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import CloseIcon from '@mui/icons-material/Close';
import BackgroundLetterAvatarsLogo from '../AvatarLogo';
import { useEffect } from 'react';
import axios from 'axios';
import API_BASE_URL from '../config/appConfig';
import { useDispatch, useSelector } from 'react-redux';
import { getUserNotifications } from '../../common/Redux/Reducer';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import { jwtDecode } from 'jwt-decode';
import moment from "moment";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';


const truncateText = (text, wordLimit) => {
    const words = text.split(' ');
    if (words.length > wordLimit) {
        return words.slice(0, wordLimit).join(' ') + '...';
    }
    return text;
};

const NotificationComponent = () => {
    const [open, setOpen] = useState(false);
    const [filter, setFilter] = useState('unread');

    const dispatch = useDispatch() ;
    const [usreNotifications , setUserNotifications]=useState([])
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const {user}= useSelector((state) => {
        return state.ProjamAppSlice;
      });
    let userNotifications = user?.notifications

    // let decoded = {}



useEffect(()=>{
    getNotificatoins()
    //   let token = localStorage.getItem('accessToken');
    //   if (token) {
    //     decoded = jwtDecode(token)
    //   }

},[])


const getNotificatoins = async()=>{
try{
    let notifications = await dispatch(getUserNotifications())
}
catch(error){
console.log(error)
}
}

useEffect(()=>{
    filterNotifications()
},[userNotifications , filter])

const filterNotifications = ()=>{
    let decoded={}
    let token = localStorage.getItem('accessToken');
    if (token) {
      decoded = jwtDecode(token)
    }

    if(filter=='unread'){

    let filteredNotifications = userNotifications?.map((notificationobj )=>{
   
    let req=[]
    let filterNotifications =  notificationobj?.receivers?.some((receiverObj)=>{
            if(receiverObj?.receiver == `${decoded?.email}` &&  receiverObj?.read ==  false){
                req.push(notificationobj)
            }
        })   
        
        return req
    })
    filteredNotifications=filteredNotifications?.filter((each)=>each?.length)

    setUserNotifications(filteredNotifications)
}
if(filter=='all'){
  
    let filteredNotifications = userNotifications?.map((notificationobj )=>{
        
        let req=[]
     let filterNotifications =  notificationobj?.receivers?.some((receiverObj)=>{
            if(receiverObj?.receiver == `${decoded?.email}`){
                req.push(notificationobj)
            }
        })   
        
        return req
    })
    filteredNotifications=filteredNotifications?.filter((each)=>each?.length)
    setUserNotifications(filteredNotifications)
}
}


const updateReadStatus = async(id)=>{

    let decoded={}
    let token = localStorage.getItem('accessToken');
    if (token) {
      decoded = jwtDecode(token)
    }

try{
let updateReadStatus = await axios.post(`${API_BASE_URL}/notification/updateReadstatus`, 
    {
        notificationId:id ,
        Email:`${decoded.email}`
    }
 )
}catch(error){
    console.log(error)
}
}



    return (
        <div>
            <NotificationsNoneIcon onClick={handleOpen} sx={{ m: 1, color: 'black' }} />
            <Modal
                open={open}
                onClose={handleClose}
                sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
            >
                <Box className="notification-modal">
                    <Box className="notify-close">
                        <Box>
                            <h5>Notifications</h5>
                        </Box>
                        <Box>
                            {/* <Button style={{borderRadius:'100%', width:'20px'}} onClick={() => setOpen(false)}><CloseIcon fontSize='larger'/></Button> */}
                            <CloseIcon onClick={() => setOpen(false)} fontSize='medium' />
                        </Box>
                    </Box>
                    <Box className='notify-focused' sx={{ mt: 1, mb: 1 }}>
                        <Button
                            onClick={() => setFilter('unread')}
                            variant={filter === 'unread' ? 'contained' : 'outlined'}
                        >
                            UNREAD
                        </Button>
                        <Button
                            onClick={() => setFilter('all')}
                            variant={filter === 'all' ? 'contained' : 'outlined'}
                        // sx={{ marginLeft: 1 }}
                        >
                            All
                        </Button>
                    </Box>
                    {/* <Box>
                        {filteredNotifications.map((notification) => (
                            <Box key={notification.id} sx={{ marginBottom: 1 }}>
                                {notification.message}
                            </Box>
                        ))}
                    </Box> */}
                    <Box sx={{ p: 1 }}>
                        {usreNotifications?.length ? usreNotifications?.map((notification) => (
                           
                         <Box
                                key={notification[0]._id}
                                className='notification-card' onClick={()=>{updateReadStatus(notification[0]._id)}}
                            >
                                {/* <Avatar
                                    alt={notification.userName}
                                    src={notification.userAvatar}
                                    sx={{ width: 45, height: 45, mr: 2 }}
                                /> */}

                                {/* <BackgroundLetterAvatarsLogo
                                    LogoTitle={'UserName'}
                                    RequiredFontSize="13px"
                                /> */}
                                <Avatar>
                                <NotificationsActiveOutlinedIcon />
                                </Avatar>
                                <Box sx={{ flex: 1, ml: 2 }}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            flexDirection:"column",
                                            gap:'1px'
                                        }}
                                    >
                                        
                                        <Box  sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            flexDirection:"row",
                                            gap:'1px'
                                        }}>


                                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                            {notification[0]?.notification?.title}
                                        </Typography>
                                        <Typography  sx={{ fontWeight: '200' , fontSize:"12px" }}>
                                        {moment(notification[0]?.createdAt).format('MM/DD/YYYY hh:mm A')} <span >{(filter=='unread') ? <FiberManualRecordIcon  sx={{ fontSize: 15 }}/> : null}</span>
                                        </Typography>
                                    </Box>

                                        
                                        <Typography variant="body2" color="textSecondary">
                                            {notification[0]?.notification?.body}
                                        </Typography>
                                    </Box>
                                    {/* <Typography variant="body2" color="textSecondary">
                                        {notification.message}
                                    </Typography> */}
                                    {/* <Typography variant="body2" color="textSecondary">
                                        {truncateText(notification?.body, 4)}
                                    </Typography> */}
                                </Box>
                            </Box>
                        )): null}
                    </Box>
                </Box>
            </Modal>
        </div>
    );
};

export default NotificationComponent;
