import React, { useContext, useState, useEffect } from "react";
import Styled from "styled-components";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Avatar, ButtonGroup } from "@mui/material";
import { Modal } from "@mui/material";
import { ModelPageContext } from "../../context/ModelPageContext";
import SelectMenuTextField from "../SelectMenuTextField";
import "./modal.css";
import axios from "axios";
import API_BASE_URL from "../../components/config/appConfig";
import { Form, Formik, Field, ErrorMessage } from "formik";
import TextField from "@mui/material/TextField";
import { ProjectContext } from "../../containers/Dashboard";
import { Icons } from "../../utilities/Icons";
import { colors } from "../../utilities/colors";
import { createProjectSchema } from "../../Validations/schema";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllProjects } from "../../common/Redux/Reducer";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';


const ModalBody = Styled(Box)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    border-radius: 4px; 
    max-height: 90%;
    overflow: auto;
`;

const btnGroupBtnStyles = {
  backgroundColor: "transparent !important",
  border: "0px !important",
  color: "#000000 !important",
};

const IconGroupStyles = {
  backgroundColor: "transparent !important",
  border: "0px !important",
  height: "16px",
  width: "16px",
  cursor: "pointer",
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const ModalPage = (props) => {
  const [projects, setProjects] = useState([]);
  const [issueTypes, setIssueTypes] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [creationFailure, setCreationFailure] = useState('');
  const [success, setSuccess] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [AllUsers, setAllUsers] = useState([]);
  const dispatch = useDispatch();
  const { projectsList, setProjectsList } = useContext(ProjectContext);
  const { Projects } = useSelector((state) => state.ProjamAppSlice);
  const allProjectsList = Projects?.allProjectsList

  const { width = 800, hideBackdrop = false } = props;
  const ModalVerifyState = useContext(ModelPageContext);
  const handleClose = () => {
    ModalVerifyState.setModalState({ open: false, createProject: false });
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  // useEffect(() => {
  //   const fetchIssueTypes = async () => {
  //     try {
  //       const response = await axios.get(`${API_BASE_URL}/issues`);
  //       const mappedIssueTypes = response.data.map((type) => ({
  //         id: type.issuetype.id,
  //         name: type.issuetype.name,
  //       }));
  //       setIssueTypes(mappedIssueTypes);
  //     } catch (error) {
  //       // setError(error.message);
  //     } finally {
  //       // setLoading(false);
  //     }
  //   };

  //   fetchIssueTypes();
  // }, []);

  useEffect(() => {
    if (allProjectsList && allProjectsList?.data?.length > 0) {
      const mappedProjects = allProjectsList?.data?.map((project) => ({
        id: project._id,
        name: project.title,
      }));
      setProjects(mappedProjects);
    }
  }, []);

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    const projectData = {
        title: values.title,
        key: values.key,
        type: values.type,
        lead: values.lead,
    };
    
    try {
        const response = await axios.post(`${API_BASE_URL}/projects/createProject`, projectData); 
        const newProject = {
            id: response.data._id,
            title: response.data.title,
            key: response.data.key,
            type: response.data.type,
            lead: response.data.lead,
        };
        
        setProjectsList([...projectsList, newProject]);
        const projectId = response.data._id;

        const backlogData = {
            projectId: projectId,
        };
        
        await axios.post(`${API_BASE_URL}/backlogs/createBacklog`, backlogData);
        setSnackbarSeverity("success");
        setSnackbarMessage("Project created successfully!");
        setSnackbarOpen(true);

        setTimeout(() => {
            setSnackbarOpen(false);
        }, 1000);

        resetForm();
        handleClose();
        dispatch(fetchAllProjects());
    } catch (error) {
        // Error handling for failure cases
        if (error.response && error.response.data) {
            setSnackbarMessage(error.response.data.message || "An error occurred. Please try again.");
        } else {
            setSnackbarMessage("An error occurred. Please try again.");
        }
        
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
    } finally {
        setSubmitting(false);
    }
};


  useEffect(() => {
    const getAllUsers = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}/users`);
        const userOptions = response.data.users.map((user) => ({
          name: user.name,
          id: user._id,
        }));
        setAllUsers(userOptions);
      } catch (error) {
        console.log(error);
      }
    };

    getAllUsers();
  }, []);

  return (
    <div>
      <Snackbar
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        autoHideDuration={1000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
      <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
      </Alert>
    </Snackbar>
      <Modal
        open={ModalVerifyState.modalState.open}
        hideBackdrop={hideBackdrop}
        onClose={handleClose}
        style={{ overflow: "auto" }}
        className="py-4"
      >
          <ModalBody width={width} maxWidth={"90%"} className="py-4">
            <Formik
              initialValues={{
                title: "",
                key: "",
                type: "",
                lead: "",
              }}
              onSubmit={handleSubmit}
              validationSchema={createProjectSchema}
            >
              {({ isSubmitting }) => (
                <Form>
                  <Box
                    sx={{
                      minWidth: "500px",
                      display: "flex",
                      flexDirection: "column",
                      gap: "18px",
                      maxHeight: "80vh",
                      overflowL: "scroll",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{ fontSize: "18px !important", color: colors.font_Bold, fontWeight: "bold" }}
                      >
                        Create Project
                      </Typography>
                      <Box sx={{ display: "flex", gap: "18px" }}>
                        <Avatar
                          sx={IconGroupStyles}
                          alt="Remy Sharp"
                          src={Icons.dash}
                        />
                        <Avatar
                          sx={IconGroupStyles}
                          alt="Remy Sharp"
                          src={Icons.close}
                          onClick={handleClose}
                        />
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography sx={{ fontSize: "12px" }}>
                        Required fields are marked with an asterisk
                        <span style={{ color: colors.required_Star_Color }}>
                          *
                        </span>
                      </Typography>
                    </Box>
                    <div className="row">
                      <div className="col-sm">
                        <Field name="title">
                          {({ field }) => (
                            <TextField
                              {...field}
                              label="Title"
                              variant="outlined"
                              fullWidth
                              className="search-bar my-2"
                              size="small"
                            />
                          )}
                        </Field>
                        <ErrorMessage name="title">
                          {(msg) => (
                            <Typography
                              variant="body2"
                              color="error"
                              className="error-message text-danger mx-1"
                              style={{ fontSize: "12px" }}
                            >
                              {msg}
                            </Typography>
                          )}
                        </ErrorMessage>
                      </div>
                      <div className="col-sm">
                        <Field name="key">
                          {({ field }) => (
                            <TextField
                              {...field}
                              label="Key"
                              variant="outlined"
                              fullWidth
                              className="search-bar my-2"
                              size="small"
                            />
                          )}
                        </Field>
                        <ErrorMessage name="key">
                          {(msg) => (
                            <Typography
                              variant="body2"
                              color="error"
                              className="error-message text-danger mx-1"
                              style={{ fontSize: "12px" }}
                            >
                              {msg}
                            </Typography>
                          )}
                        </ErrorMessage>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm">
                        <Field name="type">
                          {({ field }) => (
                            <TextField
                              {...field}
                              label="Type"
                              variant="outlined"
                              fullWidth
                              className="search-bar my-2"
                              size="small"
                            />
                          )}
                        </Field>
                      </div>
                      <div className="col-sm mt-2">
                        <Field name="lead">
                          {({ field, form }) => (
                            <SelectMenuTextField
                              labelText="Lead"
                              options={AllUsers}
                              value={field.value}
                              onChange={(event) =>
                                form.setFieldValue("lead", event.target.value)
                              }
                              onBlur={field.onBlur}
                            />
                          )}
                        </Field>
                        <ErrorMessage name="lead">
                          {(msg) => (
                            <Typography
                              variant="body2"
                              color="error"
                              className="error-message text-danger mx-1"
                              style={{ fontSize: "12px" }}
                            >
                              {msg}
                            </Typography>
                          )}
                        </ErrorMessage>
                      </div>
                    </div>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        paddingBottom: "24px",
                      }}
                    >
                      {/* <Typography
                        sx={{
                          color: colors.font_Bold,
                          fontWeight: "bold",
                          fontSize: "14px",
                        }}
                      >
                        <Checkbox /> Create another project
                      </Typography> */}
                      <Typography></Typography>
                      <ButtonGroup
                        variant="contained"
                        aria-label="outlined button group"
                        className="shadow-none d-flex justify-content-between align-items-center gap-3"
                      >
                        <Button
                          sx={{
                            backgroundColor: `transparent !important`,
                            border: `1.5px solid ${colors.primary} !important`,
                            color: `${colors.primary} !important`,
                            borderRadius: "5px  !important",
                            fontSize: "10px !important",
                            textTransform: "capitalize",
                          }}
                          className="px-3"
                          type="button"
                          onClick={handleClose}
                        >
                          Close
                        </Button>
                        <Button
                          sx={{
                            backgroundColor: `${colors.primary} !important`,
                            border: "0px !important",
                            color: "#FFFFFF !important",
                            borderRadius: "5px  !important",
                            fontSize: "10px !important",
                            textTransform: "capitalize",
                          }}
                          className="px-3"
                          type="submit"
                        >
                          Submit
                        </Button>
                      </ButtonGroup>
                    </Box>
                  </Box>
                </Form>
              )}
            </Formik>
          </ModalBody>
      </Modal>
    </div>
  );
};

export default ModalPage;
