import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import API_BASE_URL from "../../../components/config/appConfig";
import { jwtDecode } from "jwt-decode";

const initialState = {
  loader: false,
  Skeleton: false,
  assigneeLayoutSkeleton: false,
  Projects: {
    allProjectsList: [],
    selectedProject: {
      selectedProjectId: "",
      selectedProjectBackLogId: "",
      projectSlug:'',
      sprintsList: [],
      backlogIssuesList: [],
      ActiveSprint: {},
      selectedProjectData: '',
      contributors: [],
      activeSprintAssigneesLayoutData: [],
      lastFiveSprintsInfo:[]

    },
  },
  user: {
    id: "",
    details: "",
    notifications:[]
  },
  assignees: [],
  isUserVerify: true,
  snackbarOpen: false,
  snackbarMessage: '',
};
const token = localStorage.getItem("accessToken");
let decoded = ''
if (token) {
  decoded = jwtDecode(token);
}


export const getUserVerify = createAsyncThunk(
  "getUserVerify",
  async ({ headers, navigate }, { dispatch, rejectWithValue }) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/user-very`, { headers });
      if (response?.data?.status === 401) {
        // Redirect to login
        localStorage.removeItem("accessToken")
        dispatch(showSnackbar('Unauthorized access. Please log in.'));
        navigate('/login');
      }
      return response.data;
    } catch (error) {
      if (error?.response?.data?.status === 401) {
        // Redirect to login
        localStorage.removeItem("accessToken")
        dispatch(showSnackbar(error?.response?.data?.message || 'Unauthorized access. Please log in.'));
        navigate('/login');
      }
      return rejectWithValue(error.response?.data);
    }
  }
);


export const getUserNotifications = createAsyncThunk(

  'getNotifications' , 
  async ()=>{
    let decoded = {}
    try{
      let token = localStorage.getItem('accessToken');
      if (token) {
        decoded = jwtDecode(token)
      }

      let notificatinons = await axios.get(`${API_BASE_URL}/notification`)
      return notificatinons?.data

    }catch(error){
        console.log(error)
    }
  }
)



export const fetchAllProjects = createAsyncThunk(
  'projamAppSlice/fetchAllProjects',
  async (headers) => {
    let decoded = {}
    try {
      let token = localStorage.getItem('accessToken');
      if (token) {
        decoded = jwtDecode(token)
      }
      const response = await axios.get(`${API_BASE_URL}/projects?userid=${decoded?.id}`, { headers });
      if (response.status === 401 || response.status === 500) {
        throw new Error(response.status);
      }
      return response;
    } catch (error) {
      throw new Error(error.message);
    }
  }
);

export const getActiveProjectData = createAsyncThunk(
  "getActiveProjectData",
  async (projectslug = "") => {
    let token = localStorage.getItem('accessToken');
    const response = await axios(
      `${API_BASE_URL}/projects/getProjectsByslug/${projectslug}` , {headers:{
        'Authorization':`Bearer ${token}`
      }}
    );
    return response?.data;
  }
);




export const getBacklogIssues = createAsyncThunk(
  "getBacklogIssues",
  async (BacklogId) => {
    let response = await axios(
      `${API_BASE_URL}/issues/withoutSprint?backlogId=${BacklogId}`
    );
    return response?.data;
  }
);

export const createIssue = createAsyncThunk("createIssue", async (Data) => {
  let token = localStorage.getItem('accessToken');
  if (token) {
    decoded = jwtDecode(token)
  }
  let response = await axios.post(`${API_BASE_URL}/issues/createIssue?userid=${decoded?.id}`, Data);
  return response?.data;
});

export const updateIssue = createAsyncThunk("updateIssue", async ({ issueId, payload }) => {
  const token = localStorage.getItem("accessToken");
let decoded = ''
if (token) {
  decoded = jwtDecode(token);
}
  let response = await axios.put(`${API_BASE_URL}/issues/updateIssue/${issueId}?userid=${decoded?.id}`, payload)
  return response?.data;
});

export const updateSprint = createAsyncThunk("updateSprint", async ({ sprintId, payload }) => {
  let response = await axios.put(`${API_BASE_URL}/sprints/updateSprint/${sprintId}`, payload);
  return response?.data;
}
);

export const getSprintsList = createAsyncThunk(
  "sprints/getSprintsList",
  async ({ backlogId = "", filterAssigneesArray = [] }) => {
    const response = await axios(
      `${API_BASE_URL}/sprints/getSprintsExcludingCompleted?backlogId=${backlogId}&assignees=${filterAssigneesArray.join(",")}`
    );
  
    return response?.data;
  }
);

export const getActiveSprintAssigneesLayoutData = createAsyncThunk(
  "getActiveSprintAssigneesLayoutData",
  async ({ activeSprintId = "", payload = {} }) => {
    const response = await axios.post(`${API_BASE_URL}/sprints/groupbyassigne/${activeSprintId}`, payload);
    return response?.data;
  }
);

export const getLastFiveActiveSprintInfo = createAsyncThunk(
  "getLastFiveActiveSprintInfo" , 
  async({backlogId,projectId} )=>{
    const response = await axios(`${API_BASE_URL}/sprints/lastFiveActive?backlogId=${backlogId}&projectId=${projectId}`);
    return response?.data
  }
)


const ProjamAppSlice = createSlice({
  name: "projamAppSlice",
  initialState,
  reducers: {
    setSelectedProjectBackLogId: (state, action) => {
      state.Projects.selectedProject.selectedProjectBackLogId =
        action.payload.id;
    },
    selectedProjectSlug:(state, action)=>{
      state.Projects.selectedProject.projectSlug = action.payload.slug
    },
    setLoaderStatus: (state, action) => {
      state.loader = action.payload.loader;
    },
    setSkeletonStatus: (state, action) => {
      state.Skeleton = action.payload.Skeleton;
    },
    setFilterAssignees: (state, action) => {
      if (state?.assignees?.length > 0) {
        const index = state?.assignees?.indexOf(action.payload);
        if (index > -1) {
          const updatedFilter = state?.assignees?.filter((eachPerson) => {
            return eachPerson !== action.payload;
          })
          state.assignees = updatedFilter
        } else {
          state.assignees = [...state.assignees, action.payload]
        }
      } else {
        state.assignees = [...state.assignees, action.payload]
      }
    },
    setClearFilterAssignees: (state, action) => {
      state.assignees = []
    },
    showSnackbar: (state, action) => {
      state.snackbarOpen = true;
      state.snackbarMessage = action.payload;
    },
    hideSnackbar: (state) => {
      state.snackbarOpen = false;
      state.snackbarMessage = '';
    },
    setSelectedProjectData: (state, action) => {
      state.Projects.selectedProject.selectedProjectData = action.payload;
      state.Projects.selectedProject.contributors = action.payload?.contributors;
    },
    clearSprintsListAndActiveSprintData: (state, action) => {
      state.Projects.selectedProject.ActiveSprint = {};
      state.Projects.selectedProject.sprintsList = [];
    },
    SetlastfiveActiveSprintInfo:(state, action)=>{
      state.Projects.selectedProject.lastFiveSprintsInfo = action.payload
    }
  },

  extraReducers: (builder) => {
    // Get User Verify builder
    builder
      .addCase(getUserVerify.pending, (state) => {
        // state.loader = true;
      })
      .addCase(getUserVerify.fulfilled, (state, action) => {
        // state.loader = false;
      })
      .addCase(getUserVerify.rejected, (state, action) => {
        // state.loader = false;
      });

    // Get ALL Projects Data builder
    builder
      .addCase(fetchAllProjects.pending, (state) => {
        state.status = 'loading';
        // state.loader = true;
      })
      .addCase(fetchAllProjects.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.Projects.allProjectsList = action.payload;
        // state.loader = false;
      })
      .addCase(fetchAllProjects.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
        // state.loader = false;
      });

    builder.addCase(getActiveProjectData.pending, (state, action) => {
      // state.loader = true;
    });
    builder.addCase(getActiveProjectData.fulfilled, (state, action) => {
      state.Projects.selectedProject.selectedProjectData = action.payload;
      state.Projects.selectedProject.contributors = action.payload?.contributors;
      // state.loader = false;
    });

    // Get Active Sprint Assignees Layout Data builder
    builder.addCase(getActiveSprintAssigneesLayoutData.pending, (state, action) => {
      state.assigneeLayoutSkeleton = true;
    });
    builder.addCase(getActiveSprintAssigneesLayoutData.fulfilled, (state, action) => {
      state.Projects.selectedProject.activeSprintAssigneesLayoutData = action.payload;
      state.assigneeLayoutSkeleton = false;
    });

    // Get Sprints List builder
    builder.addCase(getSprintsList.pending, (state, action) => {
      state.Skeleton = true;
    });
    builder.addCase(getSprintsList.fulfilled, (state, action) => {
      const getActiveSprint = action.payload?.filter(
        (eachSprint) => eachSprint.active === true
      );
      if (getActiveSprint?.length > 0) {
        state.Projects.selectedProject.ActiveSprint = getActiveSprint[0];
      } else {
        state.Projects.selectedProject.ActiveSprint = {};
      }
      state.Projects.selectedProject.sprintsList = action.payload;
      state.Skeleton = false;
    });

    // Update Sprint builder
    builder.addCase(updateSprint.pending, (state, action) => {
      // state.loader = true;
    });
    builder.addCase(updateSprint.fulfilled, (state, action) => {
      // state.loader = false;
    });
    builder.addCase(updateSprint.rejected, (state, action) => {
      // state.loader = false;
    });

    // Create Issues builder
    builder.addCase(createIssue.pending, (state, action) => {
      // state.loader = true;
    });
    builder.addCase(createIssue.fulfilled, (state, action) => {
      // state.loader = false;
    });


    // Update Issues builder
    builder.addCase(updateIssue.pending, (state, action) => {
      // state.loader = true;
    });
    builder.addCase(updateIssue.fulfilled, (state, action) => {
      // state.loader = false;
    });

    // Get Backlog Issues builder
    builder.addCase(getBacklogIssues.pending, (state, action) => {
      // state.loader = true;
    });
    builder.addCase(getBacklogIssues.fulfilled, (state, action) => {
      state.Projects.selectedProject.backlogIssuesList = action.payload;
      // state.loader = false;
    });
     // lastFiveActiveSprint
     builder
     .addCase(getLastFiveActiveSprintInfo.pending, (state) => {
       state.Projects.selectedProject.lastFiveSprintsInfo = [];
     })
     .addCase(getLastFiveActiveSprintInfo.fulfilled, (state, action) => {
       state.Projects.selectedProject.lastFiveSprintsInfo = action.payload;
     })
     .addCase(getLastFiveActiveSprintInfo.rejected, (state, action) => {
       console.error('Error fetching sprint info:', action.error);
       state.Projects.selectedProject.lastFiveSprintsInfo = []
     });

     //user notifications
builder.addCase(getUserNotifications.pending , (state)=>{
  state.user.notifications=[]
})
.addCase(getUserNotifications.fulfilled , (state , action)=>{
  state.user.notifications=action.payload
})
.addCase(getUserNotifications.rejected , (state , action)=>{
  state.user.notifications=[]
})


    builder.addMatcher(getBacklogIssues.settled, (state, action) => { });
   

  },
});

export const {
  setSelectedProjectBackLogId,
  selectedProjectSlug,
  setLoaderStatus,
  setFilterAssignees,
  setClearFilterAssignees,
  setSkeletonStatus,
  showSnackbar, hideSnackbar,
  setSelectedProjectData,
  clearSprintsListAndActiveSprintData ,
  SetlastfiveActiveSprintInfo
} = ProjamAppSlice.actions;
export default ProjamAppSlice.reducer;
