import React, { useState, useEffect, useContext } from 'react';
import { Box, Button, Typography } from '@mui/material';
import JiraProjectsTable from '../../../components/JiraProjectsTable';
import Footer from '../../../components/Footer';
import SearchTextField from '../../../components/SearchTextField';
import { ModelPageContext } from '../../../context/ModelPageContext';
import CreateBacklogIssue from '../../../components/Create_Backlogissue';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { showSnackbar } from "../../../common/Redux/Reducer";
import { notificationSubscription } from '../../../services/notificationSubscription';

const Index = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedProject, setSelectedProject] = useState('');
  const [loading, setLoading] = useState(false);
  const modalVerifyState = useContext(ModelPageContext);
  const [issueModel, setIssueModel] = useState(false);
  const navigate = useNavigate();
  const { Projects, error } = useSelector((state) => state.ProjamAppSlice);
  const [selectedProjectIds, setSelectedProjectIds] = useState([])
  const dispatch = useDispatch();
  const allProjectsList = Projects?.allProjectsList
  const handleSearch = (text) => {
    setSearchTerm(text);
  };

  const onChangeModalVerifyState = () => {
    modalVerifyState.setModalState({ open: true, createProject: true });
  };

  useEffect(() => {
    notificationSubscription()
    if (error) {
      if (error === '401' || error === '500') {
        localStorage.removeItem('accessToken');
        navigate('/login');
      }
    }
  }, [error, navigate]);

  const handleViewReports = () => {
    console.log(selectedProjectIds, "SelectedProjectIds");
    if (selectedProjectIds && selectedProjectIds.length > 0) {
      navigate('/projects/viewreports', { state: { projectIds: selectedProjectIds } });
    } else {
      dispatch(showSnackbar("Please select projects.."));
    }
  };

  

  const filteredProjects = selectedProject
    ? allProjectsList?.data.filter(project => project.id === selectedProject)
    : allProjectsList?.data || [];

  return (
    <Box>
      <div style={{ backgroundColor: '#F9F8F7' }} className='pb-3'>
        <Box className='d-flex justify-content-between align-items-center p-4 mb-1' sx={{ boxShadow: "0px 1px 1px 0px #00000029;", backgroundColor: '#FFFFFF' }}>
          <Typography sx={{ fontSize: "18px !important", fontWeight: 800, color: '#262626', lineHeight: '24px', marginLeft: "24px" }} >Projects</Typography>
          <div>
            <Button variant='contained' className='px-4 bg-contained-btn-cls' onClick={onChangeModalVerifyState}>Add new project</Button>
          </div>
        </Box>
        <div style={{ backgroundColor: '#FFFFFF', borderRadius: '16px' }} className='p-4 m-4 shadow-sm'>
          <Box className='d-flex align-items-center'>
            <SearchTextField searchTerm={searchTerm} handleSearch={handleSearch} />
            {selectedProjectIds.length > 0 && (
              <Button
                variant='contained'
                className={`ms-3 px-4 bg-contained-btn-cls`}
                onClick={handleViewReports}
              >
                View Reports
              </Button>
            )}
          </Box>
          <Box>
            <JiraProjectsTable setSelectedProjectIds={setSelectedProjectIds} searchTerm={searchTerm} selectedProject={selectedProject} projects={filteredProjects} loading={loading} />
          </Box>
        </div>
      </div>
      <Footer />
      {issueModel && <CreateBacklogIssue isCreateNewBackIssuse={issueModel} setIsCreateNewBackIssue={setIssueModel} />}
    </Box>
  );
};

export default Index;
