import React from 'react';
import { Box } from '@mui/material';
import ProjectAccessControl from '../../../../components/ProjectAccessControl';


const ProjectSettings = ()  => {
  return (
    // <Box 
    
    // height="100vh" display="flex" flexDirection="column">
     <ProjectAccessControl/>
    // </Box>
  );
}

export default ProjectSettings;
