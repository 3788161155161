import  React , {useEffect, useState} from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

import axios from 'axios';
import API_BASE_URL from '../../components/config/appConfig';
import moment from 'moment';
import { useSelector, useDispatch } from "react-redux";

export default function SprintAnalysis() {

  const [data, setdata]=useState([])
  const  [shouldRotateLabels,setShouldRotateLables]=useState(false)
  const { Skeleton,Projects } = useSelector((state) => state.ProjamAppSlice)
  let backlogId = Projects?.selectedProject?.selectedProjectBackLogId
  let projectId = Projects?.selectedProject?.selectedProjectData?._id
  let LastFiveActiveSprint = Projects?.selectedProject?.lastFiveSprintsInfo || []

  useEffect(()=>{
    if(projectId && backlogId && LastFiveActiveSprint.length>0){
      getLastFiveActiveSprints()
    }
  },[projectId,backlogId ,LastFiveActiveSprint])

const getLastFiveActiveSprints=async ()=>{
  try{
   //let response = await axios(`${API_BASE_URL}/sprints/lastFiveActive?backlogId=${backlogId}&projectId=${projectId}`)
    let response = [...LastFiveActiveSprint]
    //console.log(LastFiveActiveSprint , 'last five sprint')
    getSprintAnalysisData(response?.reverse())
  }catch(error){
    console.log(error)
  }
}

  
  const getDuration = async(sprintInfo)=>{
  let startDate=moment(sprintInfo?.startDate);
  let endDate=moment(sprintInfo?.endDate);
  return endDate.diff(startDate, "days")/7;
  }
  
  const getSprintAnalysisData=async(data)=>{
  let barGraphData = await Promise.all(data.map(async(each)=>{
      let weeks= await getDuration(each)
      let sprintName= each?.name
      return {sprintName:sprintName , weeks:weeks}
  }))
  setdata(barGraphData)
   setShouldRotateLables(data.some((item) => item?.name?.length > 15))
  }

  return (
    <BarChart
      width={500}
      height={385}
      data={data}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
     <CartesianGrid  strokeDasharray='3'
    horizontal={true} 
    vertical={false}
      /> 
      <XAxis
        dataKey="sprintName"
        angle={shouldRotateLabels ? -30 : 0}
        textAnchor={shouldRotateLabels ? "end" : "middle"}
        interval={0}
        height={shouldRotateLabels ? 70 : 30}
        tick={{ fontSize: 10 }}
      />
      <YAxis tickFormatter={(value) => `${value} weeks`}
        allowDecimals={false} />
      <Tooltip />
      <Bar dataKey="weeks" fill="#82ca9d" />
    </BarChart>
  );
}
