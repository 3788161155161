import React from 'react';
import Box from '@mui/material/Box';
// import IconButton from '@mui/material/IconButton';
// import CloseIcon from '@mui/icons-material/Close';
import { TextField, Button, Typography } from '@mui/material';
import CKTextEditor from '../CK-Text-Editor/index';
import "../Modal/modal.css";

const Index = ({ testCases, isEditingTestCases = false, handleBoxClick, handleTestCasesHover, setIsTestCasesHovered, setIsEditingTestCases, isTestCasesHovered, applyImageStyles, handleTestCasesChange, handleUpdateTestCases, disabledBtn, handleCloseEditor }) => {
    return (
        <div className='test-cases-container mt-3'>
            <Typography sx={{ fontWeight: 600, }}>Test Cases</Typography>

            {!testCases && !isEditingTestCases && (
                <TextField
                    onClick={(event) => handleBoxClick(event, true)}
                    onMouseEnter={handleTestCasesHover}
                    onMouseLeave={() => setIsTestCasesHovered(false)}
                    variant="outlined"
                    placeholder="Add a Testcase"
                    style={{
                        backgroundColor: isTestCasesHovered ? 'lightgray' : 'transparent',
                    }}
                    className="textfield_Description"
                    onFocus={() => setIsEditingTestCases(true)}
                />
            )}
            {(testCases && !isEditingTestCases) && (
                <Box
                    onClick={(event) => handleBoxClick(event, true)}
                    className="test-cases-editable-box p-2"
                    onMouseEnter={handleTestCasesHover}
                    onMouseLeave={() => setIsTestCasesHovered(false)}
                    style={{
                        backgroundColor: isTestCasesHovered ? '#F0F7EB' : 'transparent',
                        width: '90%',
                        height: '30%',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        objectFit: 'contain',
                        cursor: "pointer",
                        border: isTestCasesHovered ? "1px solid #60A630" : '',
                        borderRadius: isTestCasesHovered ? "5px" : '',


                    }}
                    dangerouslySetInnerHTML={{ __html: applyImageStyles(testCases) }}

                />
            )}

            {isEditingTestCases && (
                <Box>
                    <CKTextEditor
                        payloadText={testCases}
                        onChange={handleTestCasesChange}
                    />
                    <Button
                        size="small"
                        variant="contained"
                        className="task_Work-button_save text-white mt-3"
                        onClick={handleUpdateTestCases}
                        disabled={disabledBtn}
                    >
                        {disabledBtn ? 'Saving...' : 'Save'}
                    </Button>
                    <Button
                        size="small"
                        variant="outlined"
                        color="error"
                        className="task_Work-button_cancel mt-3 ms-3"
                        onClick={handleCloseEditor}
                    >
                        Cancel
                    </Button>
                </Box>
            )}
        </div>
    )
}

export default Index