import React, { useEffect, useState } from "react";
import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import SelectMenuTextField from "../SelectMenuTextField";
import axios from "axios";
import { getBacklogIssues, getLastFiveActiveSprintInfo, getSprintsList, SetlastfiveActiveSprintInfo, updateSprint } from "../../common/Redux/Reducer";
import API_BASE_URL from "../config/appConfig";
// import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import EndSprint from "../../assets/images/end_sprint.jpg"
import PendingSprintTasks from "../../assets/images/pending_sprint_task.jpg"

const DeleteConfirmationModal = ({ open, handleClose, handleConfirm, setOpenDeleteDialog, description, title, btnText, buttonStyles, activeSprint, AllsprintDetails = [], isCompleteConfirm = false , isActiveSprintExist,setIsActiveSprintExist=()=>{} }) => {
    const [sprintId, setSprintId] = useState(null);
    const [issuesSelected, setIssuesSelected] = useState([]);

    const dispatch = useDispatch();

    const filterAssigneesArray = useSelector((state) => state.ProjamAppSlice.assignees);
    const filteredSprintDetails = AllsprintDetails.filter(sprint => !sprint.active);
    const { Projects } = useSelector((state) => state.ProjamAppSlice)
 

  let backlogId = Projects?.selectedProject?.selectedProjectBackLogId
  let projectId = Projects?.selectedProject?.selectedProjectData?._id

    useEffect(() => {
        if (activeSprint && activeSprint.issues) {
            const issueIds = activeSprint.issues.filter((issue) => issue.status !== "Done").map((issue) => issue._id);
            setIssuesSelected(issueIds);
        }
    }, [activeSprint]);

    const handleMoveIssuesToSprint = async (SprintId) => {
        if (SprintId) {
            try {
                const response = await axios.put(`${API_BASE_URL}/issues/moveto/sprint/${SprintId}`, {
                    selectedIssues: issuesSelected,
                });
               
               await  dispatch(updateSprint({ sprintId: activeSprint?._id, payload: { active: false, completed: true } }));
               await dispatch(getSprintsList({ backlogId: backlogId, filterAssigneesArray }));
               await dispatch(getBacklogIssues(backlogId));
               dispatch(getLastFiveActiveSprintInfo({backlogId , projectId})) 
               setOpenDeleteDialog(false);
             setIsActiveSprintExist(false)
                
            } catch (error) {
                console.error('Error moving issues to new sprint:', error);
            }
        }
    };
    const handleCompleteprint = async () => {
        if (sprintId) {
            if (sprintId === "123") {
                await CreateNewSprint();
            } else {
                await handleMoveIssuesToSprint(sprintId);
               
            }
          let response =  dispatch(getLastFiveActiveSprintInfo({backlogId , projectId})) 
          dispatch(SetlastfiveActiveSprintInfo(response))
        }
    };

    const CreateNewSprint = async () => {
        setOpenDeleteDialog(false);
        try {
            const response = await axios.post(`${API_BASE_URL}/sprints/createSprint`, {
                backlog: backlogId,
            });
            const newSprintId = response.data._id;
            if (newSprintId) {
               // dispatch(getSprintsList({ backlogId: backlogId, filterAssigneesArray }));
                setSprintId(newSprintId);
                await handleMoveIssuesToSprint(newSprintId);
            } else {
                console.error("New sprint ID is not available in the response.");
            }
        } catch (error) {
            console.error("Error creating new sprint:", error);
        }
    };

    const getTotalIssueCount = (issueCountObj) => {
        return (Object.values(issueCountObj).reduce((total, count) => total + count, 0)) - issueCountObj['Done']
    };

    const handleSelectSprintChange = (e) => {
        const selectedValue = e.target.value;
        setSprintId(selectedValue);
    };

    const openIssuesCount = activeSprint?.issues?.filter(issue => issue.status !== 'Done').length;

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
                sx: {
                    width: '30%',
                    maxWidth: 'none',
                },
            }}
        >
            {!isCompleteConfirm ? <DialogTitle id="alert-dialog-title">{title}</DialogTitle> : null}
            <DialogContent>
                {!isCompleteConfirm ?
                    <DialogContentText id="alert-dialog-description">
                        {description}
                    </DialogContentText>
                    : null}
                {isCompleteConfirm && activeSprint && Object.keys(activeSprint)?.length > 0 ? <>
                    <DialogTitle id="complete-sprint-dialog-title" sx={{ textAlign: 'center', padding: '24px 16px', backgroundColor: '#F0F7EB', borderRadius: '8px' }}>
                        {openIssuesCount > 0 ? (
                            <img src={PendingSprintTasks} alt='App-Title-Logo' height={150} width={230} className='me-1' style={{ borderRadius: '8px' }} />
                        ) : (
                            <img src={EndSprint} alt='App-Title-Logo' height={150} width={230} className='me-1' style={{ borderRadius: '8px' }} />
                        )}
                    </DialogTitle>
                    <DialogTitle id="alert-dialog-title" sx={{ fontSize: '17px' }}> Complete {activeSprint?.name}</DialogTitle>
                    {openIssuesCount > 0 ? (
                        <Box sx={{ marginLeft: '30px' }}>
                            <p>This sprint contains</p>
                            <Box className="align-items-center" sx={{ marginLeft: '30px' }}>
                                <ul className="px-0">
                                <li><strong>{activeSprint?.IssuecountObj['Done']}</strong> Completed Issues</li>
                                <li><strong>{getTotalIssueCount(activeSprint?.IssuecountObj)}</strong> Open Issues</li>
                                </ul>
                            </Box>
                            <div className="align-items-center mt-3">
                                <span className="me-2 mb-3">Move open issues to</span>
                            </div>
                            <Box sx={{ minWidth: "160px" }}>
                                <SelectMenuTextField
                                    labelText="Select Sprint"
                                    options={[...filteredSprintDetails, { _id: '123', name: "New Sprint", value: '123' }]}
                                    onChange={handleSelectSprintChange}
                                />
                            </Box>
                            <DialogActions>
                                <Button size="small" onClick={handleClose} color="success">
                                    Cancel
                                </Button>
                                <Button size="small" onClick={handleCompleteprint} sx={buttonStyles} autoFocus className="px-2">
                                    Complete Sprint
                                </Button>
                            </DialogActions>
                        </Box>
                    ) : (
                        <>
                            <Box sx={{ marginLeft: '30px' }}>
                                <p>This Sprint containes {activeSprint?.IssuecountObj['Done']} Completed Issues.</p>
                                <p>That's all them - Well Done</p>
                                <DialogActions>
                                    <Button size="small" onClick={handleClose} color="success">
                                        Cancel
                                    </Button>
                                    <Button size="small" onClick={handleConfirm} sx={buttonStyles} autoFocus>
                                        Complete Sprint
                                    </Button>
                                </DialogActions>
                            </Box>
                        </>
                    )}
                </> : null}
            </DialogContent>
            {!isCompleteConfirm ?
                <DialogActions>
                    <Button size="small" onClick={handleClose} color="success">
                        No
                    </Button>
                    <Button size="small" onClick={handleConfirm} sx={buttonStyles} autoFocus>
                        {btnText}
                    </Button>
                </DialogActions>
                : null}
        </Dialog>
    );
};

export default DeleteConfirmationModal;
