import React , {useEffect} from "react";
import GroupAvatars from "../AvatarGroupLogos";
import SelectMenuItems from "../SelectMenuItems";
import SearchTextField from "../SearchTextField";
import { Box } from "@mui/material";
import { taskPriorityList, taskTypesList } from "../../utilities/Json";
import { useDispatch, useSelector } from "react-redux";
import { getSprintsList, setClearFilterAssignees } from "../../common/Redux/Reducer";
import { NormalSkeleton, CircularSkeleton } from "../../common/Skeleton";

const BacklogHeader = ({ setOpenAddPeopleModel = () => { }, searchTerm = '', handleSearch = () => { }, handleStatuUpdate = () => { }, selectedTaskType = '', handlePriorityUpdate=() =>{}, selectPriorityType = '' }) => {

  const dispatch = useDispatch();
  const { assignees, Skeleton } = useSelector((state) => state.ProjamAppSlice);

  const handleClearFilters = () => {
    handleSearch("")
    handleStatuUpdate("")
    handlePriorityUpdate("")
    setOpenAddPeopleModel(false);
    if (assignees?.length > 0) {
      dispatch(setClearFilterAssignees());
    }
  }
  const [menuTitle, setMenuTitle] = React.useState("Type");
  const [priorityTitle, setPriorityTitle] = React.useState("Priority")

  useEffect(() => {
    if (selectedTaskType.length === 0) {
      setMenuTitle("Type");
    } else {
      setMenuTitle(selectedTaskType);
    }
    if (selectPriorityType.length === 0) {
      setPriorityTitle("Priority");
    } else {
      setPriorityTitle(selectPriorityType);
    }
  }, [selectedTaskType, selectPriorityType]);  

  return (
    <Box className="d-flex justify-content-between align-items-center ps-2 pe-3 my-1">
      <Box className="d-flex align-items-center">
        {Skeleton ? <NormalSkeleton variant='rectangular' width={150} height={35} /> : <SearchTextField searchTerm={searchTerm} handleSearch={handleSearch} />}
        {Skeleton ? <CircularSkeleton /> : <GroupAvatars setOpenAddPeopleModel={setOpenAddPeopleModel} />}
        {Skeleton ? <NormalSkeleton variant='rectangular' width={50} height={25} /> : <SelectMenuItems title="Epic" backGroundColor="#E3E4E9" />}
        {Skeleton ? <NormalSkeleton variant='rectangular' width={50} height={25} /> : <SelectMenuItems title={menuTitle} ItemsList={taskTypesList} handleStatuUpdate={handleStatuUpdate} backGroundColor="#E3E4E9" />}
        {Skeleton ? <NormalSkeleton variant='rectangular' width={50} height={25} /> : <SelectMenuItems title={priorityTitle} ItemsList={taskPriorityList} handlePriorityUpdate={handlePriorityUpdate} backGroundColor="#E3E4E9" />}
        {(assignees?.length > 0 || searchTerm || selectedTaskType || selectPriorityType) ? <button className="clear-filters-btn-cls" onClick={handleClearFilters}>Clear filters</button> : null}
      </Box>
    </Box>
  );
};

export default BacklogHeader;
