import React from 'react'
import { NavLink } from 'react-router-dom'

const index = () => {
    return (
        <div className='d-flex flex-column align-items-center justify-content-center' style={{ minHeight: '50vh' }}>
            <h4>404 - Page Not Found</h4>
            <NavLink to='/'>
                <button className='btn btn-primary px-5 my-4'>Back To Home</button>
            </NavLink>

        </div>
    )
}

export default index