import React from 'react';
import AppRoots from './AppRoots';
import ModelPageContextProvider from './context/ModelPageContext';
import Loader from './common/loader/Loader';
import SnackbarComponent from './common/snackbars';
import './App.css';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";

function App() {
  return (
    <ModelPageContextProvider>
      <Loader>
        <AppRoots />
        <SnackbarComponent />
      </Loader> 
    </ModelPageContextProvider>
  );
}

export default App;
