import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  TextField,
  Button,
  Box,
  ButtonGroup,
  Avatar,
  DialogActions,
} from "@mui/material";
import { Formik, Form, ErrorMessage } from "formik";
import { sprintSchema } from "../../Validations/schema";
import { DurationList } from "../../utilities/Json";
import SelectMenuTextField from "../SelectMenuTextField";
import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import Textarea from "@mui/joy/Textarea";
import { colors } from "../../utilities/colors";
import axios from "axios";
import API_BASE_URL from "../config/appConfig";
import { useSelector, useDispatch } from "react-redux";
import { getLastFiveActiveSprintInfo, getSprintsList } from "../../common/Redux/Reducer";
import { showSnackbar } from "../../common/Redux/Reducer";
import { Icons } from "../../utilities/Icons";

const IconGroupStyles = {
  backgroundColor: "transparent !important",
  border: "0px !important",
  height: "16px",
  width: "16px",
  cursor: "pointer",
};


const SprintStartModal = ({
  setOpenModal,
  sprintData,
  isSprintEditable,
  setIsSprintEditable=()=>{}
}) => {
  const [startDate, setStartDate] = useState(new Date());
  const [duration, setDuration] = useState(sprintData?.duration ? sprintData?.duration :"2 week");
  const estimatedEndDate= new Date(startDate);
  estimatedEndDate.setDate(estimatedEndDate.getDate() + parseInt(2) * 7);
  const [endDate, setEndDate] = useState(sprintData?.endDate?new Date(sprintData?.endDate) :estimatedEndDate);
  

  const dispatch = useDispatch();
  const { Skeleton,Projects } = useSelector((state) => state.ProjamAppSlice)
  let backlogId = Projects?.selectedProject?.selectedProjectBackLogId
  let projectId = Projects?.selectedProject?.selectedProjectData?._id

  const calculateEndDate = (startDate, duration) => {
    const newEndDate = new Date(startDate);
    const durationWeeks = parseInt(duration.split(" ")[0]);
    newEndDate.setDate(newEndDate.getDate() + durationWeeks * 7 - 1)
    // console.log(newEndDate, "new EndDate")
    return newEndDate;
  };

  const initialValues = {
    name: sprintData?.name || "",
    duration: sprintData?.duration || "2 week",
    startDate: startDate,
    endDate: endDate,
    sprintGoal: sprintData?.sprintGoal || "",
  };

  const handleDurationChange = (event, setFieldValue) => {
    const selectedDuration = event.target.value;
    setDuration(selectedDuration);
    setFieldValue("duration", selectedDuration);

    if (selectedDuration !== "Custom") {
      
      const numWeeks = parseInt(selectedDuration);
     const newEndDate = calculateEndDate(startDate, selectedDuration);
      setEndDate(newEndDate);
      setFieldValue("endDate", newEndDate);
    } else {
      setEndDate(null);
      setFieldValue("endDate", null);
    }
  };

  const handleStartDateChange = (newValue, setFieldValue) => {
    setStartDate(newValue);
    setFieldValue("startDate", newValue);
    if (duration !== "Custom") {
      const numWeeks = parseInt(duration);
      const newEndDate = new Date(newValue);
      newEndDate.setDate(newEndDate.getDate() + numWeeks * 7);
      setEndDate(newEndDate);
      setFieldValue("endDate", newEndDate);
    }
  };

  const handleStartSprint = async (values, { setSubmitting }) => {
    const data = !isSprintEditable ?{
      ...values,
      active: true,
      completed: false,
      endDate: endDate || values.endDate,
    }
    :
    {
      ...values,
      completed: false,
      endDate: endDate || values.endDate,
    }
    ;
    const hasChanges = Object.keys(initialValues).some(
      key => initialValues[key] !== values[key]
    );

    if (!hasChanges) {
      dispatch(showSnackbar("No changes, Please update"));
      return;
    }
    try {
      await axios.put(
        `${API_BASE_URL}/sprints/updateSprint/${sprintData?._id}`,
        data
      );
      dispatch(getSprintsList({ backlogId: backlogId, filterAssigneesArray: [] }))
      dispatch(getLastFiveActiveSprintInfo({backlogId , projectId})) 
      setOpenModal(false);
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Dialog
      open={true}
      aria-labelledby="sprint-start-dialog-title"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="sprint-start-dialog-title">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "0px",
          }}
        >
          <Typography
            sx={{
              fontSize: "18px !important",
              color: colors.font_Bold,
              fontWeight: "bold",
            }}
          >
            Start Sprint
          </Typography>
          <Box sx={{ display: "flex", gap: "18px" }}>
            <Avatar sx={IconGroupStyles} alt="Remy Sharp" src={Icons.dash} />
            <Avatar
              sx={IconGroupStyles}
              alt="Remy Sharp"
              src={Icons.close}
              onClick={() => (setOpenModal(false), setIsSprintEditable(false))}
            />
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          1 Issue will be included in this sprint.
        </Typography>
        <Typography variant="body1" style={{ fontWeight: "bold" }}>
          Required fields are marked with an asterisk{" "}
          <span style={{ color: colors.required_Star_Color }}>*</span>
        </Typography>
        <Formik
          initialValues={{
            name: sprintData?.name || "",
            duration: sprintData?.duration|| "2 week",
            startDate: startDate,
            endDate: endDate,
            sprintGoal: sprintData?.sprintGoal ||"",
          }}
          validationSchema={sprintSchema}
          onSubmit={handleStartSprint}
        >
          {({ handleChange, handleBlur, setFieldValue, values }) => (
            <Form>
              <Box
                sx={{
                  minWidth: "500px",
                  marginLeft: "0px",
                  marginTop: "5px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "18px",
                  maxHeight: "70vh",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start", 
                    gap: "18px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                    gap: "1px",
                  }}
                >
                  <Typography
                    sx={{ mt: 2, fontSize: "12px" }}
                  >
                    Sprint Name
                    <span style={{ color: colors.required_Star_Color }}>*</span>
                  </Typography>
                  <TextField
                    className="search-bar"
                    name="name"
                    onChange={handleChange("name")}
                    onBlur={handleBlur("name")}
                    value={values.name}
                    variant="outlined"
                    size="small"
                    sx={{ maxWidth: "375px", display: "flex", flex: 1 }}
                  />
                  <ErrorMessage
                    name="name"
                    component="span"
                    className="error-message text-danger mx-1"
                    style={{ fontSize: "12px" }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                    gap: "1px",
                  }}
                >
                  <Typography
                    sx={{ mt: 2, fontSize: "12px" }}
                  >
                    Duration
                    <span style={{ color: colors.required_Star_Color }}>*</span>
                  </Typography>
                  <SelectMenuTextField
                    labelText="Duration"
                    name="duration"
                    options={DurationList}
                    onChange={(e) => handleDurationChange(e, setFieldValue)}
                    value={values.duration}
                    onBlur={handleBlur("duration")}
                   defaultValue={sprintData?.duration ? sprintData?.duration :"2 week"}
                  />
                  {!duration && (
                    <ErrorMessage
                      name="duration"
                      component="span"
                      className="error-message text-danger mx-1"
                      style={{ fontSize: "12px" }}
                    />
                  )}
                </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start", 
                    gap: "18px",
                  }}
                >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                    gap: "1px",
                  }}
                >
                  <Typography
                    sx={{ fontSize: "12px", mb: "7px" }}
                  >
                    Start Date
                    <span style={{ color: colors.required_Star_Color }}>*</span>
                  </Typography>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                      label="Select date and time"
                      className="search-bar"
                      minDate={new Date()}
                      value={startDate}
                      onChange={(newValue) => {
                        handleStartDateChange(newValue, setFieldValue);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                      sx={{
                        "& .MuiInputBase-input": {
                          height: "10px",
                          fontSize: "13px",
                        },
                        width: "266px",
                        display: "flex",
                        flex: 1,
                      }}
                    />
                  </LocalizationProvider>
                  <ErrorMessage
                    name="startDate"
                    component="span"
                    className="error-message text-danger mx-1"
                    style={{ fontSize: "12px" }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                    gap: "1px",
                  }}
                >
                  <Typography
                    sx={{ fontSize: "12px", mb: "7px" }}
                  >
                    End Date
                    <span style={{ color: colors.required_Star_Color }}>*</span>
                  </Typography>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                      label="Select date and time"
                      className="search-bar"
                      minDate={new Date()}
                      value={endDate}
                      onChange={(newValue) => {
                        setEndDate(newValue);
                        setFieldValue("endDate", newValue);
                      }}
                      renderInput={(params) => <TextField {...params} />}
                      sx={{
                        "& .MuiInputBase-input": {
                          height: "10px",
                          fontSize: "13px",
                        },
                        width: "266px",
                        display: "flex",
                        flex: 1,
                      }}
                    />
                  </LocalizationProvider>
                  {!endDate && (
                    <ErrorMessage
                      name="endDate"
                      component="span"
                      className="error-message text-danger mx-1"
                      style={{ fontSize: "12px" }}
                    />
                  )}
                </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                    gap: "1px",
                  }}
                >
                  <Typography
                    sx={{ fontSize: "12px", mb: "7px" }}
                  >
                    Sprint Goal
                  </Typography>
                  <Textarea
                    name="sprintGoal"
                    onChange={handleChange("sprintGoal")}
                    onBlur={handleBlur("sprintGoal")}
                    value={values.sprintGoal}
                    sx={{
                      maxWidth: "600px",
                      display: "flex",
                      flex: 1,
                      mb: "2px",
                    }}
                    color="neutral"
                    minRows={3}
                    className="text-area-field"
                    size="sm"
                  />
                  <ErrorMessage
                    name="sprintGoal"
                    component="span"
                    className="error-message text-danger mx-1"
                    style={{ fontSize: "12px" }}
                  />
                </Box>
                <DialogActions>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    position:'sticky'
 
                  }}
                >
                  <ButtonGroup
                    variant="contained"
                    aria-label="outlined button group"
                    className="mb-0 shadow-none d-flex justify-content-between align-items-center gap-3"
                  >
                    <Button
                      sx={{
                        backgroundColor: `transparent !important`,
                        border: `1.5px solid ${colors.primary} !important`,
                        color: `${colors.primary} !important`,
                        borderRadius: "5px  !important",
                        fontSize: "10px !important",
                        textTransform: "capitalize",
                      }}
                      className="px-3"
                      type="button"
                      onClick={() => (
                        setOpenModal(false), 
                        setIsSprintEditable(false)
                      )}
                    >
                      Close
                    </Button>
                    {!isSprintEditable ?
                    <Button
                      sx={{
                        backgroundColor: `${
                          sprintData?.active
                          ? colors.complete_sprint_color
                          : colors.primary
                          } !important`,
                        border: "0px !important",
                        color: "#FFFFFF !important",
                        borderRadius: "5px  !important",
                        fontSize: "10px !important",
                        textTransform: "capitalize",
                      }}
                      className="px-3"
                      type="submit"
                    >
                      Start Sprint
                    </Button>
                    :
                    <Button
                      sx={{
                        backgroundColor: `${
                          sprintData?.active
                          ? colors.complete_sprint_color
                          : colors.primary
                          } !important`,
                        border: "0px !important",
                        color: "#FFFFFF !important",
                        borderRadius: "5px  !important",
                        fontSize: "10px !important",
                        textTransform: "capitalize",
                      }}
                      className="px-3"
                      type="submit"
                    >
                      Update Sprint
                    </Button>
}
                  </ButtonGroup>
                </Box>
                </DialogActions>
              </Box>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default SprintStartModal;
