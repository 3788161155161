import { getIssueTypeIcon, Icons, getPriorityIcon } from "../Icons";

export const issueStatus = [
  {
    name: "To Do",
    value: "To Do",
  },
  {
    name: "In Progress",
    value: "In Progress",
  },
  {
    name: "Code Review",
    value: "Code Review",
  },
  {
    name: "In Testing",
    value: "In Testing",
  },
  {
    name: "Done",
    value: "Done",
  },
  {
    name: "Production Ready",
    value: "Production Ready",
  },
];

export const IssueTypes = [
  {
    name: "Task",
    value: "Task",
  },
  {
    name: "Story",
    value: "Story",
  },
  {
    name: "Bug",
    value: "Bug",
  },
  {
    name: "Epic",
    value: "Epic",
  },
];

export const GroupList = [
  {
    icon: <Icons.arrowCircleRightOutlined sx={{ fontSize: "16px" }} />,
    name: "Status",
    value: "Status",
  },
  {
    icon: <Icons.sprintarrow sx={{ fontSize: "16px" }} />,
    name: "Sprint",
    value: "Sprint",
  },
  {
    icon: <Icons.alternateEmailIcon sx={{ fontSize: "16px" }} />,
    name: "Assignee",
    value: "Assignee",
  },
  {
    icon: <Icons.pinicon sx={{ fontSize: "16px" }} />,
    name: "Story Point estimate",
    value: "Story Point estimate",
  },
];

export const MoreList = [
  { name: "Hide done items", value: "Hide done items" },
  { name: "Expand all items", value: "Expand all items" },
  { name: "Collapse all items", value: "Collapse all items" },
];

export const AddPeople = [
  { name: "Administration", value: "administration" },
  { name: "Manager", value: "manager" },
  { name: "Developer", value: "developer" },
  { name: "Tester", value: "tester" },
];

export const DurationList = [
  { name: "1 week", value: "1 week" },
  { name: "2 week", value: "2 week" },
  { name: "3 week", value: "3 week" },
  { name: "4 week", value: "4 week" },
  { name: "Custom", value: "custom" },
];


export const MenuItemsList = [
  { name: "To Do", value: "To Do" },
  { name: "In Progress", value: "In Progress" },
  { name: "Code Review", value: "Code Review" },
  { name: "In Testing", value: "In Testing" },
  { name: "Production Ready", value: "Production Ready" },
  { name: "Done", value: "Done" },
];

export const taskTypesList = [
  {
    name: "Story",
    icon: getIssueTypeIcon("Story"),
  },
  {
    name: "Bug",
    icon: getIssueTypeIcon("Bug"),
  },
  {
    name: "Task",
    icon: getIssueTypeIcon("Task"),
  },
];

export const taskPriorityList = [
  {
    name: "Urgent",
    icon: getPriorityIcon("Urgent"),
  },
  {
    name: "High",
    icon: getPriorityIcon("High"),
  },
  {
    name: "Medium",
    icon: getPriorityIcon("Medium"),
  },
  {
    name: "Low",
    icon: getPriorityIcon("Low"),
  },
];

export const rolesInProject = [
  { name: "Administration", value: "administration" },
  { name: "Manager", value: "manager" },
  { name: "Developer", value: "developer" },
  { name: "Tester", value: "tester" },
]